import { getData, postData, putData } from '../utils/httputils';

class Event {
  static apiPath = '/api/v1/events';

  static get(id) {
    return getData(`${Event.apiPath}/${id}`);
  }

  static find(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${this.apiPath}?limit=${limit || 100}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }

  static findOngoing() {
    return getData('/api/v1/accounts/me/events/ongoing');
  }

  static findConfirmed() {
    return getData('/api/v1/accounts/me/events/confirmed');
  }

  static findPending() {
    return getData('/api/v1/accounts/me/events/pending');
  }

  static findPast(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    const basePath = '/api/v1/accounts/me/events/past';
    const path = `${basePath}?limit=${limit || 100}&offset=${offset || 0}&order_by=${order_by || ''}&sort=${sort ||
      ''}${searchQuery}`;
    return getData(path);
  }

  static getPastEventsDetails() {
    return getData('/api/v1/accounts/me/events/search_filters');
  }

  static findReported(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${Event.apiPath}/reported?limit=${limit || 100}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }

  static getAvailableResponsibles(id) {
    return getData(`${Event.apiPath}/${id}/responsibles`);
  }

  static update(id, field, value) {
    return putData(`${this.apiPath}/${id}`, { field, value });
  }

  static doCheckInOut(event_id, job_id, check_in_out) {
    return postData(
      `${Event.apiPath}/${event_id}/staff/lists/${job_id}/${check_in_out}`,
      check_in_out === 'check_in' ? { check_in: true } : { check_in: false }
    );
  }

  static doMoveRecord(event_id, from, to) {
    return postData(`${Event.apiPath}/${event_id}/staff/move`, { from, to });
  }

  static getProfileDetail(event_id, profile_id) {
    return getData(`${Event.apiPath}/${event_id}/staff/lists/${profile_id}/profile`);
  }
}

export default Event;
