import EventUser from '../../lib/models/Event';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { StarIcon } from '@primer/octicons-react';
import { Link } from 'react-router-dom';
import OneOrMoreAlert from '../Common/OneOrMoreAlert';
import { formatDate } from '../../lib/utils/dateutils';

export class UserEvent extends PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired
  };

  render() {
    const { item } = this.props;
    const { due_date, start_time, end_time, address } = item;
    const date_s = formatDate({ date: new Date(due_date), doubleDigit: true });
    const startTime = start_time.substring(0, start_time.lastIndexOf(':'));
    const endTime = end_time.substring(0, end_time.lastIndexOf(':'));
    const timeString = `Il ${date_s} dalle ${startTime} alle ${endTime}`;
    const link = item.is_responsible ? `/events/${item.id}` : `/job/${item.list_id}`;
    return (
      <div className="alert alert-warning" role="alert">
        <Link to={link}>
          <div className="row">
            {item.is_responsible ? (
              <>
                <span className=" col-12 col-md-3 col-lg-3">
                  <StarIcon className="text-warning mr-2" />
                  {item.title}
                </span>
              </>
            ) : (
              <span className=" col-12 col-md-4 col-lg-4">{item.title}</span>
            )}
            <div className="col-12 col-md-4 col-lg-4">{timeString}</div>
            <div className="col-12 col-md-4 col-lg-4">{address}</div>
          </div>
        </Link>
      </div>
    );
  }
}

class ConfirmedEvents extends PureComponent {
  state = {
    events: { rows: [] }
  };

  render() {
    const { events } = this.state;
    const manyMessage = `Ci sono ${events.rows.length} eventi confermati`;
    return (
      <div>
        <h5 className="text-success">Eventi confermati</h5>
        <OneOrMoreAlert
          zeroMessage="Non hai nessun lavoro programmato"
          elements={events.rows}
          ElementComponent={UserEvent}
          manyMessage={manyMessage}
        />
      </div>
    );
  }

  componentDidMount() {
    this.loadEvents();
  }

  loadEvents = () => {
    EventUser.findConfirmed().then(events => {
      this.setState({ events });
    });
  };
}

export default ConfirmedEvents;
