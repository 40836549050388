import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DownloadIcon, LockIcon, SearchIcon, TrashIcon } from '@primer/octicons-react';
import Loading from '../Common/Loading';
import ProfileAttachments from '../../lib/models/ProfileAttachments';
import { formatDate } from '../../lib/utils/dateutils';
import ProfileAttachmentForm from './ProfileAttachmentForm';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import { setTitle } from '../../lib/utils/windowutils';

class ProfileAttachmentsList extends PureComponent {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired
  };

  renderAttachmentListRow = row => {
    const created_at = new Date(row.created_at);
    const attachmentURL = ProfileAttachments.getAttachmentUrl(this.props.profile.id, row.id);
    return (
      <tr key={`staff-list-row-${row.id}`} className="justify-content-between align-items-center">
        <td>
          <a href={attachmentURL} target="_blank" rel="noopener noreferrer">
            <SearchIcon />
          </a>{' '}
          <a href={`${attachmentURL}?d=1`} target="_blank" rel="noopener noreferrer">
            <DownloadIcon />
          </a>
        </td>
        {row.is_private ? (
          <td>
            <LockIcon size={16} />
          </td>
        ) : (
          <td />
        )}
        <td>{row.filename}</td>
        <td>{row.description}</td>
        <td>
          {formatDate({ date: created_at })} {created_at.toLocaleTimeString()}
        </td>
        <td>{row.created_by}</td>
        <td>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={e => this.deleteAttachment(e, this.props.profile.id, row.id)}
          >
            <TrashIcon />
          </button>
        </td>
      </tr>
    );
  };

  state = {
    add_to_list_form: false,
    attachments: false,
    error: false
  };

  render() {
    const { profile } = this.props;
    const { add_to_list_form, attachments, error } = this.state;

    if (!error && attachments === false) return <Loading />;
    if (error) return <div className="alert alert-danger">{error}</div>;
    return (
      <div className="mt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="card border-primary">
                <div className="card-header bg-primary text-white">
                  <strong>Allegati</strong>
                </div>
                <div className="card-body">
                  <div className="mb-3 text-right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.setState({ add_to_list_form: !add_to_list_form })}
                    >
                      {add_to_list_form ? 'CHIUDI' : 'AGGIUNGI'}
                    </button>
                  </div>
                  {!add_to_list_form && (
                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th />
                          <th />
                          <th>Nome</th>
                          <th>Descrizione</th>
                          <th>Data caricamento</th>
                          <th>Caricato da</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {attachments.length === 0 && (
                          <tr className="table-warning">
                            <td colSpan={7} className="text-center">
                              <p className=" text-black-50">Nessun allegato trovato</p>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.setState({ add_to_list_form: !add_to_list_form })}
                              >
                                AGGIUNGI
                              </button>
                            </td>
                          </tr>
                        )}
                        {attachments.map(row => this.renderAttachmentListRow(row))}
                      </tbody>
                    </table>
                  )}
                  {add_to_list_form && (
                    <ProfileAttachmentForm
                      profile={profile}
                      onAttachmentUploaded={() =>
                        this.setState({ add_to_list_form: false }, () => {
                          setTimeout(this.loadAttachments, 500);
                        })
                      }
                      onToggleForm={() => this.setState({ add_to_list_form: false })}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTitle('Allegati');
    this.loadAttachments();
  }

  loadAttachments = () => {
    const { profile } = this.props;
    ProfileAttachments.find(profile.id, {})
      .then(attachments => {
        this.setState({ attachments });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };

  deleteAttachment = (e, profile_id, attachment_id) => {
    e.preventDefault();
    const ok = window.confirm('Sei sicuro?');
    if (ok) {
      ProfileAttachments.delete(profile_id, attachment_id)
        .then(() => {
          return this.loadAttachments();
        })
        .catch(e => {
          ltToast('Impossibile cancellare allegato: ' + e.message, 5000, true);
        });
    }
  };
}

export default ProfileAttachmentsList;
