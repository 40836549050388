import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class TimePicker extends PureComponent {
  static propTypes = {
    time: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    showSave: PropTypes.bool,
    saveClassName: PropTypes.string,
    onSave: PropTypes.func
  };

  state = {
    hours: '',
    minutes: ''
  };

  hoursArray = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];

  minutesArray = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

  render() {
    const { hours, minutes } = this.state;
    const { showSave, saveClassName } = this.props;
    return (
      <div className="row">
        <div className="col-4">
          <select
            className="form-control"
            name="hours"
            value={hours.length > 1 ? hours : `0${hours}`}
            onChange={this.handleChangeTime}
          >
            <option />
            {this.hoursArray.map((hour, i) => {
              return (
                <option key={i} value={hour}>
                  {hour}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-4">
          <select className="form-control" name="minutes" value={minutes} onChange={this.handleChangeTime}>
            <option />
            {this.minutesArray.map((minute, i) => {
              return (
                <option key={i} value={minute}>
                  {minute}
                </option>
              );
            })}
          </select>
        </div>
        {showSave && (
          <div className="col-4">
            <button className={saveClassName || 'btn'} onClick={this.beforeSave}>
              SALVA
            </button>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.getCurrentTime(this.props.time);
  }

  beforeSave = () => {
    const { name, onSave } = this.props;
    const { hours, minutes } = this.state;
    const updatedTime = `${hours}:${minutes}`;
    this.props.onConfirm(updatedTime, name);
    setTimeout(() => {
      onSave();
    });
  };
  getCurrentTime = time => {
    let array = time.split(':');
    if (array.length < 2) {
      array = ['00', '00'];
    }
    this.setState({ hours: array[0], minutes: array[1] });
  };

  updateTime = (name, value) => {
    const { hours, minutes } = this.state;
    let updatedTime;
    if (name === 'minutes') {
      updatedTime = hours.concat(':', value, ':0');
      return updatedTime;
    }
    if (name === 'hours') {
      updatedTime = value.concat(':', minutes, ':0');
      return updatedTime;
    }
  };

  handleChangeTime = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const updatedTime = this.updateTime(name, value);

    this.setState(
      {
        [name]: value
      },
      () => {
        this.props.onConfirm(updatedTime, this.props.name);
        console.log('called onConfirm', updatedTime);
      }
    );
  };
}

export default TimePicker;
