import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import { setTitle } from '../../lib/utils/windowutils';
import { getRoles, getRolesAsString, getRoleString, loadRoles } from '../../lib/models/Roles';
import LTFormInput from '../Common/LT-Form-Elements/LTFormInputs/LTFormInput';
import LTFormDropDown from '../Common/LT-Form-Elements/LTFormInputs/LTFormDropDown';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import { connect } from 'react-redux';
import { loadPartners } from '../../lib/models/Partners';
import Breadcrumb from '../Breadcrumb';
import { TrashIcon } from '@primer/octicons-react';
import Account from '../../lib/models/admin/Account';

const breadcrumbs = [
  { link: '/admin', text: 'Admin' },
  { link: '/admin/accounts', text: 'Utenti' }
];

class AccountView extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired
  };

  state = {
    partners: [],
    account: false,
    error: false,
    breadcrumbs
  };

  refInput = React.createRef();

  render() {
    const { account, error, partners, breadcrumbs } = this.state;
    const user = this.props.account.data;
    const roles =
      this.roles &&
      this.roles
        .filter(role => role.id >= user.role)
        .reduce((acc, role) => {
          acc[role.id] = getRoleString(role.name);
          return acc;
        }, {});

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        {!error && !account && <Loading />}
        <Breadcrumb crumbs={breadcrumbs} />
        {account && (
          <div>
            <h5 className="mb-3">Modifica utente</h5>
            <div className="row">
              <div className="mb-3 col-12 col-md-6 col-lg-4">
                <div className="card border-primary">
                  <div className="card-header bg-primary text-white">
                    <strong>Dettagli</strong>
                  </div>
                  <div className="card-body">
                    <dl className="row">
                      <dt className="col-12 col-md-4 col-lg-3">Email</dt>
                      <dd className="col-12 col-md-8 col-lg-9">{account.email}</dd>
                      <dt className="col-12 col-md-4 col-lg-3">Name</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'name'}
                        value={account.name}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      {user.master && <dt className="col-12 col-md-4 col-lg-3">Partner</dt>}
                      {user.master && (
                        <LTFormDropDown
                          className="col-12 col-md-8 col-lg-9"
                          name={'partner_id'}
                          displayValue={account.partner_name}
                          value={account.partner_id}
                          canEdit={false}
                          options={partners}
                          onChange={this.handleChange}
                          onEscape={this.undoChanges}
                          onBlur={this.saveEdit}
                          onEnter={this.saveEdit}
                        />
                      )}
                      <dt className="col-12 col-md-4 col-lg-3">Roles</dt>
                      <LTFormDropDown
                        className="col-12 col-md-8 col-lg-9"
                        name={'role'}
                        displayValue={getRolesAsString(account.role)}
                        value={account.role}
                        canEdit={true}
                        options={roles}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Stato</dt>
                      <LTFormDropDown
                        canEdit
                        name="status"
                        className="col-12 col-md-8 col-lg-9"
                        displayValue={
                          account.status === 1 ? 'Attivo' : account.status === 9 ? 'Disattivato' : 'Inattivo'
                        }
                        value={account.status}
                        options={{ 1: 'Attivo', 9: 'Disattivato' }}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                      />
                    </dl>
                  </div>
                </div>
              </div>
              {user.role_code === 'master' && account.profile && (
                <div className="mb-3 col-12 col-md-6 col-lg-4">
                  <div className="card border-primary">
                    <div className="card-header bg-primary text-white">
                      <strong>Profilo</strong>
                    </div>
                    <div className="card-body">
                      <dl className="row">
                        <dt className="col-12 col-md-4 col-lg-3">Profile ID</dt>
                        <dd className="col-12 col-md-8 col-lg-9">{account.profile.id}</dd>
                        <dt className="col-12 col-md-4 col-lg-3">Nome</dt>
                        <dd className="col-12 col-md-8 col-lg-9">{account.profile.name}</dd>
                        <dt className="col-12 col-md-4 col-lg-3">Cognome</dt>
                        <dd className="col-12 col-md-8 col-lg-9">{account.profile.surname}</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              )}
              {user.role_code === 'master' && !account.profile && (
                <div className="mb-3 col-12 col-md-6 col-lg-4">
                  <div className="card border-danger">
                    <div className="card-header bg-danger text-white">
                      <strong>ATTENZIONE</strong>
                    </div>
                    <div className="card-body">
                      <button className="btn btn-danger" onClick={this.handleDelete}>
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    loadRoles().then(roles => {
      this.roles = roles;
      if (this.props.account.data.master) {
        loadPartners().then(partners => {
          const partnersObj = {};
          partners.forEach(partner => {
            partnersObj[partner.id] = partner.name;
          });
          this.setState({ partners: partnersObj });
        });
      }
      this.loadAccount();
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.account && this.state.account.id && !prevState.account.id) {
      setTitle(this.state.account.email);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadAccount();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
  }

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Sei sicuro?');
    if (ok) {
      const { account } = this.state;
      Account.delete(account.id)
        .then(() => {
          this.props.history.push('/admin/accounts');
        })
        .catch(e => {
          ltToast("Impossibile cancellare l'account: " + e.message, 5000, true);
        });
    }
  };

  handleChange = e => {
    e.preventDefault();
    let newAccount = { ...this.state.account };
    newAccount[e.target.name] = e.target.value;
    this.setState({ account: newAccount });
  };

  saveEdit = (field, value) => {
    const { oldAccount, account } = this.state;
    if (oldAccount[field] !== value) {
      Account.update(account.id, field, value)
        .then(account => {
          this.setState({ account, oldAccount: account }, () => {
            ltToast('Salvataggio effettuato', 2000);
          });
        })
        .catch(e => {
          ltToast('Fallito salvataggio modifica:' + e.message, false, true);
        });
    }
  };

  undoChanges = (field, value) => {
    const { oldAccount } = this.state;
    if (oldAccount[field] !== value) {
      let resetedAccount = { ...this.state.oldAccount };
      this.setState({
        account: resetedAccount
      });
    }
  };

  loadAccount = () => {
    const { match } = this.props;
    const { params } = match;
    Account.get(params.id)
      .then(account => {
        const _breadcrumbs = [...breadcrumbs, { active: true, text: account.email }];
        this.setState({
          account,
          oldAccount: account,
          editedRoles: getRoles(account.role).map(role => role.id),
          breadcrumbs: _breadcrumbs
        });
      })
      .catch(e => console.error('err', e.message));
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(AccountView);
