import { deleteData, getData, postData, putData } from '../utils/httputils';

class ProfileAttachments {
  static apiPath = '/api/v1/profiles/{{ profile_id }}/attachments';

  static create(profile_id, data) {
    return postData(ProfileAttachments.apiPath.replace('{{ profile_id }}', profile_id), data);
  }

  static getAttachmentUrl(profile_id, attachment_id, embed = false) {
    return `${ProfileAttachments.apiPath.replace('{{ profile_id }}', profile_id)}/${attachment_id}${
      embed ? '?e=1' : ''
    }`;
  }

  static find(profile_id, params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${ProfileAttachments.apiPath.replace('{{ profile_id }}', profile_id)}?limit=${limit || 10}&offset=${offset ||
        0}&order_by=${order_by || 'name'}&sort=${sort || 'asc'}${searchQuery}`
    );
  }

  static get(profile_id, id) {
    return getData(`${ProfileAttachments.apiPath.replace('{{ profile_id }}', profile_id)}/${id}`);
  }

  static update(profile_id, id, field, value) {
    return putData(`${ProfileAttachments.apiPath.replace('{{ profile_id }}', profile_id)}/${id}`, { field, value });
  }

  static delete = (profile_id, id) => {
    return deleteData(`${ProfileAttachments.apiPath.replace('{{ profile_id }}', profile_id)}/${id}`);
  };
}

export default ProfileAttachments;
