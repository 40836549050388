import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  TriangleDownIcon,
  PencilIcon,
  TriangleRightIcon,
  TrashIcon,
  AlertIcon,
  CheckCircleFillIcon,
  PaperAirplaneIcon,
  DeviceMobileIcon,
  DeviceDesktopIcon
} from '@primer/octicons-react';
import { connect } from 'react-redux';

import Loading from '../Common/Loading';
import { staffListRequest } from '../../lib/models/stafflist/StaffListReducer';
import StaffListAdd from './StaffListAdd';
import StaffBlockEditForm from './StaffBlockEditForm';
import StaffRowEditForm from './StaffRowEditForm';
import StaffListRecord, { STAFF_STATUSES } from './StaffListRecord';
import StaffBlock from '../../lib/models/stafflist/StaffBlock';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import StaffBlocksModal from './StaffBlocksModal';
import StaffProfileModal from './StaffProfileModal';
import { expandRows } from '../../lib/utils/commonutils';
import { assignModalOpenClass } from '../../lib/utils/windowutils';

class StaffListView extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    staff: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired
  };

  state = {
    openedBlocks: new Set(),
    editedBlock: null,
    filledProfilesCount: null,
    add_to_list_form: false,
    fromListId: null,
    fromBlockId: null,
    mobileExtendedView: false
  };

  blockStatus = listrows => {
    let statusString;
    let StatusIcon = AlertIcon;
    let backgroundClass = '';
    if (listrows.every(row => row.status === STAFF_STATUSES.confirmed)) {
      statusString = 'Confermati';
      StatusIcon = CheckCircleFillIcon;
      backgroundClass = 'bg-primary';
    } else {
      if (listrows.some(row => row.status === STAFF_STATUSES.rejected)) {
        statusString = 'Lavoro rifiutato / Operatore rimosso';
        backgroundClass = 'bg-danger';
      } else if (listrows.some(row => !row.profile_id)) {
        statusString = 'Non tutti compilati';
        backgroundClass = 'bg-warning';
      } else if (listrows.some(row => row.status === STAFF_STATUSES.confirmed)) {
        statusString = 'Non tutti confermati';
        backgroundClass = 'bg-warning';
      } else {
        statusString = 'Incompleto';
      }
    }

    return {
      column: (
        <span>
          <span className="mr-2">
            <StatusIcon />
          </span>
          {statusString}
        </span>
      ),
      backgroundClass
    };
  };

  renderBlock = (eventOwner, block, listrows, canEdit) => {
    const { openedBlocks } = this.state;
    const isAdmin = this.isAdmin();
    const blockOwner = this.isBlockOwner(block);
    const startTime = block.start_time.substring(0, block.start_time.lastIndexOf(':'));
    const endTime = block.end_time.substring(0, block.end_time.lastIndexOf(':'));
    const opened = openedBlocks.has(block.id);
    const { column, backgroundClass } = this.blockStatus(listrows);
    const onEditClock = e => {
      e.target.type === 'submit' ? e.target.blur() : e.target.parentElement.blur();
      this.setState({ editedBlock: block, filledProfilesCount });
    };
    const filledProfilesCount = listrows.filter(row => row.profile_id).length;
    return [
      <tr key={`staff-block-${block.id}`} className={backgroundClass}>
        <td>
          <button
            className="btn"
            onClick={e => {
              const array = expandRows(e, openedBlocks, block);
              this.setState({ openedBlocks: new Set(array) });
            }}
            title={opened ? 'Restringi' : 'Espandi'}
          >
            {opened ? <TriangleDownIcon /> : <TriangleRightIcon />}
          </button>
          {block.id}
        </td>
        <td className="hide-on-mobile">
          <span>
            <span className="mr-2">
              {filledProfilesCount === block.block_size ? <CheckCircleFillIcon /> : <AlertIcon />}
            </span>
            {filledProfilesCount}/{block.block_size}
          </span>
        </td>
        <td>{startTime}</td>
        <td>{endTime}</td>
        <td>{block.partner_name}</td>
        <td className="hide-on-mobile">{block.notes}</td>
        <td className="hide-on-mobile">{column}</td>
        <td className="text-right hide-on-mobile">
          {canEdit && isAdmin && blockOwner && (
            <button
              key="event-block-send-all"
              title="Invia notifica a tutti"
              className="btn btn-sm btn-outline-dark mr-lg-2 mb-1 mb-sm-0"
              onClick={e => this.sendNotificationAllBlock(block.event_id, block.id)}
            >
              <PaperAirplaneIcon />
            </button>
          )}
          {canEdit &&
            isAdmin &&
            eventOwner && [
              <button
                key="event-block-edit"
                title="Modifica blocco"
                className="btn btn-sm btn-outline-dark mr-lg-2 mb-1 mb-sm-0"
                onClick={onEditClock}
              >
                <PencilIcon />
              </button>,
              <button
                key="event-block-delete"
                title="Cancella blocco"
                onClick={() => this.deleteBlock(block.id, this.props.event.id)}
                className={`btn btn-sm ${backgroundClass === 'bg-danger' ? 'btn-outline-dark' : 'btn-outline-danger'}`}
              >
                <TrashIcon />
              </button>
            ]}
        </td>
      </tr>,
      opened ? this.renderStaffListTable(listrows, canEdit, block, filledProfilesCount) : null
    ];
  };

  renderStaffListTable = (listrows, canEdit, block) => {
    const blockOwner = this.isBlockOwner(block);
    const eventOwner = this.isEventOwner();
    const isAdmin = this.isAdmin();
    return (
      <tr key={`staff-list-row-${listrows[0]?.id}`}>
        <td colSpan="12">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th className="hide-on-mobile">Stato</th>
                <th>Profilo</th>
                <th>Check-in</th>
                <th>Check-out</th>
                <th className="text-right hide-on-mobile">
                  <span>Azioni</span>
                </th>
              </tr>
              {listrows.map((row, i) => {
                return (
                  <StaffListRecord
                    key={row.id}
                    row={row}
                    canEdit={canEdit}
                    eventStatus={this.props.event.status}
                    blockOwner={blockOwner}
                    eventOwner={eventOwner}
                    isAdmin={isAdmin}
                    onEditListProfile={row => this.editListProfile(row)}
                    dispatch={this.props.dispatch}
                    showMoveOptions={this.renderMoveOptions}
                    showProfile={this.onShowProfile}
                  />
                );
              })}
            </thead>
          </table>
        </td>
      </tr>
    );
  };

  render() {
    const { staff, event, dispatch } = this.props;
    const { list: stafflist, blocks, isLoading } = staff;
    const {
      add_to_list_form,
      editedBlock,
      editedRow,
      filledProfilesCount,
      showMoveModal,
      fromListId,
      fromBlockId,
      openedBlocks,
      show_profile_id,
      mobileExtendedView
    } = this.state;
    const isAdmin = this.isAdmin();
    const eventOwner = this.isEventOwner();
    const canEdit = event.status < 500;
    const allSelected = openedBlocks.size === blocks.length;

    return (
      <div className="card border-primary">
        <div className="card-header bg-primary text-white">
          <strong>LISTA STAFF</strong>
        </div>
        <div className="card-body">
          {isLoading && <Loading />}
          <div style={{ width: '100%', overflow: 'auto' }}>
            <div className="row">
              <div className="mb-3 col-lg-6" role="group" aria-label="Button group with nested dropdown">
                <div className="btn-group ">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/api/v1/events/${event.id}/list`}
                    className="btn btn-primary"
                  >
                    STAMPA LISTA
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/api/v1/events/${event.id}/list?only_filled=1`}
                    className="btn btn-primary"
                  >
                    COMPILATI
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/api/v1/events/${event.id}/list?only_confirmed=1`}
                    className="btn btn-primary"
                  >
                    CONFERMATI
                  </a>
                </div>
                {isAdmin && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/api/v1/events/${event.id}/list/communication`}
                    className="btn btn-primary ml-lg-2 mt-lg-0 mt-3"
                  >
                    LISTA COMUNICAZIONI
                  </a>
                )}
              </div>
              <div className="mb-3 col-lg-2">
                <button className="btn btn-outline-primary" onClick={e => this.toggleAllSelected(allSelected, blocks)}>
                  {allSelected ? <TriangleDownIcon /> : <TriangleRightIcon />}
                  {allSelected ? 'Restringi Tutto' : 'Espandi Tutto'}
                </button>
              </div>
              <div className="col-lg-4 mb-3 text-lg-right">
                {isAdmin && eventOwner && canEdit && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      this.setState({ add_to_list_form: !add_to_list_form }, () => {
                        assignModalOpenClass(this.state.add_to_list_form);
                      })
                    }
                  >
                    {add_to_list_form ? 'CHIUDI' : 'AGGIUNGI'}
                  </button>
                )}
                {eventOwner && canEdit && (
                  <button
                    type="button"
                    className="btn btn-primary d-md-none ml-5"
                    onClick={() => this.setState({ mobileExtendedView: !mobileExtendedView })}
                  >
                    {mobileExtendedView ? <DeviceMobileIcon size={16} /> : <DeviceDesktopIcon size={16} />}
                  </button>
                )}
              </div>
            </div>

            <table
              className={
                mobileExtendedView ? 'table table-striped table-hover' : 'table table-striped table-hover adapt-mobile'
              }
            >
              <thead>
                <tr>
                  <th>ID Blocco</th>
                  <th className="hide-on-mobile">Operatori</th>
                  <th>Ora inizio</th>
                  <th>Ora fine</th>
                  <th>Partner</th>
                  <th className="hide-on-mobile">Note</th>
                  <th className="hide-on-mobile">Stato</th>
                  <th className="hide-on-mobile">Azioni</th>
                </tr>
              </thead>
              <tbody>
                {stafflist.length === 0 && (
                  <tr className="bg-warning">
                    <td colSpan={9}>Nessun blocco presente</td>
                  </tr>
                )}
                {blocks.map(block =>
                  this.renderBlock(
                    eventOwner,
                    block,
                    stafflist.filter(row => row.block_id === block.id),
                    canEdit
                  )
                )}
              </tbody>
            </table>
            {editedBlock && (
              <StaffBlockEditForm block={editedBlock} onClose={this.closeEditForm} list={filledProfilesCount} />
            )}
            {editedRow && <StaffRowEditForm row={editedRow} onClose={this.closeEditForm} />}
            {add_to_list_form && (
              <StaffListAdd
                event_id={event.id}
                onClose={() =>
                  this.setState({ add_to_list_form: false }, () => {
                    assignModalOpenClass(this.state.add_to_list_form);
                  })
                }
              />
            )}
            {showMoveModal && (
              <StaffBlocksModal
                eventId={event.id}
                onClose={this.closeModal}
                staff={staff}
                fromListId={fromListId}
                fromBlockId={fromBlockId}
                dispatch={dispatch}
              />
            )}
            {show_profile_id && (
              <StaffProfileModal
                profileId={show_profile_id}
                eventId={event.id}
                onClose={() =>
                  this.setState({ show_profile_id: false }, () => {
                    assignModalOpenClass(this.state.show_profile_id);
                  })
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  onShowProfile = profile_id => {
    this.setState({ show_profile_id: profile_id }, () => {
      assignModalOpenClass(this.state.show_profile_id);
    });
  };

  componentDidMount() {
    const { event } = this.props;
    this.props.dispatch(staffListRequest(event.id));
  }

  closeEditForm = changed => {
    this.setState({ editedBlock: null, editedRow: null });
    if (changed) {
      this.props.dispatch(staffListRequest(this.props.event.id));
    }
  };

  editListProfile = staffListRow => {
    const freeIds = this.props.staff.list
      .filter(row => row.block_id === staffListRow.block_id && !row.profile_id)
      .map(row => row.id);
    this.setState({ editedRow: { row: staffListRow, freeIds } });
  };

  isAdmin = () => {
    const { account } = this.props;
    return account.data.role === 2;
  };

  isBlockOwner = block => {
    const { account } = this.props;
    return account.data.partner_id === block.partner_id;
  };

  isEventOwner = () => {
    const { account, event } = this.props;
    return account.data.partner_id === event.partner_id || account.data.id === event.responsible_account_id;
  };

  sendNotificationAllBlock = (eventId, blockId) => {
    const ok = window.confirm('Sei sicuro di voler inviare la notifica a tutto il blocco?');
    if (ok) {
      StaffBlock.sendNotificationToAll(eventId, blockId)
        .then(() => {
          return this.props.dispatch(staffListRequest(eventId));
        })
        .catch(e => {
          ltToast('Impossibile inviare notifiche al blocco: ' + e.message, 5000, true);
        });
    }
  };

  deleteBlock = (block_id, event_id) => {
    const ok = window.confirm('Sei sicuro di voler cancellare il blocco?');
    if (ok) {
      const atLeastOne = this.props.staff.list.some(row => {
        return row.status > STAFF_STATUSES.sent && row.status < STAFF_STATUSES.rejected;
      });
      if (atLeastOne) {
        const confirm = window.confirm('Questo blocco ha operatori confermati. Vuoi cancellare lo stesso?');
        if (!confirm) {
          return;
        }
      }
      StaffBlock.delete(event_id, block_id)
        .then(() => {
          return this.props.dispatch(staffListRequest(event_id));
        })
        .catch(e => {
          ltToast('Impossibile cancellare il blocco: ' + e.message, 5000, true);
        });
    }
  };

  renderMoveOptions = (fromListId, fromBlockId) => {
    this.setState({ showMoveModal: true, fromListId, fromBlockId }, () => {
      assignModalOpenClass(this.state.showMoveModal);
    });
  };

  closeModal = () => {
    this.setState({ showMoveModal: false }, () => {
      assignModalOpenClass(this.state.showMoveModal);
    });
  };

  toggleAllSelected = (allSelected, blocks) => {
    this.setState({ openedBlocks: allSelected ? new Set() : new Set(blocks.map(block => block.id)) });
  };
}

function mapStateToProps(state) {
  const { staff, account } = state;

  return {
    staff,
    account
  };
}

export default connect(mapStateToProps)(StaffListView);
