import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Account from '../../lib/models/account';
import Loading from '../Common/Loading';
import { STAFF_STATUSES } from '../StaffList/StaffListRecord';
import EventAttachments from '../../lib/models/EventAttachments';
import { DownloadIcon } from '@primer/octicons-react';
import { formatDate, getFormattedStringDate } from '../../lib/utils/dateutils';

class ConfirmPage extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  event_table_left_classes = 'col-12 col-md-6 col-lg-3';
  event_table_right_classes = 'col-12 col-md-6 col-lg-9';

  state = {
    job: null,
    error: null,
    attachments: []
  };

  render() {
    const { job, error, attachments } = this.state;
    if (error) {
      return (
        <div className="mt-3">
          <div className="container">
            <div className="alert alert-danger">
              <h2>ATTENZIONE</h2>
              {error}
            </div>
          </div>
        </div>
      );
    }
    if (!job) {
      return <Loading />;
    }
    if (job.status === STAFF_STATUSES.rejected) {
      return (
        <div className="mt-3">
          <div className="container">
            <div className="alert alert-danger">
              <h2>ATTENZIONE</h2>
              Hai già rifiutato questo lavoro!
            </div>
          </div>
        </div>
      );
    }

    const { title, address, notes, due_date, start_time, end_time, check_in, check_out, offline } = job;

    if (offline) {
      return (
        <div className="p-lg-5 mx-lg-5 text-center">
          <div className="alert alert-warning min-vh-100">
            <h1>ATTENZIONE</h1>
            <h2>EVENTO IN MODALITA' OFFLINE</h2>
            <h3>Contatta il tuo responsabile per comunicare data di inizio e fine attività</h3>
          </div>
        </div>
      );
    }
    const date_s = formatDate({ date: new Date(due_date), doubleDigit: true });
    const startTime = start_time.substring(0, start_time.lastIndexOf(':'));
    const endTime = end_time.substring(0, end_time.lastIndexOf(':'));
    const confirmed = job.status === STAFF_STATUSES.confirmed;
    const checkOpen = job.event_status >= 299;

    return (
      <div className="mt-3">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12">
              <div className="bg-light  p-3">
                <h5 className="mb-4">Dettaglio Lavoro</h5>
                {!confirmed && (
                  <div className="alert alert-primary">
                    Ciao, sei stato selezionato per un nuovo lavoro.
                    <br />
                    <br />
                    Qui di seguito il dettaglio, per confermare clicca su "CONFERMA"
                    <br />
                    <br />
                    Se non vuoi accettare il lavoro clicca su "rifiuta"
                  </div>
                )}
                <dl className="row">
                  <dt className={this.event_table_left_classes}>Titolo</dt>
                  <dd className={this.event_table_right_classes}>{title}</dd>
                </dl>
                <dl className="row">
                  <dt className={this.event_table_left_classes}>Luogo</dt>
                  <dd className={this.event_table_right_classes}>{address || '-'}</dd>
                </dl>
                <dl className="row">
                  <dt className={this.event_table_left_classes}>Data Inizio</dt>
                  <dd className={this.event_table_right_classes}>{date_s}</dd>
                </dl>
                <dl className="row">
                  <dt className={this.event_table_left_classes}>Orario Inizio</dt>
                  <dd className={this.event_table_right_classes}>{startTime}</dd>
                </dl>
                <dl className="row">
                  <dt className={this.event_table_left_classes}>Orario Fine</dt>
                  <dd className={this.event_table_right_classes}>{endTime}</dd>
                </dl>
                <dl className="row">
                  <dt className={this.event_table_left_classes}>Note</dt>
                  <dd className={this.event_table_right_classes}>
                    <div className="overflow-auto" style={{ whiteSpace: 'pre' }}>
                      {notes}
                    </div>
                  </dd>
                </dl>
                {!confirmed && (
                  <div className="row">
                    <div className="col text-left">
                      <button
                        className="btn btn-success btn-lg"
                        onClick={() => this.updateStaffRequest(STAFF_STATUSES.confirmed)}
                      >
                        CONFERMA
                      </button>
                    </div>
                    <div className="col text-right">
                      <button
                        className="btn btn-danger btn-lg"
                        onClick={() => this.updateStaffRequest(STAFF_STATUSES.rejected)}
                      >
                        RIFIUTA
                      </button>
                    </div>
                  </div>
                )}
                {checkOpen && confirmed && <div className="mt-4">{this.renderCheckButtons(check_in, 'check-in')}</div>}
                {checkOpen && confirmed && check_in && (
                  <div className="mt-4">{this.renderCheckButtons(check_out, 'check-out')}</div>
                )}
                {confirmed && !check_in && (
                  <div className="mt-4">
                    <div className="col text-right">
                      <button
                        className="btn btn-danger"
                        onClick={() => this.updateStaffRequest(STAFF_STATUSES.rejected)}
                      >
                        RINUNCIA
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {confirmed && attachments.length > 0 && (
            <div className="row mb-3">
              <div className="col-12">
                <div className="card border-primary">
                  <div className="card-header bg-primary text-white">
                    <strong>Allegati</strong>
                  </div>
                  <div className="card-body">
                    <div className="list-group">
                      {attachments.map((attachment, i) => {
                        const attachmentURL = EventAttachments.getAttachmentUrl(job.event_id, attachment.id);
                        return (
                          <div key={i} className="list-group-item">
                            <a href={`${attachmentURL}`} target="_blank" rel="noopener noreferrer">
                              <DownloadIcon />
                              <span className="mr-2" />
                              {attachment.description}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadJob();
  }

  getEventAttachments = event_id => {
    EventAttachments.find(event_id, {})
      .then(attachments => {
        this.setState({ attachments });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };

  loadJob = () => {
    const { match } = this.props;
    const { params } = match;
    Account.getJob(params.id)
      .then(job => {
        this.setState({ job }, () => {
          this.getEventAttachments(job.event_id);
        });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };

  updateStaffRequest = status => {
    if (status === STAFF_STATUSES.rejected) {
      const confirm = window.confirm('Sei sicuro di voler rinunciare al lavoro?');
      if (confirm) {
        this.doUpdateStaffRequest(status);
      }
    } else {
      this.doUpdateStaffRequest(status);
    }
  };

  doUpdateStaffRequest = status => {
    const { event_id, id } = this.state.job;
    Account.updateJob(event_id, id, status)
      .then(() => {
        this.props.history.replace('/');
      })
      .catch(e => this.setState({ error: e.message }));
  };

  doCheckInOut = type => {
    const { id } = this.state.job;
    const checkInOut = type === 'check-in' ? 'check_in' : 'check_out';
    Account.doCheckInOut(id, checkInOut)
      .then(job => this.setState({ job }))
      .catch(e => this.setState({ error: e.message }));
  };

  renderCheckButtons = (date, type) => {
    if (!date) {
      return (
        <button
          className={type === 'check-in' ? 'btn btn-lg btn-success' : 'btn btn-lg btn-danger'}
          onClick={e => this.doCheckInOut(type)}
        >
          {type.toUpperCase()}
        </button>
      );
    } else {
      return (
        <span className={type === 'check-in' ? 'text-success' : 'text-danger'}>
          {type.toUpperCase()} effettuato in data {getFormattedStringDate(date)}.
        </span>
      );
    }
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(ConfirmPage);
