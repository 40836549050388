import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class DropdownSearch extends PureComponent {
  static propTypes = {
    initialValue: PropTypes.string.isRequired,
    doSearch: PropTypes.func.isRequired,
    dropdownName: PropTypes.string.isRequired,
    dropdownList: PropTypes.object,
    label: PropTypes.string.isRequired
  };

  render() {
    const { initialValue, dropdownList, doSearch, dropdownName, label } = this.props;
    return (
      <div className="input-group input-group-sm">
        <div className="input-group-prepend">
          <label className="input-group-text">{label} </label>
        </div>
        <select
          name={dropdownName}
          value={initialValue}
          onChange={e => doSearch(e.target.value, dropdownName)}
          className="form-control form-control-sm"
        >
          <option />
          {Object.keys(dropdownList).map((k, i) => {
            return (
              <option key={i} value={k}>
                {dropdownList[k]}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

export default DropdownSearch;
