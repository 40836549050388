import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { XCircleFillIcon } from '@primer/octicons-react';
import Profiles from '../../lib/models/Profiles';
import StaffList from '../../lib/models/stafflist/StaffList';
import InputSearch from '../Common/InputSearch';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';

class StaffRowEditForm extends PureComponent {
  static propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
  };

  state = {
    profiles: { rows: [] },
    selectedRows: [],
    search: '',
    offset: 0,
    limit: 5,
    saving: false
  };

  renderProfile(profile) {
    const { selectedRows } = this.state;
    const isSelected = selectedRows.some(row => row.id === profile.id);
    const className = `justify-content-between align-items-center cursor-pointer ${isSelected ? 'bg-success' : ''}`;
    return (
      <tr className={className} key={'profile-table-' + profile.id} onClick={() => this.handleRowSelection(profile)}>
        <td>
          {profile.rejected && (
            <span style={{ color: '#E74C3C' }}>
              <XCircleFillIcon size="small" />
            </span>
          )}
        </td>
        <td>{profile.surname}</td>
        <td>{profile.name}</td>
        <td>{profile.qualifications.join(', ')}</td>
      </tr>
    );
  }

  render() {
    const { profiles, selectedRows, search, offset, saving } = this.state;

    return (
      <div
        className="modal show overflow-auto"
        style={{
          backgroundColor: 'rgba(0,0,0,.5)',
          display: 'block'
        }}
        onClick={this.props.onClose}
      >
        <div className="modal-dialog modal-xl" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ricerca profilo:</h5>
              <button type="button" className="close" aria-label="Close Modal" onClick={this.props.onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ minHeight: 420 }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <InputSearch
                      initialValue={search}
                      doSearch={this.handleSearch}
                      clearSearch={e => this.setState({ search: '', offset: 0 })}
                    />
                    <div style={{ height: 300 }}>
                      <table style={{ width: '100%' }} className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th />
                            <th>Cognome</th>
                            <th>Nome</th>
                            <th>Qualifiche</th>
                          </tr>
                        </thead>
                        <tbody>{profiles.rows && profiles.rows.map(profile => this.renderProfile(profile))}</tbody>
                      </table>
                    </div>
                    {(Number(offset) || profiles.hasMore) && (
                      <div className="mt-3 ml-auto p-2 bd-highlight">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination" style={{ margin: 0 }}>
                            <li className={'page-item' + (!Number(offset) ? ' disabled' : '')}>
                              <button className="page-link" onClick={e => this.loadProfilePrevNext(e, 'p')}>
                                Precedente
                              </button>
                            </li>
                            <li className={'page-item' + (profiles.hasMore === false ? ' disabled' : '')}>
                              <button className="page-link" onClick={e => this.loadProfilePrevNext(e, 'n')}>
                                Successivo
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 col-12">
                    <h5>Slot liberi: {this.props.row.freeIds.length - selectedRows.length}</h5>
                    <div style={{ height: 360, overflow: 'auto' }}>
                      <table style={{ width: '100%' }} className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th />
                            <th>Cognome</th>
                            <th>Nome</th>
                            <th>Qualifiche</th>
                          </tr>
                        </thead>
                        <tbody>{selectedRows && selectedRows.map(profile => this.renderProfile(profile))}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                disabled={selectedRows.length < 1 || saving}
                onClick={() => {
                  this.setState({ saving: true }, () => {
                    this.saveProfile().catch(e =>
                      ltToast('Impossibile aggiornare la lista staff: ' + e.message, 5000, true)
                    );
                  });
                }}
              >
                SALVA
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadProfiles();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.search !== this.state.search || prevState.offset !== this.state.offset) {
      this.loadProfiles();
    }
  }

  loadProfilePrevNext = (e, direction) => {
    e.target.blur();
    const { offset, limit } = this.state;
    const newOffset = direction === 'p' ? offset - limit : offset + limit;
    this.setState({ offset: newOffset });
  };

  loadProfiles = () => {
    const { row } = this.props;
    if (!row) return;

    const { limit, offset, search } = this.state;
    Profiles.findAvailable(row.row.id, { limit, offset, order_by: null, sort: null, search })
      .then(profiles => {
        this.setState({ profiles });
      })
      .catch(e => ltToast('Impossibile ottenere la lista: ' + e.message, 5000, true));
  };

  handleRowSelection = profile => {
    console.log('handleRowSelection', this.state.selectedRows);
    const selectedRows = this.state.selectedRows.filter(prof => prof.id !== profile.id);
    if (selectedRows.length === this.state.selectedRows.length) {
      if (this.props.row.freeIds.length === selectedRows.length) {
        return;
      }
      selectedRows.push(profile);
    }
    selectedRows.sort((a, b) => {
      const asurname = a.surname.toLowerCase();
      const bsurname = b.surname.toLowerCase();
      if (asurname === bsurname) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      }
      return asurname > bsurname ? 1 : -1;
    });
    this.setState({ selectedRows });
  };

  handleSearch = search => {
    this.setState({ search, offset: 0 });
  };

  saveProfile = async () => {
    const { row, onClose } = this.props;
    console.log('event_id', row.row.event_id);
    const { selectedRows } = this.state;
    const alls = [];
    for (let i = 0; i < selectedRows.length; i++) {
      alls.push(StaffList.update(row.row.event_id, row.freeIds[i], { field: 'profile_id', value: selectedRows[i].id }));
    }
    const ret = await Promise.allSettled(alls);
    console.log(ret);
    const fullfilled = ret.filter(ret => ret.status === 'fulfilled');
    if (fullfilled.length === 0) {
      throw new Error(ret[0].reason);
    }
    onClose(true);
  };
}

export default StaffRowEditForm;
