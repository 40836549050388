import React from 'react';
import LTFormElement from './LTFormElement';
import PropTypes from 'prop-types';

export default function LTFormTextArea(props) {
  return (
    <>
      <LTFormElement type={'text-area'} {...props} />
    </>
  );
}

LTFormTextArea.prototype = {
  canEdit: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  onEnter: PropTypes.func,
  onEscape: PropTypes.func,
  displayValue: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func
};
