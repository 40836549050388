import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import StaffBlock from '../../lib/models/stafflist/StaffBlock';
import TimePicker from '../Common/TimePicker';
import { cloneObject } from '../../lib/utils/commonutils';

class StaffBlockEditForm extends PureComponent {
  static propTypes = {
    block: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    list: PropTypes.number.isRequired
  };

  state = {
    block: null,
    validated: true,
    inProgress: false
  };

  render() {
    const { block, validated, inProgress } = this.state;
    if (!block) return null;
    const { notes, partner_name, block_size } = block;

    return (
      <div
        className="modal show"
        style={{
          backgroundColor: 'rgba(0,0,0,.5)',
          display: 'block'
        }}
        onClick={this.props.onClose}
      >
        <div className="modal-dialog modal-dialog-centered" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modifica informazioni blocco:</h5>
              <button type="button" className="close" aria-label="Close Modal" onClick={this.props.onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-container">
                <form onSubmit={this.saveEdit}>
                  <div className="form-group">
                    <label htmlFor="slotNumberInput" className="col-form-label">
                      Numero di slot <small>(Minimo {this.props.list || '1'})</small>:
                    </label>
                    <input
                      className="form-control col-sm-6"
                      type="number"
                      id="slotNumberInput"
                      name="block_size"
                      autoComplete="off"
                      value={block_size}
                      onChange={this.handleChange}
                    />
                    <label htmlFor="partnerIdDropdown" className="col-form-label">
                      Partner:
                    </label>
                    <input
                      className="form-control"
                      id="partnerIdDropdown"
                      type="text"
                      readOnly={true}
                      value={partner_name}
                    />

                    <label htmlFor="eventHourDropdown" className="col-form-label">
                      Ora inizio
                    </label>
                    <TimePicker time={this.props.block.start_time} name="start_time" onConfirm={this.submitTime} />

                    <label htmlFor="blockTimeEnd" className="col-form-label">
                      Ora fine
                    </label>
                    <TimePicker time={this.props.block.end_time} name="end_time" onConfirm={this.submitTime} />

                    <label htmlFor="notesTextArea" className="col-form-label">
                      Note:
                    </label>
                    <textarea
                      className="form-control"
                      id="notesTextArea"
                      name="notes"
                      value={notes}
                      onChange={this.handleChange}
                    />

                    {inProgress && (
                      <div className="mt-3 text-center">
                        <em>Salvataggio in corso, attendere prego</em>
                        <div className="progress">
                          <div
                            className="progress-bar progress-bar-striped bg-info"
                            role="progressbar"
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>
                    )}
                    {!inProgress && (
                      <button type="submit" className="btn btn-primary mt-3" disabled={!validated || inProgress}>
                        SALVA
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const block = cloneObject(this.props.block);
    this.setState({ block });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.block.id !== prevProps.block.id) {
      const block = cloneObject(this.props.block);
      this.setState({ block });
    }
  }

  handleChange = event => {
    const { name } = event.target;
    let { value } = event.target;
    let validated = true;
    const validateBlockSize = value => {
      if (!value) return false;
      if (isNaN(value)) return false;
      if (Number(value) < 1) return false;
      return Number(value) >= this.props.list;
    };
    if (name === 'block_size') {
      validated = validateBlockSize(value);
      value = Number(value);
    }
    const newBlock = { ...this.state.block };
    newBlock[name] = value;
    this.setState({ block: newBlock, validated });
  };

  submitTime = (updatedTime, name) => {
    const newBlock = { ...this.state.block };
    newBlock[name] = updatedTime;
    this.setState({ block: newBlock });
  };

  saveEdit = e => {
    e && e.preventDefault();
    if (this.state.inProgress) {
      return;
    }
    this.setState({ inProgress: true }, () => {
      const { block } = this.state;
      const { start_time, end_time, notes, block_size } = block;
      const { block: originalBlock } = this.props;
      if (
        start_time !== originalBlock.start_time ||
        end_time !== originalBlock.end_time ||
        block_size !== originalBlock.block_size ||
        notes !== originalBlock.notes
      ) {
        StaffBlock.update(block.event_id, block.id, { start_time, end_time, notes, block_size })
          .then(() => {
            this.props.onClose(true);
          })
          .catch(e => {
            this.setState({ inProgress: false }, () => {
              ltToast('Fallito salvataggio modifica:' + e.message, false, true);
            });
          });
      } else {
        this.props.onClose(false);
      }
    });
  };
}

export default StaffBlockEditForm;
