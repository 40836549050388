import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PlusIcon } from '@primer/octicons-react';
import { Link } from 'react-router-dom';
import { getCurrentTheme } from '../../lib/utils/windowutils';

class AddSmth extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  state = {
    theme: getCurrentTheme()
  };

  listenOnThemeChange = () => {
    console.log('listenOnThemeChange');
    this.setState({ theme: getCurrentTheme() });
  };

  constructor() {
    super();
    document.querySelector('html').addEventListener('theme-changed', this.listenOnThemeChange);
  }

  componentWillUnmount() {
    document.querySelector('html').removeEventListener('theme-changed', this.listenOnThemeChange);
  }

  render() {
    const { match } = this.props;
    const { theme } = this.state;
    return (
      <div
        className="position-fixed shadow rounded"
        style={{
          zIndex: 999,
          bottom: 20,
          right: 20
        }}
      >
        <Link
          to={match.url === '/' ? `${match.url}events/add` : `${match.url}/add`}
          className={`btn ${theme === 'il-light' ? 'btn-dark' : 'btn-light'}`}
        >
          <PlusIcon />
        </Link>
      </div>
    );
  }
}

export default AddSmth;
