import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Profile from '../../lib/models/admin/Profile';
import Loading from '../Common/Loading';
import ProfileView from './ProfileView';
import ProfileAttachmentsList from '../ProfileAttachments/ProfileAttachmentsList';
import qs from 'qs';
import Partner from '../../lib/models/admin/Partner';
import { connect } from 'react-redux';
import Breadcrumb from '../Breadcrumb';
import ProfileJobList from './ProfileJobList';

const breadcrumbs = [
  { link: '/admin', text: 'Admin' },
  { link: '/admin/profiles', text: 'Profili' }
];

class ProfileMain extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    profile: false,
    current_tab: '',
    error: false,
    breadcrumbs
  };

  tabs = {
    detail: {
      title: 'Dettaglio',
      comp: ProfileView
    },
    attachments: {
      title: 'Allegati',
      comp: ProfileAttachmentsList
    },
    jobs: {
      title: 'Lavori',
      comp: ProfileJobList
    }
  };

  render() {
    const { profile, current_tab, breadcrumbs, error } = this.state;
    const { account, history } = this.props;
    if (error) return <div className="alert alert-danger">{error}</div>;
    if (!current_tab || !profile) return <Loading />;
    const TabComp = this.tabs[current_tab].comp;

    return (
      <div>
        <Breadcrumb crumbs={breadcrumbs} />
        <ul className="nav nav-tabs">
          {Object.keys(this.tabs).map((tab, i) => {
            return (
              <li key={i} className="nav-item">
                <a
                  href={`#${tab}`}
                  className={`nav-link ${tab === current_tab ? 'active' : ''}`}
                  onClick={e => this.setState({ current_tab: tab })}
                >
                  {this.tabs[tab].title}
                </a>
              </li>
            );
          })}
        </ul>
        <div className="py-3">
          <TabComp
            profile={profile}
            account={account}
            history={history}
            onSwitchTab={tab => this.setState({ current_tab: tab })}
          />
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadProfile();
    const { location } = this.props;
    let current_tab = 'detail';
    if (location.hash) {
      const hash = location.hash.substring(1);
      if (this.tabs[hash]) {
        current_tab = hash;
      }
    }
    this.setState({ current_tab });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadProfile();
    }
  }

  loadProfile = () => {
    const { match } = this.props;
    const { params } = match;
    Profile.get(params.id)
      .then(profile => {
        const _breadcrumbs = [...breadcrumbs, { active: true, text: `${profile.name} ${profile.surname}` }];
        this.setState({
          profile,
          breadcrumbs: _breadcrumbs
        });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };

  loadPartners = async () => {
    const { search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    const { limit, offset, order_by, sort, q } = parameters;

    let searchString = '';
    if (q) {
      searchString = q;
    }
    if (this.state.searchString !== searchString) {
      this.setState({ searchString });
    }

    const newPartners = await Partner.find({ limit, offset, order_by, sort, search: q });
    let obj = {};
    for (let i = 0; i < newPartners.rows.length; i++) {
      const { id, name } = newPartners.rows[i];
      obj[id] = name;
    }
    this.setState({ partners: newPartners, partnersObject: obj });
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(ProfileMain);
