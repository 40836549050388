import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb';
import { setTitle } from '../../lib/utils/windowutils';
import EventType from '../../lib/models/admin/EventType';
import UrlManglingInputSearch from '../Common/UrlManglingInputSearch';
import { getPaginationUrl, getQueryParameters } from '../../lib/utils/urlutils';
import AddSmth from '../Common/AddSmth';

class EventTypeComp extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    eventTypes: { rows: [] }
  };

  breadcrumbs = [
    { link: '/admin/', text: 'Admin' },
    { active: true, text: 'Tipologie Evento' }
  ];

  render() {
    const { match, location } = this.props;
    const { eventTypes } = this.state;
    const { offset } = getQueryParameters(location);

    return (
      <div>
        <Breadcrumb crumbs={this.breadcrumbs} />
        <div className="my-3 row">
          <div className="col-12 col-md-4 col-lg-3">
            <UrlManglingInputSearch />
          </div>
        </div>
        <div>
          {eventTypes.rows.length > 0 && (
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Codice</th>
                  <th>Descrizione</th>
                  <th>Stato</th>
                </tr>
              </thead>
              <tbody>
                {eventTypes.rows.map((eventType, i) => {
                  return (
                    <tr key={i} className="justify-content-between align-items-center">
                      <td>
                        <Link to={`${match.path}/${eventType.code}`}>{eventType.code}</Link>
                      </td>
                      <td>{eventType.description}</td>
                      <td>{eventType.status === 1 ? 'Attivo' : 'Disattivo'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {eventTypes.rows.length === 0 && <div className="alert alert-info">Nessuna tipologia evento trovata</div>}
        </div>
        <div className="d-flex bd-highlight mb-3">
          {(Number(offset) || eventTypes.hasMore) && (
            <div className="mt-3 ml-auto p-2 bd-highlight">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={'page-item' + (!Number(getQueryParameters(location).offset) ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'p')} className="page-link" onClick={e => e.target.blur()}>
                      Precedente
                    </Link>
                  </li>
                  <li className={'page-item' + (eventTypes.hasMore === false ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'n')} className="page-link" onClick={e => e.target.blur()}>
                      Successivo
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
        <AddSmth match={match} />
      </div>
    );
  }

  componentDidMount() {
    setTitle('Tipologie Evento');
    this.loadEventTypes();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadEventTypes();
    }
  }

  loadEventTypes = () => {
    const { location } = this.props;
    const { limit, offset, order_by, sort, q } = getQueryParameters(location);

    EventType.find({ limit, offset, order_by, sort, search: q }).then(eventTypes => {
      this.setState({ eventTypes });
    });
  };
}

export default EventTypeComp;
