export const getFormattedStringDate = string => {
  const t = new Date(string);
  return t.toLocaleString();
};

const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const getMonth = date => {
  return month[date.getMonth()];
};

export const getYear = date => {
  return date.getFullYear();
};

export const weekdayshort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const checkExpiringPassword = expirationDate => {
  const today = new Date();
  const expire = new Date(expirationDate);
  const timeDiscrepancy = expire.getTime() - today.getTime();
  const daysDiscrepancy = timeDiscrepancy / (1000 * 3600 * 24);
  return Math.floor(daysDiscrepancy);
};

export const formatDate = ({ date, format = '%D/%M/%Y', doubleDigit = false }) => {
  if (!date) {
    return '';
  }

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  format = format.replace('%Y', year);
  format = format.replace('%M', month < 10 && doubleDigit ? `0${month}` : month);
  format = format.replace('%D', day < 10 && doubleDigit ? `0${day}` : day);
  return format;
};

export const ISOStringToNumberOfHours = isoString => {
  const date = new Date(isoString);
  return date.getTime() / 1000 / 60 / 60;
};

export const numberOfHoursToISOString = number => {
  const value = number * 60 * 60 * 1000;
  return new Date(value).toISOString();
};
