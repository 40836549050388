import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import StaffList from '../../lib/models/stafflist/StaffList';
import { connect } from 'react-redux';
import { staffListRequest } from '../../lib/models/stafflist/StaffListReducer';
import { loadPartners } from '../../lib/models/Partners';
import TimePicker from '../Common/TimePicker';

class StaffListAdd extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    event_id: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };

  state = {
    partners: [],
    validated: false,
    slot_number: 1,
    partner_id: '',
    start_time: '00:00',
    end_time: '00:00',
    notes: ''
  };

  slotNumberRef = React.createRef();

  render() {
    const { partners, validated, slot_number, partner_id, start_time, end_time, notes } = this.state;
    const { account } = this.props;

    return (
      <div
        className="modal show"
        style={{
          backgroundColor: 'rgba(0,0,0,.5)',
          display: 'block'
        }}
        onClick={this.props.onClose}
      >
        <div className="modal-dialog modal-dialog-centered" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Crea nuovo blocco:</h5>
              <button type="button" className="close" aria-label="Close Modal" onClick={this.props.onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-container">
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <label htmlFor="slotNumberInput" className="col-form-label">
                      Numero di slot:
                    </label>
                    <input
                      ref={this.slotNumberRef}
                      className="form-control col-sm-12 col-md-5 col-lg-2"
                      type="number"
                      id="slotNumberInput"
                      name="slot_number"
                      autoComplete="off"
                      value={slot_number.toString()}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="partnerIdDropdown" className="col-form-label">
                      Società esterna:
                    </label>

                    <select
                      className="form-control"
                      id="partnerIdDropdown"
                      name="partner_id"
                      value={partner_id ? partner_id : account.data.partner_id}
                      onChange={this.handleChange}
                    >
                      <option />
                      {partners.map((partner, i) => {
                        return (
                          <option key={i} value={partner.id}>
                            {partner.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="startTimeInput" className="col-form-label">
                      Ora inizio:
                    </label>
                    <TimePicker time={start_time} name="start_time" onConfirm={this.submitTime} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="endTimeInput" className="col-form-label">
                      Ora fine:
                    </label>
                    <TimePicker time={end_time} name="end_time" onConfirm={this.submitTime} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="notesTextArea" className="col-form-label">
                      Note:
                    </label>
                    <textarea
                      className="form-control"
                      id="notesTextArea"
                      name="notes"
                      value={notes}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary" disabled={!validated}>
                        SALVA
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const { account } = this.props;
    if (account && account.isLoaded) {
      this.setState({ partner_id: account.data.partner_id });
    }
    loadPartners().then(partners => {
      this.setState({ partners });
    });
    if (this.slotNumberRef.current) {
      this.slotNumberRef.current.focus();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let validated = false;
    const { slot_number, partner_id, start_time } = this.state;
    if (slot_number && partner_id && start_time !== '00:00' && !isNaN(slot_number) && Number(slot_number) > 0) {
      validated = true;
    }
    this.setState({ validated });
  }

  submitTime = (updatedTime, name) => {
    this.setState({ [name]: updatedTime });
  };

  handleChange = event => {
    const target = event.target;
    const value =
      target.type === 'checkbox' ? target.checked : target.name === 'name' ? target.value.toUpperCase() : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleBlur = () => {};
  handleKeyDown = () => {};

  onSubmit = event => {
    event.preventDefault();
    const { validated } = this.state;
    if (!validated) return;

    const { event_id } = this.props;
    const { slot_number, partner_id, start_time, end_time, notes } = this.state;
    const payload = { slot_number, partner_id, start_time, end_time, notes };
    StaffList.create(event_id, payload)
      .then(() => {
        this.props.dispatch(staffListRequest(event_id));
        this.props.onClose();
      })
      .catch(e => ltToast('Fallita creazione lista:' + e.message, false, true));
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(StaffListAdd);
