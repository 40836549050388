import settings from '../settings';

export function getConfirmation(message) {
  return new Promise((resolve, reject) => {
    const ret = window.confirm(message);
    if (ret) {
      resolve(ret);
    } else {
      reject(new Error('Reject'));
    }
  });
}

export function setTitle(title) {
  document.title = `${title} - ${settings.appDescription}/${settings.version}`;
}

const themes = ['il-dark', 'il-light'];

let currentTheme = 0;

export function setCurrentTheme(theme) {
  if (!theme) {
    theme = localStorage.getItem('theme');
    if (!theme) {
      theme = themes[0];
    }
  }
  currentTheme = themes.indexOf(theme);
  localStorage.setItem('theme', theme);
  const html = document.querySelector('html');
  html.classList.remove(currentTheme === 0 ? themes[1] : themes[0]);
  html.classList.add(theme);
  const event = new Event('theme-changed');
  html.dispatchEvent(event);
}

export function getCurrentTheme() {
  return themes[currentTheme];
}
export function toggleTheme() {
  setCurrentTheme(currentTheme === 0 ? themes[1] : themes[0]);
}

export const assignModalOpenClass = open => {
  if (open) {
    document.body.classList.add('modal-open');
  } else {
    document.body.classList.remove('modal-open');
  }
};
