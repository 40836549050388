import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import Partner from '../../lib/models/admin/Partner';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import Breadcrumb from '../Breadcrumb';

class PartnerAdd extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    partner: false,
    error: false,
    validated: false,
    name: '',
    description: '',
    contact_email: '',
    contact_name: '',
    contact_phone: '',
    address: '',
    legal_address: '',
    piva: '',
    create_event: false
  };

  refInput = React.createRef();

  breadcrumbs = [
    { link: '/admin/', text: 'Admin' },
    { link: '/admin/partners', text: 'Partners' },
    { active: true, text: 'Nuovo' }
  ];

  render() {
    const {
      validated,
      name,
      description,
      contact_email,
      contact_name,
      contact_phone,
      address,
      legal_address,
      piva,
      create_event,
      error
    } = this.state;

    return (
      <div>
        <Breadcrumb crumbs={this.breadcrumbs} />
        {error && <div className="alert alert-danger">{error}</div>}
        <div>
          <div className="row">
            <div className="mb-3 col-12 col-md-8 col-lg-5">
              <div className="card border-primary">
                <div className="card-header bg-primary text-white">
                  <strong>Crea Partner</strong>
                </div>
                <div className="card-body">
                  <form onSubmit={this.onSubmit}>
                    <div className="form-group row">
                      <label htmlFor="partnerInputName" className="col-sm-3 col-form-label">
                        Codice
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          id="partnerInputName"
                          name="name"
                          autoComplete="off"
                          value={name}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="partnerInputDescription" className="col-sm-3 col-form-label">
                        Ragione Sociale
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          id="partnerInputDescription"
                          name="description"
                          autoComplete="off"
                          value={description}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="partnerInputContact" className="col-sm-3 col-form-label">
                        Nome Contatto
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          id="partnerInputContact"
                          name="contact_name"
                          autoComplete="off"
                          value={contact_name}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="partnerInputEmail" className="col-sm-3 col-form-label">
                        Email Contatto
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="email"
                          placeholder="name@example.com"
                          id="partnerInputEmail"
                          name="contact_email"
                          value={contact_email}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="partnerInputPhone" className="col-sm-3 col-form-label">
                        Telefono Contatto
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="phone"
                          id="partnerInputPhone"
                          name="contact_phone"
                          value={contact_phone}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="partnerInputAddress" className="col-sm-3 col-form-label">
                        Indirizzo Sede Operativa
                      </label>
                      <div className="col-sm-9">
                        <textarea
                          rows={5}
                          className="form-control"
                          id="partnerInputAddress"
                          name="address"
                          autoComplete="off"
                          value={address}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="partnerInputLegalAddress" className="col-sm-3 col-form-label">
                        Indirizzo Sede Legale
                      </label>
                      <div className="col-sm-9">
                        <textarea
                          rows={5}
                          className="form-control"
                          placeholder="(facoltativo, max 128 caratteri)"
                          id="partnerInputLegalAddress"
                          name="legal_address"
                          autoComplete="off"
                          value={legal_address}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="partnerInputIVA" className="col-sm-3 col-form-label">
                        Partita IVA
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          placeholder="(facoltativo)"
                          type="text"
                          id="partnerInputIVA"
                          name="piva"
                          autoComplete="off"
                          value={piva}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="create_event" className="col-sm-3 col-form-label">
                        Permesso creazione eventi
                      </label>
                      <div className="col-sm-9 custom-control custom-switch">
                        <input
                          checked={create_event}
                          type="checkbox"
                          className="custom-control-input"
                          name="create_event"
                          id="create_event"
                          onChange={this.handleChange}
                        />
                        <label className="custom-control-label" htmlFor="create_event">
                          {create_event ? 'Attivo' : 'Disattivo'}
                        </label>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary" disabled={!validated}>
                          SALVA
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    setTitle('Partner');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { name, description, contact_email, contact_name, contact_phone, address } = this.state;
    let validated = false;
    if (name && description && contact_email && contact_name && contact_phone && address) {
      validated = true;
    }
    this.setState({ validated });
  }

  handleChange = event => {
    const target = event.target;
    const value =
      target.type === 'checkbox' ? target.checked : target.name === 'name' ? target.value.toUpperCase() : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  onSubmit = event => {
    event.preventDefault();
    const {
      name,
      description,
      contact_email,
      contact_name,
      contact_phone,
      address,
      legal_address,
      piva,
      create_event,
      validated
    } = this.state;
    if (!validated) {
      return;
    }
    const payload = {
      name,
      description,
      contact_email,
      contact_name,
      contact_phone,
      address,
      legal_address,
      piva,
      create_event
    };
    Partner.create(payload)
      .then(data => {
        this.props.history.push(`./${data.id}`);
      })
      .catch(e => {
        ltToast('Fallita creazione cliente:' + e.message, false, true);
      });
    return false;
  };
}

export default PartnerAdd;
