import React, { PureComponent } from 'react';
import Account from '../lib/models/account';
class PrivacyRequest extends PureComponent {
  state = {
    error: false,
    privacy_accept: false
  };

  render() {
    const { privacy_accept, error } = this.state;
    return (
      <div className="container">
        {error && <div className="alert alert-danger mt-3">{error}</div>}
        <div className="alert alert-info mt-5">
          <p>
            Ho preso visione ed accetto{' '}
            <a href="/assets/PRY-Privacy.pdf" target="_blank" rel="noopener noreferrer">
              l'informativa sulla privacy.
            </a>
          </p>
          <div className="text-center">
            <div className="custom-control custom-switch">
              <input
                value="1"
                checked={privacy_accept}
                onChange={e => {
                  this.setState({ privacy_accept: e.target.checked });
                }}
                type="checkbox"
                className="custom-control-input"
                id="customSwitch1"
              />
              <label className="custom-control-label" htmlFor="customSwitch1">
                Accetto
              </label>
            </div>

            <button disabled={!privacy_accept} className="mt-3 btn btn-info" onClick={this.doPrivacyAccept}>
              PROSEGUI
            </button>
          </div>
        </div>
      </div>
    );
  }

  doPrivacyAccept = () => {
    Account.doPrivacyAccept()
      .then(() => {
        window.location.href = '/';
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };
}

export default PrivacyRequest;
