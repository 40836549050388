import { deleteData, getData, postData, putData } from '../utils/httputils';

class EventAttachments {
  static apiPath = '/api/v1/events/{{ event_id }}/attachments';

  static create(event_id, data) {
    return postData(EventAttachments.apiPath.replace('{{ event_id }}', event_id), data);
  }

  static getAttachmentUrl(event_id, attachment_id) {
    return `${EventAttachments.apiPath.replace('{{ event_id }}', event_id)}/${attachment_id}`;
  }

  static find(event_id, params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${EventAttachments.apiPath.replace('{{ event_id }}', event_id)}?limit=${limit || 10}&offset=${offset ||
        0}&order_by=${order_by || 'name'}&sort=${sort || 'asc'}${searchQuery}`
    );
  }

  static get(event_id, id) {
    return getData(`${EventAttachments.apiPath.replace('{{ event_id }}', event_id)}/${id}`);
  }

  static update(event_id, id, field, value) {
    return putData(`${EventAttachments.apiPath.replace('{{ event_id }}', event_id)}/${id}`, { field, value });
  }

  static delete = (event_id, id) => {
    return deleteData(`${EventAttachments.apiPath.replace('{{ event_id }}', event_id)}/${id}`);
  };
}

export default EventAttachments;
