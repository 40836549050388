import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import EventAdd from './EventAdd';
import EventMain from './EventMain';
import UserEventList from './UserEventList';
import ConfirmPage from '../ConfirmPage/ConfirmPage';
import ResponsibleEventMain from './ResponsibleEventMain';
import MasterEventList from './MasterEventList';
import PastEvents from '../UserEvents/Past';
import EventListMain from './EventListMain';

class EventsRoot extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired
  };

  render() {
    const { account } = this.props;
    return (
      <Switch>
        {account.master && <Route exact path={'/'} component={MasterEventList} />}
        {account.admin && <Route exact path={'/'} component={EventListMain} />}
        {(account.responsible || account.operator) && <Route exact path={'/'} component={UserEventList} />}
        {(account.responsible || account.operator) && <Route exact path="/job/:id" component={ConfirmPage} />}
        {(account.responsible || account.operator) && (
          <Route exact path="/events/:id" component={ResponsibleEventMain} />
        )}
        {(account.responsible || account.operator) && <Route exact path={'/past'} component={PastEvents} />}
        {account.admin && <Route exact path={'/events/add'} component={EventAdd} />}
        {account.admin && <Route exact path={'/events/:id'} component={EventMain} />}
      </Switch>
    );
  }
}

export default EventsRoot;
