import qs from 'qs';

export const getQueryParameters = location => {
  const { search } = location;
  return qs.parse(search, { ignoreQueryPrefix: true });
};

export const getPaginationUrl = (location, direction) => {
  const { pathname } = location;
  const parameters = getQueryParameters(location);
  let offset = Number(parameters.offset) || 0;
  let limit = Number(parameters.limit) || 10;
  let offsetPrev = offset - limit;
  let offsetNext = offset + limit;
  if (offsetPrev < 0) {
    offsetPrev = 0;
  }
  parameters.offset = direction === 'p' ? offsetPrev : offsetNext;
  return pathname + qs.stringify(parameters, { addQueryPrefix: true });
};
