import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Event from '../../lib/models/admin/Event';
import { setTitle } from '../../lib/utils/windowutils';
import EventView from './EventView';
import StaffListView from '../StaffList/StaffListView';
import Loading from '../Common/Loading';
import EventAttachmentsList from '../EventAttachments/EventAttachmentsList';
import EventUtilityView from './EventUtilityView';
import Breadcrumb from '../Breadcrumb';
import { connect } from 'react-redux';
import { EVENT_STATUSES } from '../../lib/models/Events';

const breadcrumbs = [{ link: '/', text: 'Eventi' }];

class EventMain extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    event: false,
    current_tab: '',
    error: false,
    breadcrumbs
  };

  tabs = {
    detail: {
      title: 'Dettaglio',
      comp: EventView
    },
    list: {
      title: 'Lista Staff',
      comp: StaffListView,
      rbac: {
        role: 'admin'
      }
    },
    attachments: {
      title: 'Allegati',
      comp: EventAttachmentsList
    },
    utility: {
      title: 'Utilità',
      comp: EventUtilityView,
      rbac: {
        role: 'admin',
        ext: false,
        hideWhenCanceled: true
      }
    }
  };

  render() {
    const { event, current_tab, error, breadcrumbs } = this.state;
    const { account } = this.props;
    if (error) return <div className="alert alert-danger">{error}</div>;
    if (!current_tab || !event) return <Loading />;
    const TabComp = this.tabs[current_tab].comp;
    const eventReady = event.due_date && event.status > EVENT_STATUSES.created; // && event.status < EVENT_STATUSES.canceled;
    return (
      <div>
        <Breadcrumb crumbs={breadcrumbs} />
        <ul className="nav nav-tabs">
          {eventReady &&
            Object.keys(this.tabs)
              .filter(tabKey => {
                const tab = this.tabs[tabKey];
                if (!tab.rbac) return true;
                if (!event.__role[tab.rbac.role]) return false;
                if (tab.rbac.ext === undefined) return true;
                if (event.status === EVENT_STATUSES.canceled) {
                  if (tab.rbac.hideWhenCanceled) {
                    return false;
                  }
                }
                return event.__role.ext === tab.rbac.ext;
              })
              .map((tab, i) => {
                return (
                  <li key={i} className="nav-item">
                    <a
                      href={`#${tab}`}
                      className={`nav-link ${tab === current_tab ? 'active' : ''}`}
                      onClick={e => this.setState({ current_tab: tab })}
                    >
                      {this.tabs[tab].title}
                    </a>
                  </li>
                );
              })}
          {!event.due_date && (
            <li className="nav-item">
              <a
                href={`#${this.tabs.detail}`}
                className={'nav-link active'}
                onClick={e => this.setState({ current_tab: this.tabs.detail })}
              >
                {this.tabs.detail.title}
              </a>
            </li>
          )}
        </ul>

        <div className="py-3">
          <div className="container-fluid">
            <TabComp event={event} account={account} onEventDetailsChange={this.onChangeEventDetails} />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadEvent();
    const { location } = this.props;
    let current_tab = 'detail';
    if (location.hash) {
      const hash = location.hash.substring(1);
      if (this.tabs[hash]) {
        current_tab = hash;
      }
    }
    this.setState({ current_tab });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.event && this.state.event.id && (!prevState.event || prevState.event.id !== this.state.event.id)) {
      setTitle(this.state.event.title);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadEvent();
    }
  }

  loadEvent = () => {
    const { match } = this.props;
    const { params } = match;
    Event.get(params.id)
      .then(event => {
        const _breadcrumbs = [...breadcrumbs, { active: true, text: event.title }];
        this.setState({
          event,
          breadcrumbs: _breadcrumbs
        });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };

  onChangeEventDetails = event => {
    if (event) {
      this.setState({
        event
      });
    } else {
      this.loadEvent();
    }
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(EventMain);
