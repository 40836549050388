import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import { setTitle } from '../../lib/utils/windowutils';
import LTFormInput from '../Common/LT-Form-Elements/LTFormInputs/LTFormInput';
import { TrashIcon } from '@primer/octicons-react';
import Partner from '../../lib/models/admin/Partner';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import LTFormSwitch from '../Common/LT-Form-Elements/LTFormInputs/LTFormSwitch';
import { connect } from 'react-redux';
import LTFormTextArea from '../Common/LT-Form-Elements/LTFormInputs/LTFormTextArea';
import Breadcrumb from '../Breadcrumb';
import { getPartnerLogo } from '../../lib/models/Partners';
import PartnerLogoUploadForm from './PartnerLogoUploadForm';

const breadcrumbs = [
  { link: '/admin', text: 'Admin' },
  { link: '/admin/partners', text: 'Partner' }
];

class PartnerView extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired
  };

  state = {
    partner: false,
    error: false,
    breadcrumbs,
    showLogoForm: false
  };

  refInput = React.createRef();

  render() {
    const { partner, error, breadcrumbs } = this.state;
    let photoUrl = partner.logo ? getPartnerLogo(partner.id, partner.logo, true) : false;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        {!error && !partner && <Loading />}
        <Breadcrumb crumbs={breadcrumbs} />
        {partner && (
          <div>
            <h5 className="mb-3">Modifica Partner</h5>
            <div className="row">
              <div className="mb-3 col-12 col-md-8 col-lg-5">
                <div className="card border-primary">
                  <div className="card-header bg-primary text-white">
                    <strong>Dettagli</strong>
                  </div>
                  <div className="card-body">
                    <dl className="row">
                      <dt className="col-12 col-md-4 col-lg-3">Codice</dt>
                      <dd className="col-12 col-md-8 col-lg-9">{partner.name}</dd>
                      <dt className="col-12 col-md-4 col-lg-3">Ragione Sociale</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'description'}
                        value={partner.description}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Nome Contatto</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'contact_name'}
                        value={partner.contact_name}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Email Contatto</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'contact_email'}
                        value={partner.contact_email}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Telefono Contatto</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'contact_phone'}
                        value={partner.contact_phone}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Sede Operativa</dt>
                      <LTFormTextArea
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'address'}
                        value={partner.address}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Sede Legale</dt>
                      <LTFormTextArea
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'legal_address'}
                        value={partner.legal_address}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Partita IVA</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'piva'}
                        value={partner.piva}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Permesso creazione eventi</dt>
                      <LTFormSwitch
                        canEdit
                        name="create_event"
                        className="col-12 col-md-8 col-lg-9"
                        displayValue={partner.create_event === 1 ? 'Attivo' : 'Disattivato'}
                        value={!!partner.create_event}
                        options={{ 0: 'Disattivo', 1: 'Attivo' }}
                        onChange={e => this.handleChange(e, true)}
                        onEscape={this.undoChanges}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Stato</dt>
                      <LTFormSwitch
                        canEdit
                        name="status"
                        className="col-12 col-md-8 col-lg-9"
                        displayValue={partner.status === 1 ? 'Attivo' : 'Disattivato'}
                        value={!!partner.status}
                        options={{ 0: 'Disattivo', 1: 'Attivo' }}
                        onChange={e => this.handleChange(e, true)}
                        onEscape={this.undoChanges}
                      />
                    </dl>
                  </div>
                </div>
              </div>
              <div className="mb-3 col-12 col-md-6 col-lg-3">
                <div className="card border-primary">
                  <div className="card-header bg-primary text-white">
                    <strong>Logo Partner</strong>
                  </div>
                  <div className="card-body">
                    {photoUrl && <img style={{ width: 200 }} src={photoUrl} className="img-fluid" alt="Partner Logo" />}
                    {!photoUrl && <div className="alert alert-info">Logo Partner non ancora inserito.</div>}
                    <div className="mt-3">
                      <button className="btn btn-primary" onClick={() => this.setState({ showLogoForm: true })}>
                        CARICA LOGO
                      </button>
                      {this.state.showLogoForm && (
                        <PartnerLogoUploadForm
                          partner={partner}
                          onToggleForm={() => this.setState({ showLogoForm: false })}
                          onAttachmentUploaded={() => {
                            this.setState({ showLogoForm: false }, () => this.loadPartner());
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {this.props.account.data.master && (
                <div className="mb-3 col-12 col-md-6 col-lg-4">
                  <div className="card border-danger">
                    <div className="card-header bg-danger text-white">
                      <strong>ATTENZIONE</strong>
                    </div>
                    <div className="card-body">
                      <button className="btn btn-danger" onClick={this.handleDelete}>
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.loadPartner();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.partner && this.state.partner.id && !prevState.partner.id) {
      setTitle(this.state.partner.name);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadPartner();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
  }

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Sei sicuro?');
    if (ok) {
      const { partner } = this.state;
      Partner.delete(partner.id)
        .then(() => {
          this.props.history.push('/admin/partners');
        })
        .catch(e => {
          ltToast('Impossibile cancellare il partner: ' + e.message, 5000, true);
        });
    }
  };

  handleChange = (e, saveAfter) => {
    let newPartner = { ...this.state.partner };
    const { name, type, checked, value } = e.target;
    newPartner[name] = type === 'checkbox' ? checked : value;
    this.setState({ partner: newPartner }, () => {
      if (saveAfter) {
        this.saveEdit(name, this.state.partner[name]);
      }
    });
  };

  saveEdit = (field, value) => {
    const { oldPartner, partner } = this.state;
    if (oldPartner[field] !== value) {
      Partner.update(partner.id, field, value)
        .then(partner => {
          this.setState({ partner, oldPartner: partner }, () => {
            ltToast('Salvataggio effettuato', 2000);
          });
        })
        .catch(e => {
          ltToast('Impossibile salvare: ' + e.message, 5000, true);
        });
    }
  };

  undoChanges = (field, value) => {
    const { oldPartner } = this.state;
    if (oldPartner[field] !== value) {
      let resetedPartner = { ...this.state.oldPartner };
      this.setState({
        partner: resetedPartner
      });
    }
  };

  loadPartner = () => {
    const { match } = this.props;
    const { params } = match;
    Partner.get(params.id)
      .then(partner => {
        const _breadcrumbs = [...breadcrumbs, { active: true, text: partner.description }];
        this.setState({
          partner,
          oldPartner: partner,
          breadcrumbs: _breadcrumbs
        });
      })
      .catch(e => console.error('err', e.message));
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(PartnerView);
