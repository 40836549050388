import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { setTitle } from '../../lib/utils/windowutils';
import Account from '../../lib/models/admin/Account';
import { getRolesAsString } from '../../lib/models/Roles';
import UrlManglingInputSearch from '../Common/UrlManglingInputSearch';
import { getPaginationUrl, getQueryParameters } from '../../lib/utils/urlutils';
import Breadcrumb from '../Breadcrumb';
import AddSmth from '../Common/AddSmth';
import { formatDate } from '../../lib/utils/dateutils';

class AccountComp extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    accounts: { rows: [] }
  };

  breadcrumbs = [
    { link: '/admin/', text: 'Admin' },
    { active: true, text: 'Utenti' }
  ];

  render() {
    const { match, location } = this.props;
    const { accounts } = this.state;
    const { offset } = getQueryParameters(location);

    return (
      <div>
        <Breadcrumb crumbs={this.breadcrumbs} />
        <div className="my-3 row">
          <div className="col-12 col-md-4 col-lg-3">
            <UrlManglingInputSearch />
          </div>
        </div>
        <div>
          {accounts.rows.length > 0 && (
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Nome</th>
                  <th>Partner</th>
                  <th>Ruolo</th>
                  <th>Stato</th>
                  <th>Ultimo Accesso</th>
                </tr>
              </thead>
              <tbody>
                {accounts.rows.map((account, i) => {
                  let last_activity = '';
                  if (account.last_activity_at) {
                    const d_last_activity = new Date(account.last_activity_at);
                    last_activity = `${formatDate({
                      date: d_last_activity,
                      doubleDigit: true
                    })} ${d_last_activity.toLocaleTimeString()}`;
                  }
                  return (
                    <tr key={i} className="justify-content-between align-items-center">
                      <td>
                        <Link to={`${match.path}/${account.id}`}>{account.email}</Link>
                      </td>
                      <td>{account.name}</td>
                      <td>{account.partner_name}</td>
                      <td>{getRolesAsString(account.role)}</td>
                      <td>{account.status === 1 ? 'Attivo' : account.status === 9 ? 'Disattivato' : 'Inattivo'}</td>
                      <td>{last_activity}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {accounts.rows.length === 0 && <div className="alert alert-info">Nessun utente trovato</div>}
        </div>
        <div className="d-flex bd-highlight mb-3">
          {(Number(offset) || accounts.hasMore) && (
            <div className="mt-3 ml-auto p-2 bd-highlight">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={'page-item' + (!Number(offset) ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'p')} className="page-link" onClick={e => e.target.blur()}>
                      Previous
                    </Link>
                  </li>
                  <li className={'page-item' + (accounts.hasMore === false ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'n')} className="page-link" onClick={e => e.target.blur()}>
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
        <AddSmth match={match} />
      </div>
    );
  }

  componentDidMount() {
    setTitle('Utenti');
    this.loadAccounts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadAccounts();
    }
  }

  loadAccounts = () => {
    const { location } = this.props;
    const { limit, offset, order_by, sort, q } = getQueryParameters(location);

    Account.find({ limit, offset, order_by, sort, search: q })
      .then(accounts => {
        this.setState({ accounts });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };
}

export default AccountComp;
