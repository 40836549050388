export const copyToClipboard = (str, next) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  next && next(true);
};

export const handleOnKeyDown = ({ event, onEnter, onEscape, sideEffect }) => {
  if (event.key === 'Enter' && onEnter) {
    if (typeof onEnter === 'function') {
      onEnter();
    }
    if (typeof sideEffect === 'function') {
      sideEffect();
    }
  } else if (event.key === 'Escape' && onEscape) {
    if (typeof onEscape === 'function') {
      onEscape();
    }
    if (typeof sideEffect === 'function') {
      sideEffect();
    }
  }
};

export const sum = arr => {
  return arr.reduce((a, b) => {
    return a + b;
  }, 0);
};

export const fromHoursToSeconds = hours => {
  return Number(hours) * 60 * 60;
};

export const fromMinutesToSeconds = minutes => {
  return Number(minutes) * 60;
};

export const formatJobDuration = duration => {
  return (
    duration
      .split(':')
      .slice(0, -1)
      .join('h ') + 'm'
  );
};

const transformDurationArrayToSeconds = array => {
  let arrayInSeconds = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i]) {
      const hoursMinutes = array[i].split(':');
      const hoursInSeconds = fromHoursToSeconds(hoursMinutes[0]);
      const minutesInSeconds = fromMinutesToSeconds(hoursMinutes[1]);
      const durationInSeconds = hoursInSeconds + minutesInSeconds;
      arrayInSeconds.push(durationInSeconds);
    }
  }
  return arrayInSeconds;
};

export const sumJobDuration = array => {
  const durationsToSum = transformDurationArrayToSeconds(array);
  const sumDurations = sum(durationsToSum);
  const hours = Math.floor(sumDurations / 60 / 60);
  const minutes = sumDurations / 60 - hours * 60;
  return `${hours}h ${minutes}m`;
};

export const expandRows = (e, array, item) => {
  const opened = array.has(item.id);
  e.target.type === 'submit' ? e.target.blur() : e.target.parentElement.blur();
  if (opened) {
    array.delete(item.id);
  } else {
    array.add(item.id);
  }
  return array;
};

export const getReadableFileSizeString = function(fileSizeInBytes) {
  var i = -1;
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export const trimIfNotNull = value => {
  if (value) return value.trim();
  return value;
};

export const debounce = (func, delay, immediate) => {
  let timeout;
  return function executedFunction() {
    let context = this;
    let args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, delay);
    if (callNow) func.apply(context, args);
  };
};

export const CONTACT_METHODS = {
  email: 'Email',
  whatsapp: 'Whatsapp',
  sms: 'SMS'
};

export const getProvinces = () => {
  return [
    'AG',
    'AL',
    'AN',
    'AO',
    'AP',
    'AQ',
    'AR',
    'AT',
    'AV',
    'BA',
    'BG',
    'BI',
    'BL',
    'BN',
    'BO',
    'BR',
    'BS',
    'BT',
    'BZ',
    'CA',
    'CB',
    'CE',
    'CH',
    'CL',
    'CN',
    'CO',
    'CR',
    'CS',
    'CT',
    'CZ',
    'EN',
    'FC',
    'FE',
    'FG',
    'FI',
    'FM',
    'FR',
    'FU',
    'GE',
    'GO',
    'GR',
    'IM',
    'IS',
    'KR',
    'LC',
    'LE',
    'LI',
    'LO',
    'LT',
    'LU',
    'MB',
    'MC',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NA',
    'NO',
    'NU',
    'OR',
    'PA',
    'PC',
    'PD',
    'PE',
    'PG',
    'PI',
    'PL',
    'PN',
    'PO',
    'PR',
    'PT',
    'PU',
    'PV',
    'PZ',
    'RA',
    'RC',
    'RE',
    'RG',
    'RI',
    'RM',
    'RN',
    'RO',
    'SA',
    'SI',
    'SO',
    'SP',
    'SR',
    'SS',
    'SU',
    'SV',
    'TA',
    'TE',
    'TN',
    'TO',
    'TP',
    'TR',
    'TS',
    'TV',
    'UD',
    'VA',
    'VB',
    'VC',
    'VE',
    'VI',
    'VR',
    'VT',
    'VV',
    'ZR'
  ];
};

function forEach(array, iteratee) {
  let index = -1;
  const length = array.length;
  while (++index < length) {
    iteratee(array[index], index);
  }
  return array;
}

export function cloneObject(target, map = new WeakMap()) {
  if (typeof target === 'object') {
    const isArray = Array.isArray(target);
    let cloneTarget = isArray ? [] : {};

    if (map.get(target)) {
      return map.get(target);
    }
    map.set(target, cloneTarget);

    if (isArray) {
      forEach(target, (value, index) => {
        cloneTarget[index] = value;
      });
    } else {
      forEach(Object.keys(target), (key, index) => {
        cloneTarget[key] = target[key];
      });
    }
    return cloneTarget;
  } else {
    return target;
  }
}

const ROLE_TRANSLATIONS = {
  master: 'master',
  admin: 'amministratore',
  responsible: 'responsabile',
  operator: 'operatore'
};

export const rolesToIta = role => {
  const roles = Object.keys(ROLE_TRANSLATIONS);
  for (let i = 0; i < roles.length; i++) {
    if (roles[i] === role) {
      return ROLE_TRANSLATIONS[roles[i]];
    }
  }
};
