import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { formatDate } from '../../lib/utils/dateutils';
import Profile from '../../lib/models/admin/Profile';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';

class ASCUpdate extends PureComponent {
  static propTypes = {
    profile: PropTypes.object.isRequired
  };

  state = {
    error: false,
    validated: false,
    asc_code: this.props.profile.asc_code,
    asc_release_date: this.props.profile.asc_release_date ? new Date(this.props.profile.asc_release_date) : '',
    asc_renew_date: this.props.profile.asc_renew_date ? new Date(this.props.profile.asc_renew_date) : ''
  };

  render() {
    const { profile } = this.props;
    const { validated, asc_code, asc_release_date, asc_renew_date, error } = this.state;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="card border-primary">
          <div className="card-header bg-primary text-white">
            <strong>ASC</strong>
          </div>
          <div className="card-body">
            <form onSubmit={this.onSubmit}>
              <div className="form-group row">
                <label htmlFor="profileInputAsc" className="col-sm-3 col-lg-4 col-form-label">
                  ASC
                </label>
                <div className="col-sm-9 col-lg-8">
                  <input
                    className="form-control"
                    type="text"
                    id="profileInputAsc"
                    autoComplete="off"
                    name="asc_code"
                    placeholder={profile.asc_code ? profile.asc_code : 'Codice ASC'}
                    value={asc_code}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="ProfileInputASCDate" className="col-sm-3 col-lg-4 col-form-label">
                  ASC Data d'Iscrizione/Rilascio
                </label>
                <div className="col-sm-9 col-lg-8">
                  <DatePicker
                    id="ProfileInputASCDate"
                    name="asc_release_date"
                    type="date"
                    locale="it"
                    dateFormat="dd/MM/yyyy"
                    autoComplete="off"
                    className="form-control mt-2"
                    selected={asc_release_date}
                    onChange={asc_release_date => this.onDateChange(asc_release_date, 'asc_release_date')}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="ProfileInputASCRenewDate" className="col-sm-3 col-lg-4 col-form-label">
                  ASC Data Rinnovo
                </label>
                <div className="col-sm-9 col-lg-8">
                  <DatePicker
                    id="ProfileInputASCRenewDate"
                    name="asc_renew_date"
                    type="date"
                    locale="it"
                    dateFormat="dd/MM/yyyy"
                    autoComplete="off"
                    className="form-control mt-2"
                    selected={asc_renew_date}
                    onChange={asc_renew_date => this.onDateChange(asc_renew_date, 'asc_renew_date')}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-10">
                  <button type="submit" className="btn btn-primary" disabled={!validated}>
                    SALVA
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { asc_code, asc_release_date } = this.state;
    let validated = false;
    if (asc_code && asc_release_date) {
      validated = true;
    }
    this.setState({ validated });
  }

  onDateChange = (e, name) => {
    if (name === 'asc_release_date') {
      this.setState({ asc_release_date: e });
    } else if (name === 'asc_renew_date') {
      this.setState({ asc_renew_date: e });
    }
  };

  handleChange = event => {
    const target = event.target;
    const value =
      target.type === 'checkbox' ? target.checked : target.name === 'code' ? target.value.toUpperCase() : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  onSubmit = event => {
    event.preventDefault();
    const { validated, asc_code, asc_release_date, asc_renew_date } = this.state;
    if (!validated) {
      return;
    }
    const formatted_asc_release_date = asc_release_date
      ? formatDate({ date: asc_release_date, format: '%Y-%M-%D', doubleDigit: true })
      : null;
    const formatted_asc_renew_date = asc_renew_date
      ? formatDate({ date: asc_renew_date, format: '%Y-%M-%D', doubleDigit: true })
      : null;
    const payload = {
      asc_code,
      asc_release_date: formatted_asc_release_date,
      asc_renew_date: formatted_asc_renew_date
    };

    Profile.updateAsc(this.props.profile.id, payload)
      .then(data => {
        ltToast('Salvataggio effettuato', 2000);
        this.props.history.push(`./${data.id}`);
      })
      .catch(e => {
        ltToast('Fallito aggiornamento profilo:' + e.message, false, true);
      });
    return false;
  };
}

export default ASCUpdate;
