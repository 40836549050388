import { getData, postData, putData } from '../../utils/httputils';

class StaffList {
  static get(event_id) {
    const url = `/api/v1/events/${event_id}/staff`;
    return getData(url);
  }

  static create(event_id, data) {
    const url = `/api/v1/events/${event_id}/staff`;
    return postData(url, data);
  }

  static getJob(event_id, row_id) {
    const url = `/api/v1/lists/${event_id}/lists/${row_id}`;
    return getData(url);
  }

  static getList(staff_list_id) {
    const url = `/api/v1/lists/${staff_list_id}`;
    return getData(url);
  }

  static update(event_id, row_id, data, manual = false) {
    const url = `/api/v1/events/${event_id}/staff/lists/${row_id}?m=${manual}`;
    return putData(url, data);
  }
}

export default StaffList;
