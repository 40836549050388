import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Account from '../../lib/models/account';
import ChangePasswordForm from '../Common/ChangePasswordForm';
import NoUserComponent from '../Common/NoUserComponent';
import { connect } from 'react-redux';
import { setTitle } from '../../lib/utils/windowutils';

const Header = () => {
  return (
    <div className="header text-center py-4">
      <img src={process.env.PUBLIC_URL + '/assets/logo-inlista-white.png'} alt="logo InLista" />
      <div className="mt-4 p-2 pt-3 bg-login">
        <h5>Imposta la tua password</h5>
      </div>
    </div>
  );
};

class Confirm extends PureComponent {
  state = {
    valid_token: undefined,
    message: undefined
  };

  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { account, match } = this.props;
    if (account.data) {
      return;
    }
    const { token } = match.params;
    this.getConfirmation(token);
    setTitle(this.getTitle());
    const htmlClass = document.querySelector('html').classList;
    if (htmlClass.contains('il-light')) {
      htmlClass.remove('il-light');
      htmlClass.add('il-dark');
      this.switchBodyClass = true;
    } else {
      this.switchBodyClass = false;
    }
  }

  componentWillUnmount() {
    if (this.switchBodyClass) {
      const htmlClass = document.querySelector('html').classList;
      htmlClass.remove('il-dark');
      htmlClass.add('il-light');
    }
  }

  render() {
    const { account, history, match } = this.props;
    const { token } = match.params;
    return (
      <NoUserComponent account={account} history={history}>
        <div className="login-wrapper">
          <div className="">
            <Header />
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  {typeof this.state.valid_token === 'undefined' ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : this.state.valid_token ? (
                    <ChangePasswordForm hideTitle={true} token={token} onPasswordChanged={this.onPasswordChanged} />
                  ) : (
                    <div className="well well-lg text-center mt-3">
                      <h3>{this.state.message}</h3>
                      <Link to="/" className="btn btn-primary mt-5">
                        CONTINUA
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </NoUserComponent>
    );
  }

  getTitle = () => {
    return this.props.match.path.indexOf('/reset') === 0 ? 'Reimposta la tua password' : 'Imposta la tua password';
  };

  onPasswordChanged = () => {
    this.props.history.push('/login?pc=1');
  };

  getConfirmation(token) {
    Account.checkConfirmToken(token)
      .then(json => {
        this.setState({ valid_token: true });
      })
      .catch(err => {
        this.setState({ valid_token: false, message: err.message });
      });
  }
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(Confirm);
