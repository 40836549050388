import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import Customer from '../../lib/models/admin/Customer';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import { loadCustomers } from '../../lib/models/Customers';
import Breadcrumb from '../Breadcrumb';

class CustomerAdd extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    customer: false,
    error: false,
    validated: false,
    code: '',
    description: '',
    email: '',
    contact: '',
    address: '',
    status: 1
  };

  refInput = React.createRef();

  breadcrumbs = [
    { link: '/admin/', text: 'Admin' },
    { link: '/admin/customers', text: 'Clienti' },
    { active: true, text: 'Nuovo' }
  ];

  render() {
    const { validated, code, description, email, contact, address, status, error } = this.state;

    return (
      <div>
        <Breadcrumb crumbs={this.breadcrumbs} />
        {error && <div className="alert alert-danger">{error}</div>}
        <div>
          <div className="row">
            <div className="mb-3 col-12 col-md-8 col-lg-5">
              <div className="card border-primary">
                <div className="card-header bg-primary text-white">
                  <strong>Crea Cliente</strong>
                </div>
                <div className="card-body">
                  <form onSubmit={this.onSubmit}>
                    <div className="form-group row">
                      <label htmlFor="customerInputCode" className="col-sm-3 col-form-label">
                        Codice
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          id="customerInputCode"
                          name="code"
                          autoComplete="off"
                          value={code}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="customerInputDescription" className="col-sm-3 col-form-label">
                        Ragione Sociale
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          id="customerInputDescription"
                          name="description"
                          autoComplete="off"
                          value={description}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="customerInputEmail" className="col-sm-3 col-form-label">
                        Email
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="email"
                          id="customerInputEmail"
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="customerInputContact" className="col-sm-3 col-form-label">
                        Contatto
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          id="customerInputContact"
                          name="contact"
                          autoComplete="off"
                          value={contact}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="customerInputAddress" className="col-sm-3 col-form-label">
                        Indirizzo
                      </label>
                      <div className="col-sm-9">
                        <textarea
                          rows={4}
                          className="form-control"
                          id="customerInputAddress"
                          name="address"
                          autoComplete="off"
                          value={address}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputName" className="col-sm-3 col-form-label">
                        Stato
                      </label>
                      <div className="col-sm-9 pt-2">
                        <div className="custom-control custom-switch">
                          <input
                            checked={status}
                            type="checkbox"
                            className="custom-control-input"
                            name="status"
                            id="status"
                            onChange={this.handleChange}
                          />
                          <label className="custom-control-label" htmlFor="status">
                            {status ? 'Attivo' : 'Disattivo'}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary" disabled={!validated}>
                          SALVA
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    setTitle('Clienti');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { code, description, email, contact } = this.state;
    let validated = false;
    if (code && description && email && contact) {
      validated = true;
    }
    this.setState({ validated });
  }

  handleChange = event => {
    const target = event.target;
    const value =
      target.type === 'checkbox' ? target.checked : target.name === 'code' ? target.value.toUpperCase() : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  onSubmit = event => {
    event.preventDefault();
    const { code, description, email, contact, address, status, validated } = this.state;
    if (!validated) {
      return;
    }
    const payload = { code, description, email, contact, address, status };
    Customer.create(payload)
      .then(data => {
        loadCustomers(true).then(() => {
          this.props.history.push(`./${data.id}`);
        });
      })
      .catch(e => {
        ltToast('Fallita creazione cliente:' + e.message, false, true);
      });
    return false;
  };
}

export default CustomerAdd;
