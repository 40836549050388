import qs from 'qs';

export const getDropdownVariables = (variable, eventsDetails) => {
  let list = {};
  let column = '';
  let value = '';
  if (variable === 'responsibles') {
    column = 'responsible_account_id';
    value = 'responsible_name';
  } else if (variable === 'customers') {
    column = 'customer_id';
    value = 'customer_name';
  } else if (variable === 'partners') {
    column = 'partner_id';
    value = 'partner_name';
  }
  for (let i = 0; i < eventsDetails.rows.length; i++) {
    const _key = eventsDetails.rows[i][column];
    if (_key) {
      if (!list[_key]) {
        const _value = eventsDetails.rows[i][value]?.trim();
        if (_value) {
          list[_key] = _value;
        }
      }
    }
  }
  return list;
};

export const getSearchObject = location => {
  const { search } = location;
  const parsed = qs.parse(search, { ignoreQueryPrefix: true });
  const searchItemsArray = parsed.q?.split(' ');
  let searchObject = { main: [] };
  for (let i = 0; i < searchItemsArray?.length; i++) {
    const keyValueArray = searchItemsArray[i].split(':');
    if (!keyValueArray[1]) {
      searchObject.main.push(keyValueArray[0]);
    } else {
      searchObject[keyValueArray[0]] = keyValueArray[1];
    }
  }
  searchObject.main = searchObject.main.join(' ');
  parsed.q = searchObject;
  return parsed;
};

export const getHandleSearch = (searchInput, inputName, location) => {
  const searchObject = getSearchObject(location);
  if (!inputName) {
    searchObject.q.main = searchInput;
  } else {
    if (!searchInput) {
      delete searchObject.q[inputName];
    } else {
      searchObject.q[inputName] = searchInput;
    }
  }
  searchObject.offset = 0;
  searchObject.q = Object.keys(searchObject.q)
    .map(key => {
      if (key === 'main') {
        return searchObject.q.main;
      }
      return `${key}:${searchObject.q[key]}`;
    })
    .filter(str => str)
    .join(' ');
  return searchObject;
};
