import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Event from '../../lib/models/admin/Event';
import EventBase from '../../lib/models/Event';
import LTFormInput from '../Common/LT-Form-Elements/LTFormInputs/LTFormInput';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import LTFormDropDown from '../Common/LT-Form-Elements/LTFormInputs/LTFormDropDown';
import { loadCustomers } from '../../lib/models/Customers';
import { loadPartners } from '../../lib/models/Partners';
import { loadEventTypes } from '../../lib/models/EventTypes';
import LTFormSwitch from '../Common/LT-Form-Elements/LTFormInputs/LTFormSwitch';
import LTFormTextArea from '../Common/LT-Form-Elements/LTFormInputs/LTFormTextArea';
import { EVENT_STRINGS, getEventStatusString } from '../../lib/models/Events';
import { formatDate } from '../../lib/utils/dateutils';

class EventView extends PureComponent {
  static propTypes = {
    event: PropTypes.object.isRequired,
    onEventDetailsChange: PropTypes.func.isRequired
  };

  state = {
    error: false,
    customers: {},
    partners: {},
    event_types: {},
    event_statuses: {},
    reload_event: false
  };

  left_box_classes = 'mb-3 col-12 col-md-8 col-lg-6';
  event_table_left_classes = 'col-12 col-md-6 col-lg-4';
  event_table_right_classes = 'col-12 col-md-6 col-lg-8';

  renderInput = (canEdit, source, keyname, displayValue = 'non indicato') => {
    return (
      <LTFormInput
        className={this.event_table_right_classes}
        canEdit={canEdit}
        name={keyname}
        displayValue={source[keyname] ? source[keyname] : displayValue}
        value={source[keyname]}
        onChange={this.handleChange}
        onEscape={this.undoChanges}
        onBlur={this.saveEdit}
        onEnter={this.saveEdit}
      />
    );
  };

  renderDropDown = (canEdit, source, keyname, options, displayValue = ' ?? ') => {
    return (
      <LTFormDropDown
        className={this.event_table_right_classes}
        name={keyname}
        displayValue={displayValue}
        value={source[keyname] || ''}
        canEdit={canEdit}
        options={options}
        onChange={this.handleChange}
        onEscape={this.undoChanges}
      />
    );
  };

  render() {
    const { event, error, customers, partners, event_types, event_statuses } = this.state;
    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }
    if (!event) return <div />;
    const canEdit = event?.__role?.admin && !event.__role?.ext && event.status !== 500 && event.status !== 900;
    const ext = !event || event.__role?.ext;
    return (
      <div className="row">
        <div className={this.left_box_classes}>
          <div className="card border-primary">
            <div className="card-header bg-primary text-white">
              <strong>Dettaglio</strong>
            </div>
            <div className="card-body">
              <dl className="row">
                <dt className={this.event_table_left_classes}>Società responsabile:</dt>
                {this.renderDropDown(false, event, 'partner_id', partners, event.partner_description)}
                <dt className={this.event_table_left_classes}>Titolo</dt>
                {this.renderInput(canEdit, event, 'title', 'titolo')}
                {!ext && <dt className={this.event_table_left_classes}>Codice commessa:</dt>}
                {!ext && this.renderInput(canEdit, event, 'order_code', 'codice commessa')}
                {!ext && <dt className={this.event_table_left_classes}>Cliente:</dt>}
                {!ext && this.renderDropDown(canEdit, event, 'customer_id', customers, event.customer_description)}
                <dt className={this.event_table_left_classes}>Tipologia:</dt>
                {this.renderDropDown(canEdit, event, 'event_type_code', event_types, event.event_type_description)}
                <dt className={this.event_table_left_classes}>Responsabile:</dt>
                {this.renderDropDown(
                  canEdit,
                  event,
                  'responsible_account_id',
                  this.loadPossibleResponsibles,
                  event.responsible_account_name || ''
                )}
                <dt className={this.event_table_left_classes}>Indirizzo</dt>
                <LTFormTextArea
                  className={this.event_table_right_classes}
                  canEdit={canEdit}
                  name="address"
                  displayValue={
                    event.address ? (
                      <div dangerouslySetInnerHTML={{ __html: event.address.replace(/\n/g, '<br />') }} />
                    ) : (
                      <p>&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    )
                  }
                  value={event.address}
                  onChange={this.handleChange}
                  onEscape={this.undoChanges}
                  onBlur={this.saveEdit}
                  rows={5}
                />
                <dt className={this.event_table_left_classes}>Data:</dt>
                <LTFormInput
                  type="date"
                  className={this.event_table_right_classes}
                  canEdit={canEdit}
                  name={'due_date'}
                  displayValue={event.due_date ? formatDate({ date: new Date(event.due_date), doubleDigit: true }) : ''}
                  value={
                    event.due_date ? (event.due_date instanceof Date ? event.due_date : new Date(event.due_date)) : ''
                  }
                  onChange={this.onDateChange}
                  onEscape={this.undoChanges}
                  onBlur={this.saveEdit}
                />
                <dt className={this.event_table_left_classes}>Orario di inizio:</dt>
                <LTFormInput
                  type="time"
                  className={this.event_table_right_classes}
                  canEdit={canEdit}
                  name="scheduled_hour"
                  showSave={true}
                  displayValue={event.scheduled_hour || '-'}
                  value={event.scheduled_hour || '00:00'}
                  onChange={(value, name) => {
                    this.handleChange({ target: { name, value } });
                  }}
                  saveClassName="btn btn-primary"
                  onEscape={this.undoChanges}
                  onBlur={this.saveEdit}
                />
                <dt className={this.event_table_left_classes}>Durata (in ore):</dt>
                <LTFormInput
                  type="number"
                  className={this.event_table_right_classes}
                  canEdit={canEdit}
                  name={'duration'}
                  value={event.duration || ''}
                  onChange={this.handleChange}
                  onEscape={this.undoChanges}
                  onBlur={this.saveEdit}
                  onEnter={this.saveEdit}
                />
                {!ext && <dt className={this.event_table_left_classes}>Stato:</dt>}
                {!ext &&
                  this.renderDropDown(canEdit, event, 'status', event_statuses, getEventStatusString(event.status))}

                {!ext && <dt className={this.event_table_left_classes}>Offline:</dt>}
                {!ext && (
                  <LTFormSwitch
                    className={this.event_table_right_classes}
                    canEdit={canEdit}
                    onChange={this.handleChange}
                    displayValue={!!event.offline ? 'SI' : 'NO'}
                    name={'offline'}
                    value={!!event.offline}
                    onBlur={this.saveEdit}
                  />
                )}
              </dl>
            </div>
          </div>
        </div>
        {!ext && (
          <div className={this.left_box_classes}>
            <div className="card border-primary">
              <div className="card-header bg-primary text-white">
                <strong>Note</strong>
              </div>
              <div className="card-body">
                <dl className="row">
                  <dt className={this.event_table_left_classes}>Note tecniche:</dt>
                  <LTFormTextArea
                    className={this.event_table_right_classes}
                    canEdit={canEdit}
                    name={'technical_notes'}
                    displayValue={
                      event.technical_notes ? (
                        <div dangerouslySetInnerHTML={{ __html: event.technical_notes.replace(/\n/g, '<br />') }} />
                      ) : (
                        <p className="py-4 border-primary border-bottom">&nbsp;&nbsp;&nbsp;&nbsp;</p>
                      )
                    }
                    value={event.technical_notes}
                    onChange={this.handleChange}
                    onEscape={this.undoChanges}
                    onBlur={this.saveEdit}
                    rows={5}
                  />
                  <dt className={this.event_table_left_classes}>Note generiche:</dt>
                  <LTFormTextArea
                    className={this.event_table_right_classes}
                    canEdit={canEdit}
                    name={'generic_notes'}
                    displayValue={
                      event.generic_notes ? (
                        <div dangerouslySetInnerHTML={{ __html: event.generic_notes.replace(/\n/g, '<br />') }} />
                      ) : (
                        <p className="py-4 border-primary border-bottom">&nbsp;&nbsp;&nbsp;&nbsp;</p>
                      )
                    }
                    value={event.generic_notes || ''}
                    onChange={this.handleChange}
                    onEscape={this.undoChanges}
                    onBlur={this.saveEdit}
                    rows={5}
                  />
                </dl>
              </div>
            </div>
          </div>
        )}
        {!ext && (
          <div className={this.left_box_classes}>
            <div className="card border-primary">
              <div className="card-header bg-primary text-white">
                <strong>Dati Cliente</strong>
              </div>
              <div className="card-body">
                <dl className="row">
                  <dt className={this.event_table_left_classes}>Nome del referente principale:</dt>
                  {this.renderInput(canEdit, event, 'main_referent_name')}
                  <dt className={this.event_table_left_classes}>Contatto del referente principale:</dt>
                  {this.renderInput(canEdit, event, 'main_referent_email')}
                  <dt className={this.event_table_left_classes}>Nome del referente secondario:</dt>
                  {this.renderInput(canEdit, event, 'secondary_referent_name')}
                  <dt className={this.event_table_left_classes}>Contatto del referente secondario:</dt>
                  {this.renderInput(canEdit, event, 'secondary_referent_email')}
                </dl>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    const { event } = this.props;
    const oldEvent = Object.assign({}, event);
    this.setState({ event, oldEvent });
    const canEdit = event?.__role?.admin && !event.__role?.ext;
    const ext = !event || event.__role?.ext;
    if (canEdit && !ext) {
      this.loadExternalData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.event.id !== prevProps.event.id ||
      (this.props.event !== prevProps.event && this.state.reload_event)
    ) {
      const { event } = this.props;
      const oldEvent = Object.assign({}, event);
      this.setState({ event, oldEvent, reload_event: false });
    }
  }

  loadPossibleResponsibles = () => {
    return EventBase.getAvailableResponsibles(this.props.event.id);
  };

  loadExternalData = () => {
    const _actions = [loadCustomers(), loadPartners(), loadEventTypes()];
    Promise.all(_actions).then(([customers, partners, eventTypes]) => {
      const customersObject = {};
      if (customers) {
        customers.forEach(item => {
          customersObject[item.id] = item.code;
        });
      }
      const partnersObject = {};
      partners.forEach(item => {
        partnersObject[item.id] = item.name;
      });
      const typesObject = {};
      eventTypes.forEach(item => {
        typesObject[item.code] = item.description;
      });
      const eventStatusesObject = this.setEventStatusObject(this.state.event);
      this.setState({
        customers: customersObject,
        partners: partnersObject,
        event_types: typesObject,
        event_statuses: eventStatusesObject
      });
    });
  };

  onDateChange = e => {
    const newEvent = { ...this.state.event };
    newEvent['due_date'] = e;
    this.setState({ event: newEvent });
  };

  handleChange = e => {
    const { target } = e;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const newEvent = { ...this.state.event };
    newEvent[name] = value;
    if (target.type === 'select-one') {
      this.setState({ event: newEvent }, () => this.saveEdit(name, value));
    } else if (name === 'scheduled_hour') {
      this.setState({ event: newEvent });
    } else {
      this.setState({ event: newEvent });
    }
  };

  saveEdit = (field, value) => {
    const { oldEvent, event } = this.state;
    if (field === 'due_date') {
      if (value) {
        value = formatDate({ date: value, format: '%Y-%M-%D', doubleDigit: true });
      }
    } else if (field === 'duration') {
      if (!value) return;
    } else if (field === 'status') {
      let message = 'Sei sicuro?';
      if (value === '500' || value === '900') {
        message += '\nATTENZIONE: operazione non reversibile';
      }

      const ok = window.confirm(message);
      if (!ok) {
        this.undoChanges(field, value);
        return;
      }
    }
    if (oldEvent[field] !== value) {
      Event.update(event.id, field, value)
        .then(event => {
          this.setState({ reload_event: true }, () => {
            this.props.onEventDetailsChange(event);
            ltToast('Salvataggio effettuato', 2000);
          });
        })
        .catch(e => {
          ltToast('Fallito salvataggio modifica:' + e.message, false, true);
          this.setState({ event: Object.assign({}, oldEvent) });
        });
    }
  };

  undoChanges = (field, value) => {
    const { oldEvent } = this.state;
    if (oldEvent[field] !== value) {
      let resetedEvent = { ...this.state.oldEvent };
      this.setState({
        event: resetedEvent
      });
    }
  };

  setEventStatusObject = event => {
    return EVENT_STRINGS;
  };
}

export default EventView;
