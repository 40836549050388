import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Event from '../../lib/models/Event';
import { setTitle } from '../../lib/utils/windowutils';
import StaffListView from '../StaffList/StaffListView';
import Loading from '../Common/Loading';
import EventAttachmentsList from '../EventAttachments/EventAttachmentsList';
import { connect } from 'react-redux';
import ResponsibleEventView from './ResponsibleEventView';
import { EVENT_STATUSES } from '../../lib/models/Events';

class ResponsibleEventMain extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    event: false,
    current_tab: '',
    error: false
  };

  tabs = {
    detail: {
      title: 'Dettaglio',
      comp: ResponsibleEventView
    },
    list: {
      title: 'Lista Staff',
      comp: StaffListView
    },
    attachments: {
      title: 'Allegati',
      comp: EventAttachmentsList
    }
  };

  render() {
    const { event, current_tab, error } = this.state;
    if (error) return <div className="alert alert-danger">{error}</div>;
    if (!current_tab || !event) return <Loading />;
    if (event.offline) {
      return (
        <div className="p-5 mx-5 text-center">
          <div className="alert alert-warning">
            <h1>ATTENZIONE</h1>
            <h2>EVENTO IN MODALITA' OFFLINE</h2>
            <h3>Registra su cartaceo le date di inizio/fine lavoro degli operatori</h3>
          </div>
        </div>
      );
    }
    const TabComp = this.tabs[current_tab].comp;
    const eventReady =
      event.due_date && event.status > EVENT_STATUSES.created && event.status < EVENT_STATUSES.canceled;
    return (
      <div>
        <ul className="nav nav-tabs">
          {eventReady &&
            Object.keys(this.tabs)
              .filter(tabKey => {
                const tab = this.tabs[tabKey];
                if (!tab.rbac) return true;
                if (!event.__role[tab.rbac.role]) return false;
                if (tab.rbac.ext === undefined) return true;
                return event.__role.ext === tab.rbac.ext;
              })
              .map((tab, i) => {
                return (
                  <li key={i} className="nav-item">
                    <a
                      href={`#${tab}`}
                      className={`nav-link ${tab === current_tab ? 'active' : ''}`}
                      onClick={e => this.setState({ current_tab: tab })}
                    >
                      {this.tabs[tab].title}
                    </a>
                  </li>
                );
              })}
        </ul>
        <div className="py-3">
          <TabComp event={event} />
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadEvent();
    const { location } = this.props;
    let current_tab = 'detail';
    if (location.hash) {
      const hash = location.hash.substring(1);
      if (this.tabs[hash]) {
        current_tab = hash;
      }
    }
    this.setState({ current_tab });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.event && this.state.event.id && (!prevState.event || prevState.event.id !== this.state.event.id)) {
      setTitle(this.state.event.title);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadEvent();
    }
  }

  loadEvent = () => {
    const { match } = this.props;
    const { params } = match;
    Event.get(params.id)
      .then(event => {
        this.setState({
          event
        });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(ResponsibleEventMain);
