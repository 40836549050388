import EventUser from '../../lib/models/Event';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StarIcon } from '@primer/octicons-react';
import { Link, withRouter } from 'react-router-dom';
import { getQueryParameters } from '../../lib/utils/urlutils';
import { UserEvent } from './Confirmed';
import PastEventSearch from '../Common/PastEventSearch';
import { formatDate } from '../../lib/utils/dateutils';

class PastEvents extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    events: { rows: [] },
    showEvents: false
  };

  renderSingleEvent = event => {
    return (
      <div className="alert alert-info" role="alert">
        {event.is_responsible ? (
          <>
            <StarIcon className="mr-2" />
            <Link to={'/events/' + event.id}>{event.title}</Link>
          </>
        ) : (
          <span>{event.title}</span>
        )}
        <span className="float-right">{formatDate({ date: new Date(event.due_date), doubleDigit: true })}</span>
      </div>
    );
  };

  render() {
    const { events } = this.state;
    if (events.rows.length === 0) {
      return (
        <div className="container-fluid">
          <div className="alert alert-info" role="alert">
            <PastEventSearch />
            <hr />
            <div className="alert-heading">Nessun lavoro passato</div>
          </div>
          <div className="mb-0">
            {events.rows.map((item, i) => (
              <UserEvent key={i} item={item} />
            ))}
          </div>
        </div>
      );
    }
    return (
      <>
        <div className="container-fluid alert alert-info" role="alert">
          <PastEventSearch />
        </div>
        <div className="mb-0">
          {events.rows.map((item, i) => (
            <UserEvent key={i} item={item} />
          ))}
        </div>
      </>
    );
  }

  componentDidMount() {
    this.loadEvents();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadEvents();
    }
  }

  loadEvents = () => {
    const { location } = this.props;
    const { limit, offset, order_by, sort, q } = getQueryParameters(location);
    EventUser.findPast({ limit, offset, order_by, sort, search: q }).then(events => {
      this.setState({ events });
    });
  };
}

export default withRouter(PastEvents);
