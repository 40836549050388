import React, { useState } from 'react';
import DescriptionEdit from '../DescriptionEdit/DescriptionEdit';
import LTDropDown from '../LTDropDown/LTDropDown';
import LTInput from '../LTInput/LTInput';
import LTTextArea from '../LTTextArea/LTTextArea';
import { LinkIcon } from '@primer/octicons-react';
import PropTypes from 'prop-types';
import { handleOnKeyDown } from '../../../../lib/utils/commonutils';
import LTSwitch from '../LTSwitch/LTSwitch';
import LTCheckbox from '../LTCheckbox/LTCheckbox';
import LTInputDate from '../LTInputDate/LTInputDate';
import LTInputTime from '../LTInputTime/LTInputTime';

const renderElement = (el, props) => {
  switch (el) {
    case 'drop-down':
      return <LTDropDown {...props} />;
    case 'text':
    case 'number':
      return <LTInput {...props} />;
    case 'time':
      return <LTInputTime {...props} />;
    case 'date':
      return <LTInputDate {...props} />;
    case 'text-link':
      return <LTInput {...props} />;
    case 'text-area':
      return <LTTextArea {...props} />;
    case 'switch':
      return <LTSwitch {...props} />;
    case 'checkbox':
      return <LTCheckbox {...props} />;
    default:
      return null;
  }
};

export default function LTFormElement(props) {
  const { onBlur, canEdit, className, name, value, displayValue, type, link, onEnter, onEscape } = props;

  const [editing, setEditing] = useState(false);
  const handleKeyDown = e => {
    const blurInput = () => setEditing(false);
    const setInput = onEnter ? () => onEnter(name, value) : undefined;
    const resetInput = () => onEscape(name, value);
    handleOnKeyDown({ event: e, onEnter: setInput, onEscape: resetInput, sideEffect: blurInput });
  };

  const handleBlur = () => {
    onBlur && onBlur(name, value);
    setTimeout(() => {
      setEditing(false);
    }, 1);
  };

  const toggleEdit = e => {
    if (canEdit) {
      setEditing(true);
    }
  };

  const elementProps = {
    handleKeyDown: (...args) => handleKeyDown(...args),
    handleBlur: (...args) => handleBlur(...args),
    isFocused: true,
    ...props
  };

  return (
    <dd className={className ? className : 'col-12 col-md-8 col-lg-6'} onClick={toggleEdit}>
      {!editing && (
        <>
          <DescriptionEdit canEdit={canEdit}>{displayValue || value}</DescriptionEdit>
          <CustomDescription type={type} link={link} />
        </>
      )}
      {editing && renderElement(type, elementProps)}
    </dd>
  );
}

function CustomDescription({ type, link }) {
  if (type === 'text-link') {
    return (
      <a title="Open in new tab" href={link} target="_blank" rel="noopener noreferrer">
        <LinkIcon size="small" />
      </a>
    );
  }
  return null;
}

LTFormElement.prototype = {
  type: PropTypes.string
};
