import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingContent } from '../Common/Loading';
import { connect } from 'react-redux';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import ProfileAttachments from '../../lib/models/ProfileAttachments';
import { canCreatePrivateAttachment } from '../../lib/utils/permissionChecker';

class ProfileAttachmentForm extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    onToggleForm: PropTypes.func.isRequired,
    onAttachmentUploaded: PropTypes.func.isRequired
  };

  state = {
    uploading: false,
    is_private: false,
    description: '',
    passportPhoto: false
  };

  refCode = React.createRef();

  togglePassportPhoto = e => {
    if (e.target.checked) {
      this.setState({
        passportPhoto: true,
        description: 'fototessera',
        is_private: true
      });
    } else {
      this.setState({
        passportPhoto: false,
        description: '',
        is_private: false
      });
    }
  };
  render() {
    const { uploading, description, attachment, is_private, passportPhoto } = this.state;
    const { account } = this.props;
    const validated = attachment && description;
    const create_private = canCreatePrivateAttachment(account.data);

    return (
      <div
        className="modal show"
        style={{
          backgroundColor: 'rgba(0,0,0,.5)',
          display: 'block'
        }}
        onClick={this.props.onToggleForm}
      >
        <form onSubmit={this.handleSubmit}>
          <div className="modal-dialog modal-dialog-centered" role="document" onClick={e => e.stopPropagation()}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Aggiungi allegato</h5>
                <button type="button" className="close" aria-label="Close Modal" onClick={this.props.onToggleForm}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {uploading && <LoadingContent />}
                {!uploading && (
                  <div className="form-container">
                    <div className="form-group">
                      <label>File</label>
                      <div className="input-group mb-3">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="attachmentInputCode"
                            name="attachment"
                            autoComplete="off"
                            accept="image/*,application/pdf"
                            ref={this.refCode}
                            onChange={this.onFileChange}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="attachmentInputCode"
                            aria-describedby="inputGroupFileAddon02"
                          >
                            Scegli il file
                          </label>
                        </div>
                      </div>
                      {attachment && <div className="text-muted">{attachment.name}</div>}
                    </div>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="passportPhotoSwitch"
                        checked={passportPhoto}
                        onChange={this.togglePassportPhoto}
                      />
                      <label className="custom-control-label" htmlFor="passportPhotoSwitch">
                        Fototessera
                      </label>
                    </div>
                    {!passportPhoto && (
                      <div className="form-group">
                        <label>Descrizione</label>
                        <input
                          type="text"
                          className="form-control"
                          id="attachmentInputDescription"
                          name="description"
                          autoComplete="off"
                          value={description}
                          onChange={this.handleChange}
                        />
                      </div>
                    )}
                    {create_private && !passportPhoto && (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          name="is_private"
                          type="checkbox"
                          checked={is_private}
                          onChange={this.handleChange}
                          id="isPrivateCheck"
                        />
                        <label className="form-check-label" htmlFor="isPrivateCheck">
                          Riservato
                          <br />
                          <small>visibile solo dagli amministratori</small>
                        </label>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary" disabled={!validated}>
                  SALVA
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ entering: false });
    }, 250);
  }

  handleChange = e => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { attachment, description, is_private } = this.state;
    const payload = { name: attachment.name, mimetype: attachment.type, description, is_private };
    this.setState({ uploading: true }, () => {
      this.toBase64(attachment)
        .then(b64 => {
          payload.attachment = b64;
          return ProfileAttachments.create(this.props.profile.id, payload);
        })
        .then(() => {
          this.props.onAttachmentUploaded();
        })
        .catch(err => {
          this.setState({ uploading: false }, () => {
            ltToast('Impossibile salvare il file: ' + err.message, 5000, true);
          });
        });
    });
  };

  onFileChange = e => {
    // Update the state
    this.setState({ attachment: e.target.files[0] });
  };

  toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(ProfileAttachmentForm);
