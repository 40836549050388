import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import { setTitle } from '../../lib/utils/windowutils';
import LTFormInput from '../Common/LT-Form-Elements/LTFormInputs/LTFormInput';
import { TrashIcon } from '@primer/octicons-react';
import Qualification from '../../lib/models/admin/Qualification';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import LTFormSwitch from '../Common/LT-Form-Elements/LTFormInputs/LTFormSwitch';
import Breadcrumb from '../Breadcrumb';

const breadcrumbs = [
  { link: '/admin', text: 'Admin' },
  { link: '/admin/qualifications', text: 'Qualifiche personale' }
];

class QualificationView extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    qualification: false,
    error: false,
    breadcrumbs
  };

  refInput = React.createRef();

  render() {
    const { qualification, error, breadcrumbs } = this.state;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        {!error && !qualification && <Loading />}
        <Breadcrumb crumbs={breadcrumbs} />
        {qualification && (
          <div>
            <h5 className="mb-3">Modifica Qualifica</h5>
            <div className="row">
              <div className="mb-3 col-12 col-md-6 col-lg-4">
                <div className="card border-primary">
                  <div className="card-header bg-primary text-white">
                    <strong>Dettagli</strong>
                  </div>
                  <div className="card-body">
                    <dl className="row">
                      <dt className="col-12 col-md-4 col-lg-3">Codice</dt>
                      <dd className="col-12 col-md-8 col-lg-9">{qualification.code}</dd>
                      <dt className="col-12 col-md-4 col-lg-3">Descrizione</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'description'}
                        value={qualification.description}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Stato</dt>
                      <LTFormSwitch
                        canEdit
                        name="status"
                        className="col-12 col-md-8 col-lg-9"
                        displayValue={qualification.status === 1 ? 'Attivo' : 'Disattivato'}
                        value={!!qualification.status}
                        options={{ 0: 'Disattivo', 1: 'Attivo' }}
                        onChange={e => this.handleChange(e, true)}
                        onEscape={this.undoChanges}
                      />
                    </dl>
                  </div>
                </div>
              </div>
              <div className="mb-3 col-12 col-md-6 col-lg-4">
                <div className="card border-danger">
                  <div className="card-header bg-danger text-white">
                    <strong>ATTENZIONE</strong>
                  </div>
                  <div className="card-body">
                    <button className="btn btn-danger" onClick={this.handleDelete}>
                      <TrashIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.loadQualification();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.qualification && this.state.qualification.code && !prevState.qualification.code) {
      setTitle(this.state.qualification.code);
    }
    if (prevProps.match.params.code !== this.props.match.params.code) {
      this.loadQualification();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
  }

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Sei sicuro?');
    if (ok) {
      const { qualification } = this.state;
      Qualification.delete(qualification.code)
        .then(() => {
          this.props.history.push('/admin/qualifications');
        })
        .catch(e => {
          ltToast('Impossibile cancellare qualifica: ' + e.message, 5000, true);
        });
    }
  };

  handleChange = (e, saveAfter) => {
    let newQualification = { ...this.state.qualification };
    const { name, type, checked, value } = e.target;
    newQualification[name] = type === 'checkbox' ? checked : value;
    this.setState({ qualification: newQualification }, () => {
      if (saveAfter) {
        this.saveEdit(name, this.state.qualification[name]);
      }
    });
  };

  saveEdit = (field, value) => {
    const { oldQualification, qualification } = this.state;
    if (oldQualification[field] !== value) {
      Qualification.update(qualification.code, field, value)
        .then(qualification => {
          this.setState({ qualification, oldQualification: qualification }, () => {
            ltToast('Salvataggio effettuato', 2000);
          });
        })
        .catch(e => {
          ltToast('Impossibile salvare: ' + e.message, 5000, true);
        });
    }
  };

  undoChanges = (field, value) => {
    const { oldQualification } = this.state;
    if (oldQualification[field] !== value) {
      let resetedQualification = { ...this.state.oldQualification };
      this.setState({
        qualification: resetedQualification
      });
    }
  };

  loadQualification = () => {
    const { match } = this.props;
    const { params } = match;
    Qualification.get(params.code)
      .then(qualification => {
        const _breadcrumbs = [...breadcrumbs, { active: true, text: qualification.description }];
        this.setState({
          qualification,
          oldQualification: qualification,
          breadcrumbs: _breadcrumbs
        });
      })
      .catch(e => console.error('err', e.message));
  };
}

export default QualificationView;
