import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LTFormInput from '../Common/LT-Form-Elements/LTFormInputs/LTFormInput';
import LTFormDropDown from '../Common/LT-Form-Elements/LTFormInputs/LTFormDropDown';
import LTFormTextArea from '../Common/LT-Form-Elements/LTFormInputs/LTFormTextArea';
import { getProvinces } from '../../lib/utils/commonutils';
import Profiles from '../../lib/models/Profiles';
import { doGetUserMe } from '../../lib/models/account';
import { formatDate } from '../../lib/utils/dateutils';

class UserProfile extends PureComponent {
  static propTypes = {
    profile: PropTypes.object,
    qualification: PropTypes.object,
    dispatch: PropTypes.func
  };

  render() {
    const { profile } = this.props;

    return (
      <div className="col-md-6 mb-3">
        <div className="card border-primary">
          <div className="card-header bg-primary text-white">
            <strong>Profilo</strong>
          </div>
          <div className="card-body">
            <dl className="row">
              <dt className="col-12 col-md-4 col-lg-3">Nome</dt>
              <LTFormInput
                className="col-12 col-md-8 col-lg-9"
                canEdit={false}
                name={'name'}
                value={profile.name}
                onChange={this.handleChange}
                onEscape={this.undoChanges}
                onBlur={this.saveEdit}
                onEnter={this.saveEdit}
              />
              <dt className="col-12 col-md-4 col-lg-3">Cognome</dt>
              <LTFormInput
                className="col-12 col-md-8 col-lg-9"
                canEdit={false}
                name={'surname'}
                value={profile.surname}
                onChange={this.handleChange}
                onEscape={this.undoChanges}
                onBlur={this.saveEdit}
                onEnter={this.saveEdit}
              />
              <dt className="col-12 col-md-4 col-lg-3">Email</dt>
              <LTFormInput
                className="col-12 col-md-8 col-lg-9"
                canEdit={false}
                name={'email'}
                value={profile.email}
                onChange={this.handleChange}
                onEscape={this.undoChanges}
                onBlur={this.saveEdit}
                onEnter={this.saveEdit}
              />
              <dt className="col-12 col-md-4 col-lg-3">Telefono</dt>
              <LTFormInput
                className="col-12 col-md-8 col-lg-9"
                canEdit={false}
                name={'phone'}
                value={profile.phone}
                onChange={this.handleChange}
                onEscape={this.undoChanges}
                onBlur={this.saveEdit}
                onEnter={this.saveEdit}
              />
              <dt className="col-12 col-md-4 col-lg-3">Metodo Contatto</dt>
              <LTFormDropDown
                canEdit={false}
                name="contact_method"
                className="col-12 col-md-8 col-lg-9"
                displayValue={profile.contact_method ? (profile.contact_method === 'email' ? 'Email' : 'Whatsapp') : ''}
                value={profile.contact_method}
                options={{ email: 'email', phone: 'phone' }}
                onChange={this.handleChange}
                onEscape={this.undoChanges}
                onBlur={this.saveEdit}
              />
              <dt className="col-12 col-md-4 col-lg-3">Indirizzo</dt>
              <LTFormTextArea
                rows={4}
                className="col-12 col-md-8 col-lg-9"
                canEdit={false}
                name={'address'}
                value={profile.address}
                onChange={this.handleChange}
                onEscape={this.undoChanges}
                onBlur={this.saveEdit}
              />
              <dt className="col-12 col-md-4 col-lg-3">Città</dt>
              <LTFormTextArea
                rows={4}
                className="col-12 col-md-8 col-lg-9"
                canEdit={false}
                name={'city'}
                value={profile.city}
                onChange={this.handleChange}
                onEscape={this.undoChanges}
                onBlur={this.saveEdit}
              />
              <dt className="col-12 col-md-4 col-lg-3">Provincia</dt>
              <LTFormDropDown
                canEdit={false}
                name="province"
                className="col-12 col-md-8 col-lg-9"
                displayValue={profile.province}
                value={profile.province}
                options={getProvinces()}
                onChange={this.handleChange}
                onEscape={this.undoChanges}
                onBlur={this.saveEdit}
              />
              <dt className="col-12 col-md-4 col-lg-3">Codice Fiscale</dt>
              <LTFormInput
                className="col-12 col-md-8 col-lg-9"
                canEdit={false}
                name={'cf'}
                value={profile.cf}
                onChange={this.handleChange}
                onEscape={this.undoChanges}
                onBlur={this.saveEdit}
                onEnter={this.saveEdit}
              />
              <dt className="col-12 col-md-4 col-lg-3">ASC</dt>
              <LTFormInput
                className="col-12 col-md-8 col-lg-9"
                canEdit={false}
                name={'asc_code'}
                value={profile.asc_code}
                onChange={this.handleChange}
                onEscape={this.undoChanges}
                onBlur={this.saveEdit}
                onEnter={this.saveEdit}
              />
              <dt className="col-12 col-md-4 col-lg-3">ASC Data di Rilascio</dt>
              <LTFormInput
                type="date"
                className="col-12 col-md-8 col-lg-9"
                canEdit={false}
                name={'asc_release_date'}
                displayValue={
                  profile.asc_release_date
                    ? formatDate({ date: new Date(profile.asc_release_date), doubleDigit: true })
                    : ''
                }
                value={
                  profile.asc_release_date
                    ? profile.asc_release_date instanceof Date
                      ? profile.asc_release_date
                      : new Date(profile.asc_release_date)
                    : ''
                }
                onChange={e => this.onDateChange(e, 'asc_release_date')}
                onEscape={this.undoChanges}
                onBlur={this.saveEdit}
              />
              <dt className="col-12 col-md-4 col-lg-3">ASC Data di Rinnovo</dt>
              <LTFormInput
                type="date"
                className="col-12 col-md-8 col-lg-9"
                canEdit={false}
                name={'asc_renew_date'}
                displayValue={
                  profile.asc_renew_date
                    ? formatDate({ date: new Date(profile.asc_renew_date), doubleDigit: true })
                    : ''
                }
                value={
                  profile.asc_renew_date
                    ? profile.asc_renew_date instanceof Date
                      ? profile.asc_renew_date
                      : new Date(profile.asc_renew_date)
                    : ''
                }
                onChange={e => this.onDateChange(e, 'asc_renew_date')}
                onEscape={this.undoChanges}
                onBlur={this.saveEdit}
              />
            </dl>
            <div className="alert alert-light mt-3">
              Se i dati non sono corretti oppure devono essere aggiornati, comunicalo con tempestività al tuo referente{' '}
              {profile.partner_name}
            </div>
            <div className="mt-3">
              <div className="custom-control custom-switch">
                <input
                  value="1"
                  checked={profile.whatsapp_enable}
                  onChange={this.handleWhatsappEnable}
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitch1"
                />
                <label className="custom-control-label" htmlFor="customSwitch1">
                  {profile.whatsapp_enable ? 'Accetto contatto via WhatsApp' : 'Non accetto contatto via WhatsApp'}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleWhatsappEnable = e => {
    const { profile, dispatch } = this.props;
    if (profile.whatsapp_enable) {
      const proceed = window.confirm('Vuoi rimuovere la possibilità che Inlista ti contatti su Whatsapp?');
      if (!proceed) return;
    }
    Profiles.saveWhatsappEnable(e.target.checked)
      .then(() => {
        dispatch(doGetUserMe());
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(UserProfile);
