import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getData } from '../../lib/utils/httputils';

class CardLatest extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    enableAdd: PropTypes.bool
  };

  state = {
    entities: [],
    error: ''
  };

  render() {
    const { match, title, entity, enableAdd } = this.props;
    const { entities, error } = this.state;
    return (
      <div className="card border-primary">
        <div className="card-header bg-primary">
          <Link to={`${match.path}/${entity}`} className="text-white">
            <strong>{title}</strong>
          </Link>
        </div>
        <div className="card-body">
          {error && <div className="alert alert-danger">{error}</div>}
          {entities.length === 0 && <span>Nessun elemento trovato</span>}
          {enableAdd && (
            <div className="mb-3">
              <Link to={`${match.path}/${entity}/add`} className="btn btn-outline-primary">
                CREA NUOVO
              </Link>
            </div>
          )}
          {entities.length > 0 && (
            <div className="list-group">
              {entities.map((row, i) => {
                let description = row.description;
                if (row.name) {
                  description = row.name;
                }
                if (row.surname) {
                  description = `${row.surname} ${row.name}`;
                }
                return (
                  <Link
                    to={`${match.path}/${entity}/${row.id || row.code}`}
                    key={i}
                    className="list-group-item list-group-item-action"
                  >
                    {description}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    const { match, entity } = this.props;
    getData(`/api/v1${match.path}/${entity}/latest`)
      .then(data => {
        this.setState({ entities: data.rows });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  }
}
export default CardLatest;
