import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ChangePasswordForm from './ChangePasswordForm';
import { doGetUserMe } from '../../lib/models/account';

class PasswordUpdateRequest extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  state = {
    password_info: '',
    password_error: ''
  };

  render() {
    const { password_info, password_error } = this.state;
    const { account, dispatch } = this.props;
    return (
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-md-6 mb-3">
            <div className="card border-primary">
              <div className="card-header bg-primary text-white">
                <strong>Aggiorna la password</strong>
              </div>
              <div className="card-body">
                {password_info && (
                  <div className="alert alert-dismissible alert-primary my-3">
                    {password_info}
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                      onClick={e => {
                        this.setState({ password_info: '' });
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
                <fieldset className="border-primary border p-3">
                  {password_error && (
                    <div className="alert alert-dismissible alert-danger my-3">
                      {password_error}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={e => {
                          this.setState({ password_error: '' });
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  )}
                  <ChangePasswordForm
                    check_old_password={true}
                    account={account}
                    hideTitle={true}
                    onPasswordChanged={() => {
                      dispatch(doGetUserMe());
                    }}
                    onPasswordChangeFailed={password_error => {
                      this.setState({ password_error });
                    }}
                  />
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordUpdateRequest;
