export const EVENT_STATUSES = {
  created: 1,
  confirmed: 100,
  completed: 200,
  ongoing: 300,
  concluded: 400,
  reported: 500,
  canceled: 900
};

export const EVENT_STRINGS = {
  1: 'creato',
  100: 'confermato',
  // 200: 'completato',
  300: 'in corso',
  400: 'concluso',
  500: 'fatturato',
  900: 'annullato'
};

export const getEventStatusString = status => {
  return EVENT_STRINGS[status];
};
