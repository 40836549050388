import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Use `LTInputTime` to display input text.
 */
class LTInput extends PureComponent {
  refInput = React.createRef();
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    handleBlur: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    subText: PropTypes.string,
    isFocused: PropTypes.bool,
    type: PropTypes.string
  };

  componentDidMount() {
    this.props.isFocused && this.refInput && this.refInput.current.focus();
  }

  render() {
    const { name, value, placeholder, onChange, id, handleKeyDown, handleBlur, subText, type } = this.props;
    const _onChange =
      type === 'number'
        ? e => {
            if (isNaN(e.target.value)) {
              return;
            }
            onChange(e);
          }
        : onChange;
    return (
      <div>
        <input
          type="text"
          className="form-control form-control-sm"
          id={id}
          ref={this.refInput}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={_onChange}
          onKeyDown={handleKeyDown && handleKeyDown}
          onBlur={handleBlur && handleBlur}
        />
        {subText && <span className="col-form-label-sm text-muted">{subText}</span>}
      </div>
    );
  }
}

export default LTInput;
