import AdminEntity from './AdminEntity';
import { getData, putData } from '../../utils/httputils';

class Profile extends AdminEntity {
  static apiPath = '/api/v1/admin/profiles';

  static updateProfileQualifications(id, qualifications) {
    return putData(`${this.apiPath}/${id}/qualifications`, qualifications);
  }

  static updateAsc(id, payload) {
    return putData(`${this.apiPath}/${id}/asc`, payload);
  }

  static getJobs(id) {
    return getData(`${this.apiPath}/${id}/jobs`);
  }
}

export default Profile;
