import React, { PureComponent } from 'react';
import Profiles from '../lib/models/Profiles';
class WhatsAppRequest extends PureComponent {
  state = {
    error: false,
    whatsapp_enable: true
  };

  render() {
    const { whatsapp_enable, error } = this.state;
    return (
      <div className="container">
        {error && <div className="alert alert-danger mt-3">{error}</div>}
        <div className="alert alert-info mt-5">
          <p>
            Il sottoscritto letta{' '}
            <a href="/assets/PRY-Privacy.pdf" target="_blank" rel="noopener noreferrer">
              l'informativa
            </a>
            , pienamente edotto delle finalità e delle modalità stabilite dal Titolare del trattamento in merito
            all’utilizzo dei miei dati personali, per l’ottimizzazione della gestione del rapporto di lavoro autorizza
            il contatto anche attraverso il servizio di messaggistica <strong>WhatsApp</strong>
          </p>
          <div className="text-center">
            <div className="custom-control custom-switch">
              <input
                value="1"
                checked={whatsapp_enable}
                onChange={e => {
                  this.setState({ whatsapp_enable: e.target.checked });
                }}
                type="checkbox"
                className="custom-control-input"
                id="customSwitch1"
              />
              <label className="custom-control-label" htmlFor="customSwitch1">
                {whatsapp_enable ? 'Accetto' : 'Non accetto'}
              </label>
            </div>

            <button className="mt-3 btn btn-info" onClick={this.saveWhatsappEnable}>
              SALVA
            </button>
          </div>
        </div>
      </div>
    );
  }

  saveWhatsappEnable = () => {
    Profiles.saveWhatsappEnable(this.state.whatsapp_enable)
      .then(() => {
        window.location.href = '/';
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };
}

export default WhatsAppRequest;
