import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import Profile from '../../lib/models/admin/Profile';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import qs from 'qs';
import DatePicker from 'react-datepicker';
import Partner from '../../lib/models/admin/Partner';
import Qualification from '../../lib/models/admin/Qualification';
import { getProvinces } from '../../lib/utils/commonutils';
import Breadcrumb from '../Breadcrumb';
import { formatDate } from '../../lib/utils/dateutils';

class ProfileAdd extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    qualifications: { rows: [] },
    provinces: [],
    profile: false,
    error: false,
    validated: false,
    name: '',
    surname: '',
    partner: false,
    email: '',
    phone: '',
    address: '',
    city: '',
    province: '',
    cf: '',
    asc_code: '',
    asc_release_date: '',
    asc_renew_date: '',
    qualificationsChecked: [],
    status: 1
  };

  refInput = React.createRef();

  breadcrumbs = [
    { link: '/admin/', text: 'Admin' },
    { link: '/admin/profiles', text: 'Profili' },
    { active: true, text: 'Nuovo' }
  ];

  render() {
    const {
      validated,
      provinces,
      name,
      surname,
      email,
      phone,
      address,
      city,
      province,
      cf,
      asc_code,
      asc_release_date,
      asc_renew_date,
      qualifications,
      qualificationsChecked,
      status,
      error
    } = this.state;

    return (
      <div>
        <Breadcrumb crumbs={this.breadcrumbs} />
        {error && <div className="alert alert-danger">{error}</div>}
        <div>
          <div className="row">
            <div className="mb-3 col-12 col-md-8 col-lg-5">
              <div className="card border-primary">
                <div className="card-header bg-primary text-white">
                  <strong>Nuovo Profilo</strong>
                </div>
                <div className="card-body">
                  <form onSubmit={this.onSubmit}>
                    <div className="form-group row">
                      <label htmlFor="profileInputName" className="col-sm-3 col-form-label">
                        Nome
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          id="profileInputName"
                          name="name"
                          autoComplete="off"
                          value={name}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="profileInputSurname" className="col-sm-3 col-form-label">
                        Cognome
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          id="profileInputSurname"
                          name="surname"
                          autoComplete="off"
                          value={surname}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="profileInputEmail" className="col-sm-3 col-form-label">
                        Email
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="email"
                          id="profileInputEmail"
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="profileInputPhone" className="col-sm-3 col-form-label">
                        Telefono
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          id="profileInputPhone"
                          name="phone"
                          value={phone}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="profileInputAddress" className="col-sm-3 col-form-label">
                        Indirizzo
                      </label>
                      <div className="col-sm-9">
                        <textarea
                          rows={2}
                          className="form-control"
                          id="profileInputAddress"
                          name="address"
                          autoComplete="off"
                          value={address}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="profileInputCity" className="col-sm-3 col-form-label">
                        Città
                      </label>
                      <div className="col-sm-9">
                        <textarea
                          rows={2}
                          className="form-control"
                          id="profileInputCity"
                          name="city"
                          autoComplete="off"
                          value={city}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="profileInputProvince" className="col-sm-3 col-form-label">
                        Provincia
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          id="profileInputProvince"
                          name="province"
                          autoComplete="off"
                          value={province}
                          onChange={this.handleChange}
                        >
                          <option />
                          {provinces.map((province, i) => {
                            return (
                              <option key={i} value={province}>
                                {province}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="profileInputCF" className="col-sm-3 col-form-label">
                        Codice Fiscale
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          id="profileInputCF"
                          name="cf"
                          value={cf}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="profileInputAsc" className="col-sm-3 col-form-label">
                        ASC
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          id="profileInputAsc"
                          name="asc_code"
                          value={asc_code}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    {asc_code && (
                      <div className="form-group row">
                        <label htmlFor="ProfileInputASCDate" className="col-sm-3 col-form-label">
                          ASC Data d'Iscrizione/Rilascio
                        </label>
                        <div className="col-sm-9">
                          <DatePicker
                            id="ProfileInputASCDate"
                            name="asc_release_date"
                            type="date"
                            locale="it"
                            dateFormat="dd/MM/yyyy"
                            className="form-control mt-2"
                            selected={asc_release_date}
                            onChange={asc_release_date => this.onDateChange(asc_release_date, 'asc_release_date')}
                          />
                        </div>
                      </div>
                    )}
                    {asc_code && (
                      <div className="form-group row">
                        <label htmlFor="ProfileInputASCRenewDate" className="col-sm-3 col-form-label">
                          ASC Data Rinnovo
                        </label>
                        <div className="col-sm-9">
                          <DatePicker
                            id="ProfileInputASCRenewDate"
                            name="asc_renew_date"
                            type="date"
                            locale="it"
                            dateFormat="dd/MM/yyyy"
                            className="form-control mt-2"
                            selected={asc_renew_date}
                            onChange={asc_renew_date => this.onDateChange(asc_renew_date, 'asc_renew_date')}
                          />
                        </div>
                      </div>
                    )}

                    {qualifications.rows.length > 0 && (
                      <div className="form-group row">
                        <label htmlFor="profileInputQualification" className="col-sm-3 col-form-label">
                          Qualifications
                        </label>
                        <div className="col-sm-9">
                          {qualifications.rows.map((qualification, i) => {
                            return (
                              <div className="form-check" key={i}>
                                <input
                                  checked={qualificationsChecked.includes(qualification.code)}
                                  className="form-check-input"
                                  type="checkbox"
                                  id={qualification.code}
                                  name={qualification.code}
                                  onChange={this.handleChangeQualifications}
                                />
                                <label className="form-check-label" htmlFor={qualification.code}>
                                  {qualification.description}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div className="form-group row">
                      <label htmlFor="inputName" className="col-sm-3 col-form-label">
                        Stato
                      </label>
                      <div className="col-sm-9 pt-2">
                        <div className="custom-control custom-switch">
                          <input
                            checked={status}
                            type="checkbox"
                            className="custom-control-input"
                            name="status"
                            id="status"
                            onChange={this.handleChange}
                          />
                          <label className="custom-control-label" htmlFor="status">
                            {status ? 'Attivo' : 'Disattivo'}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary" disabled={!validated}>
                          SALVA
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    setTitle('Profilo');
    this.loadQualifications();
    this.loadProvinces();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { name, surname, cf, asc_code, asc_release_date } = this.state;
    let validated = false;
    if (name && surname && cf) {
      validated = true;
      if (asc_code) {
        if (!asc_release_date) {
          validated = false;
        }
      }
    }
    this.setState({ validated });
  }

  onDateChange = (e, name) => {
    if (name === 'asc_release_date') {
      this.setState({ asc_release_date: e });
    } else if (name === 'asc_renew_date') {
      this.setState({ asc_renew_date: e });
    }
  };

  handleChange = event => {
    const target = event.target;
    const value =
      target.type === 'checkbox' ? target.checked : target.name === 'code' ? target.value.toUpperCase() : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleChangeQualifications = event => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    let { qualificationsChecked } = this.state;
    const included = qualificationsChecked.includes(name);

    if (included) {
      qualificationsChecked.splice(qualificationsChecked.indexOf(name), 1);
    }
    if (value && !included) {
      qualificationsChecked.push(name);
    }

    this.setState({
      qualificationsChecked,
      [name]: value
    });
  };

  onSubmit = event => {
    event.preventDefault();
    const {
      validated,
      name,
      surname,
      partner_id,
      email,
      phone,
      address,
      city,
      province,
      cf,
      asc_code,
      asc_release_date,
      asc_renew_date,
      qualificationsChecked,
      status
    } = this.state;
    if (!validated) {
      return;
    }
    const formatted_asc_release_date = asc_release_date
      ? formatDate({ date: asc_release_date, format: '%Y-%M-%D', doubleDigit: true })
      : null;
    const formatted_asc_renew_date = asc_renew_date
      ? formatDate({ date: asc_renew_date, format: '%Y-%M-%D', doubleDigit: true })
      : null;
    const payload = {
      name,
      surname,
      partner_id,
      email,
      phone,
      address,
      city,
      province,
      cf,
      asc_code,
      asc_release_date: formatted_asc_release_date,
      asc_renew_date: formatted_asc_renew_date,
      qualifications: qualificationsChecked,
      status
    };

    Profile.create(payload)
      .then(data => {
        this.props.history.push(`./${data.id}`);
      })
      .catch(e => {
        ltToast('Fallita creazione profilo:' + e.message, false, true);
      });
    return false;
  };

  loadPartners = () => {
    const { search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    const { limit, offset, order_by, sort, q } = parameters;

    let searchString = '';
    if (q) {
      searchString = q;
    }
    if (this.state.searchString !== searchString) {
      this.setState({ searchString });
    }

    Partner.find({ limit, offset, order_by, sort, search: q }).then(partners => {
      this.setState({ partners });
    });
  };

  loadQualifications = () => {
    Qualification.find({ limit: 100 }).then(qualifications => {
      this.setState({ qualifications });
    });
  };

  loadProvinces = () => {
    const provinces = getProvinces();
    this.setState({ provinces });
  };
}

export default ProfileAdd;
