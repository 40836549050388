import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import EventUser from '../../lib/models/Event';
import { getEventStatusString } from '../../lib/models/Events';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import { ReplyIcon } from '@primer/octicons-react';
import AdminEventSearch from '../Common/AdminEventSearch';
import { Link } from 'react-router-dom';
import { getPaginationUrl, getQueryParameters } from '../../lib/utils/urlutils';
import { connect } from 'react-redux';
import { formatDate } from '../../lib/utils/dateutils';

class MasterEventList extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  state = {
    events: { rows: [] },
    error: ''
  };

  render() {
    const { events, error } = this.state;
    const { location } = this.props;
    const { offset } = getQueryParameters(location);

    return (
      <div>
        <AdminEventSearch intext="master" />
        <div className="my-lg-3 my-1">
          {error && <div className="alert alert-danger">{error}</div>}
          {events.rows.length > 0 && (
            <table className="table table-striped table-hover table-responsive-md">
              <thead>
                <tr>
                  <th>Evento</th>
                  <th>Codice</th>
                  <th>Società Responsabile</th>
                  <th>Cliente</th>
                  <th>Data</th>
                  <th>Stato</th>
                </tr>
              </thead>
              <tbody>
                {events.rows.map((row, i) => {
                  const date = formatDate({ date: new Date(row.due_date), doubleDigit: true });
                  return (
                    <tr key={i}>
                      <td>{row.title}</td>
                      <td>{row.order_code}</td>
                      <td>{row.partner_description}</td>
                      <td>{row.customer_description}</td>
                      <td>{date}</td>
                      <td>
                        {getEventStatusString(row.status)}
                        <button
                          title="Riporta indietro lo stato"
                          className="btn btn-outline-danger ml-3 "
                          onClick={e => this.revertEventStatus(e, row)}
                        >
                          <ReplyIcon size="small" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {events.rows.length === 0 && <div className="alert alert-info">Nessun evento trovato</div>}
        </div>
        <div className="d-flex bd-highlight mb-3">
          {(Number(offset) || events.hasMore) && (
            <div className="mt-3 ml-auto p-2 bd-highlight">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={'page-item' + (!Number(getQueryParameters(location).offset) ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'p')} className="page-link" onClick={e => e.target.blur()}>
                      Precedente
                    </Link>
                  </li>
                  <li className={'page-item' + (events.hasMore === false ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'n')} className="page-link" onClick={e => e.target.blur()}>
                      Successivo
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadEvents();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search || prevProps.intext !== this.props.intext) {
      this.loadEvents();
    }
  }

  loadEvents = () => {
    const { location } = this.props;
    const { limit, offset, order_by, sort, q } = getQueryParameters(location);

    EventUser.findReported({ limit, offset, order_by, sort, search: q }).then(events => {
      this.setState({ events });
    });
  };

  revertEventStatus = (e, row) => {
    e.preventDefault();
    const ok = window.confirm("Sei sicuro di voler riportare indietro lo stato dell'evento?");
    if (ok) {
      EventUser.update(row.id, 'status', 400)
        .then(() => this.loadEvents())
        .catch(e => {
          ltToast("Impossibile cambiare lo stato dell'evento: " + e.message, 5000, true);
        });
    }
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(MasterEventList);
