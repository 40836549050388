import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import EventTemplate from '../../lib/models/admin/EventTemplate';
import { setTitle } from '../../lib/utils/windowutils';
import UrlManglingInputSearch from '../Common/UrlManglingInputSearch';
import { getPaginationUrl, getQueryParameters } from '../../lib/utils/urlutils';
import Breadcrumb from '../Breadcrumb';

class EventTemplateComp extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    eventTemplates: { rows: [] }
  };

  breadcrumbs = [
    { link: '/admin/', text: 'Admin' },
    { active: true, text: 'Modelli evento' }
  ];

  render() {
    const { match, location } = this.props;
    const { eventTemplates } = this.state;
    const { offset } = getQueryParameters(location);

    return (
      <div>
        <Breadcrumb crumbs={this.breadcrumbs} />
        <div className="my-3 row">
          <div className="col-12 col-md-4 col-lg-3">
            <UrlManglingInputSearch />
          </div>
        </div>
        <div>
          {eventTemplates.rows.length > 0 && (
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Evento</th>
                  <th>Nome</th>
                  <th>Descrizione</th>
                </tr>
              </thead>
              <tbody>
                {eventTemplates.rows.map((eventTemplate, i) => {
                  return (
                    <tr key={i} className="justify-content-between align-items-center">
                      <td>
                        <Link to={`${match.path}/${eventTemplate.id}`}>{eventTemplate.id}</Link>
                      </td>
                      <td>
                        <Link to={`/events/${eventTemplate.event_id}`}>{eventTemplate.event_name}</Link>
                      </td>
                      <td>{eventTemplate.name}</td>
                      <td>{eventTemplate.description}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {eventTemplates.rows.length === 0 && (
            <div className="alert alert-info">Nessuna modello di evento trovato</div>
          )}
          {(Number(offset) || eventTemplates.hasMore) && (
            <div className="mt-3 ml-auto p-2 bd-highlight">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={'page-item' + (!Number(getQueryParameters(location).offset) ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'p')} className="page-link" onClick={e => e.target.blur()}>
                      Previous
                    </Link>
                  </li>
                  <li className={'page-item' + (eventTemplates.hasMore === false ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'n')} className="page-link" onClick={e => e.target.blur()}>
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTitle('Modelli evento');
    this.loadEventTemplates();
  }

  loadEventTemplates = () => {
    const { location } = this.props;
    const { limit, offset, order_by, sort, q } = getQueryParameters(location);

    EventTemplate.find({ limit, offset, order_by, sort, search: q }).then(eventTemplates => {
      this.setState({ eventTemplates });
    });
  };
}

export default EventTemplateComp;
