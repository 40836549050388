import React from 'react';
import { createRoot } from 'react-dom/client';
import configureStore from './store';
import './assets/css/prynet.scss';
import Plausible from 'plausible-tracker';
import App from './App';
import { setCurrentTheme } from './lib/utils/windowutils';
import { getData } from './lib/utils/httputils';

const initialState = {};
const store = configureStore(initialState);

setCurrentTheme();

const UnsupportedBrowser = function() {
  return (
    <div className="p-5 m-5">
      <div className="alert alert-warning">
        <h2>ATTENZIONE</h2>
        Il browser che stai usando sembra non supportare alcune funzionalità richieste da questa applicazione.
        <br />
        <br />
        <a href="https://browsehappy.com/">Prova ad aggiornarlo o a usarne uno differente</a>
      </div>
    </div>
  );
};

const initPlausible = function(config) {
  const { domain, apiHost } = config;
  if (!domain || !apiHost) return;
  window.plausible = Plausible(config);
  window.plausible.enableAutoPageviews();
};

const container = document.getElementById('root');
const root = createRoot(container);

if (!window.fetch || typeof window.fetch !== 'function') {
  root.render(<UnsupportedBrowser />);
} else {
  getData('/config.json')
    .then(config => {
      initPlausible(config);
    })
    .catch(e => {
      // console.error('bum', e.message);
    })
    .finally(() => {
      root.render(<App store={store} />);
    });
}
