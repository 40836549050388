import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TimePicker from '../../TimePicker';

/**
 * Use `LTInputTime` to display time picker
 */
class LTInputTime extends PureComponent {
  static propTypes = {
    showSave: PropTypes.bool,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    subText: PropTypes.string,
    isFocused: PropTypes.bool,
    saveClassName: PropTypes.string
  };

  render() {
    const { value, name, onChange, showSave, handleBlur, subText, saveClassName } = this.props;
    return (
      <>
        <TimePicker
          time={value}
          saveClassName={saveClassName}
          showSave={showSave}
          name={name}
          onConfirm={onChange}
          onSave={handleBlur}
        />
        {subText && <span className="col-form-label-sm text-muted">{subText}</span>}
      </>
    );
  }
}

export default LTInputTime;
