import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import { setTitle } from '../../lib/utils/windowutils';
import Accounts from '../../lib/models/admin/Account';
import { getRoleString, loadRoles } from '../../lib/models/Roles';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import { loadPartners } from '../../lib/models/Partners';
import { connect } from 'react-redux';
import Breadcrumb from '../Breadcrumb';

class AccountAdd extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    roles: [],
    partners: [],
    account: false,
    error: false,
    email: '',
    name: '',
    partner_id: 0,
    role: '',
    status: false
  };

  refInput = React.createRef();

  breadcrumbs = [
    { link: '/admin/', text: 'Admin' },
    { link: '/admin/accounts', text: 'Utenti' },
    { active: true, text: 'Nuovo' }
  ];

  render() {
    const { email, name, status, role, roles, error, partner_id, partners } = this.state;
    const user = this.props.account.data;
    if (!roles) return <Loading />;

    const validated = email && name && role;

    return (
      <div>
        <Breadcrumb crumbs={this.breadcrumbs} />
        {error && <div className="alert alert-danger">{error}</div>}
        <div>
          <div className="row">
            <div className="mb-3 col-12 col-md-6 col-lg-4">
              <div className="card border-primary">
                <div className="card-header bg-primary text-white">
                  <strong>Crea utente</strong>
                </div>
                <div className="card-body">
                  <form onSubmit={this.onSubmit}>
                    <div className="form-group row">
                      <label htmlFor="inputEmail" className="col-sm-2 col-form-label">
                        Email
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="email"
                          name="email"
                          value={email}
                          className="form-control"
                          id="inputEmail"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputName" className="col-sm-2 col-form-label">
                        Nome
                      </label>
                      <div className="col-sm-10">
                        <input
                          name="name"
                          type="text"
                          value={name}
                          className="form-control"
                          id="inputName"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    {user.master && (
                      <div className="form-group row">
                        <label htmlFor="inputName" className="col-sm-3 col-form-label">
                          Partner
                        </label>
                        <div className="col-sm-9">
                          <select
                            name="partner_id"
                            className="form-control"
                            onChange={this.handleChange}
                            value={partner_id}
                          >
                            <option />
                            {partners.map((partner, i) => {
                              return (
                                <option key={i} value={partner.id}>
                                  {partner.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    )}
                    <div className="form-group row">
                      <label htmlFor="inputName" className="col-sm-2 col-form-label">
                        Ruolo
                      </label>
                      <div className="col-sm-10">
                        <select name="role" className="form-control" onChange={this.handleChange} value={role}>
                          <option />
                          {Object.keys(roles).map((role, i) => {
                            return (
                              <option key={i} value={role}>
                                {roles[role]}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputName" className="col-sm-2 col-form-label">
                        Stato
                      </label>
                      <div className="col-sm-10 pt-2">
                        <div className="custom-control custom-switch">
                          <input
                            checked={status}
                            type="checkbox"
                            className="custom-control-input"
                            name="status"
                            id="status"
                            onChange={this.handleChange}
                          />
                          <label className="custom-control-label" htmlFor="status">
                            {status ? 'Attivo' : 'Disattivo'}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary" disabled={!validated}>
                          SALVA
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTitle('Crea utente');
    loadRoles().then(roles => {
      const user = this.props.account.data;

      const _roles = roles
        .filter(role => role.id >= user.role)
        .reduce((acc, role) => {
          acc[role.id] = getRoleString(role.name);
          return acc;
        }, {});

      this.setState({ roles: _roles });
    });
    loadPartners().then(partners => {
      this.setState({ partners });
    });
  }

  onSubmit = event => {
    event.preventDefault();
    const { email, name, role, status, partner_id } = this.state;
    if (!email || !name || !role) return;
    Accounts.create({ email, name, role, status, partner_id })
      .then(data => {
        this.props.history.push(`./${data.id}`);
      })
      .catch(e => {
        ltToast('Fallita creazione utente:' + e.message, false, true);
      });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(AccountAdd);
