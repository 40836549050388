import AdminEntity from './AdminEntity';
import { getData } from '../../utils/httputils';

class EventType extends AdminEntity {
  static apiPath = '/api/v1/admin/event-types';

  static find(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${this.apiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'code'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }
}

export default EventType;
