import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import CardLatest from '../CardLatest';
import { connect } from 'react-redux';
import Loading from '../Common/Loading';

class AdminMain extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    cards: []
  };

  master_cards = [
    { title: 'Partner', entity: 'partners', enableAdd: true },
    { title: 'Utenti', entity: 'accounts', enableAdd: true },
    { title: 'Qualifiche Personale', entity: 'qualifications', enableAdd: true },
    { title: 'Tipologie Evento', entity: 'event-types', enableAdd: true }
  ];

  admin_cards = [
    { title: 'Clienti', entity: 'customers', enableAdd: true },
    { title: 'Profili', entity: 'profiles', enableAdd: true },
    { title: 'Utenti', entity: 'accounts', enableAdd: true },
    { title: 'Modelli evento', entity: 'event-templates' }
  ];

  admin_cards_no_events = [
    { title: 'Profili', entity: 'profiles', enableAdd: true },
    { title: 'Utenti', entity: 'accounts', enableAdd: true }
  ];

  constructor(props) {
    super(props);
    setTitle('Admin');
  }

  render() {
    const { match, account } = this.props;
    if (!account) return <Loading />;
    const { cards } = this.state;

    return (
      <div className="mt-3">
        <div className="row">
          {cards.map((card, i) => (
            <div key={i} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
              <CardLatest match={match} title={card.title} entity={card.entity} enableAdd={card.enableAdd} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  componentDidMount() {
    let cards;
    const { account } = this.props;
    if (account.data.master) {
      cards = this.master_cards;
    } else {
      cards = account.data.create_event ? this.admin_cards : this.admin_cards_no_events;
    }
    this.setState({ cards });
  }
}

function mapStateToProps(state) {
  const { account, staff } = state;

  return {
    account,
    staff
  };
}

export default connect(mapStateToProps)(AdminMain);
