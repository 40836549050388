import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Breadcrumb extends PureComponent {
  static propTypes = {
    crumbs: PropTypes.array.isRequired
  };

  render() {
    const { crumbs } = this.props;
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {crumbs.map((crumb, i) => {
            const className = `breadcrumb-item${crumb.active ? ' active' : ''}`;
            return (
              <li key={i} className={className} aria-current={crumb.active ? 'page' : false}>
                {crumb.link ? <Link to={crumb.link}>{crumb.text}</Link> : crumb.text}
              </li>
            );
          })}
        </ol>
      </nav>
    );
  }
}

export default Breadcrumb;
