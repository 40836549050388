import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from './Common/Loading';
import { withRouter } from 'react-router-dom';
import { setTitle } from '../lib/utils/windowutils';

function checkUser(WrappedComponent) {
  return withRouter(
    connect(mapStateToProps)(
      class AccountComponent extends Component {
        static propTypes = {
          account: PropTypes.object.isRequired,
          history: PropTypes.object.isRequired
        };

        render() {
          const { account } = this.props;
          if (!account || !account.isLoaded) {
            return <Loading />;
          }
          if (!account.data) {
            return <div />;
          }
          return <WrappedComponent {...this.props} />;
        }

        componentDidMount() {
          if (this.props.account.isLoaded && !this.props.account.data) {
            localStorage.setItem('redirectURL', window.location.pathname);
            this.props.history.replace('/login');
          }
          setTitle('Home');
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
          if (!prevProps.account.isLoaded && this.props.account.isLoaded) {
            if (!this.props.account.data) {
              localStorage.setItem('redirectURL', window.location.pathname);
              this.props.history.replace('/login');
            }
          }
        }
      }
    )
  );
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default checkUser;
