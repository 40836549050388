import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Account from '../../lib/models/account';
import { CheckIcon, EyeIcon, EyeClosedIcon, XIcon } from '@primer/octicons-react';
import Errors from '../../lib/models/Errors';

const initialState = {
  show_old_password: false,
  show_password: false,
  show_password2: false,
  password: '',
  password_check: '',
  password_check_err: false,
  old_password: '',
  password_ok: false,
  hasLength: false,
  hasUpperCase: false,
  hasLowerCase: false,
  hasNumbers: false,
  hasNonalphas: false,
  running: false,
  error: false,
  errorSent: false
};

class ChangePasswordForm extends Component {
  static propTypes = {
    check_old_password: PropTypes.bool,
    token: PropTypes.string,
    onPasswordChanged: PropTypes.func.isRequired,
    onPasswordChangeFailed: PropTypes.func,
    hideTitle: PropTypes.bool,
    account: PropTypes.object.isRequired
  };

  state = initialState;
  oldPasswordInput = React.createRef();
  passwordInput = React.createRef();
  passwordInput2 = React.createRef();

  render() {
    const { hideTitle, check_old_password, account } = this.props;
    const {
      old_password,
      password,
      password_ok,
      password_check,
      password_check_err,
      show_old_password,
      show_password,
      show_password2,
      privacy,
      error,
      errorSent
    } = this.state;
    const passwordsMatch = password && password === password_check;

    if (error) {
      return (
        <div className="alert alert-dismissible alert-danger my-3">
          Si è verificato un errore imprevisto. {errorSent && 'Una mail di segnalazione è stata correttamente inviata.'}
        </div>
      );
    }

    return (
      <div className="change-password-wrapper">
        {!hideTitle && (
          <h2 className="form-changepassword-heading text-center">
            Per piacere {check_old_password ? 'cambia' : 'imposta'} la tua password
          </h2>
        )}
        <div className="row">
          <div className="col-12 col-sm-6 mb-3">
            <form className="" autoComplete="false" onSubmit={this.handleSubmit}>
              {check_old_password && (
                <div className="mb-3">
                  <label htmlFor="inputOldPassword">Vecchia Password</label>
                  <div className="input-group">
                    <input
                      type={show_old_password ? 'text' : 'password'}
                      tabIndex={1}
                      name="old_password"
                      value={old_password}
                      onChange={this.handleChangeCheck}
                      id="inputOldPassword"
                      className="form-control"
                      required="required"
                      ref={this.oldPasswordInput}
                    />
                    <div className="input-group-append">
                      <button
                        className="input-group-text text-primary"
                        type="button"
                        onClick={e =>
                          this.setState({ show_old_password: !show_old_password }, () => {
                            this.oldPasswordInput.current.focus();
                          })
                        }
                      >
                        {show_old_password ? <EyeClosedIcon /> : <EyeIcon />}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="mb-3">
                <label htmlFor="inputPassword">Imposta la tua password</label>
                <div className="input-group">
                  <input
                    type={show_password ? 'text' : 'password'}
                    name="password"
                    tabIndex={2}
                    value={password}
                    onChange={this.handleChangeCheck}
                    id="inputPassword"
                    className="form-control"
                    required
                    ref={this.passwordInput}
                  />
                  <div className="input-group-append">
                    <button
                      className="input-group-text text-primary"
                      type="button"
                      onClick={e =>
                        this.setState({ show_password: !show_password }, () => {
                          this.passwordInput.current.focus();
                        })
                      }
                    >
                      {show_password ? <EyeClosedIcon /> : <EyeIcon />}
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="inputPasswordCheck">Conferma la password</label>
                <div className="input-group">
                  <input
                    type={show_password2 ? 'text' : 'password'}
                    id="inputPasswordCheck"
                    tabIndex={3}
                    name="password_check"
                    className={`form-control ${password_check_err && 'is-invalid'}`}
                    required
                    ref={this.passwordInput2}
                    value={password_check}
                    onChange={this.handleChangeCheck}
                    onBlur={() => {
                      const { password, password_check } = this.state;
                      if (password_check !== password) {
                        this.setState({ password_check_err: true });
                      } else {
                        this.setState({ password_check_err: false });
                      }
                    }}
                    disabled={!password_ok}
                  />
                  <div className="input-group-append">
                    <button
                      disabled={!password_ok}
                      className={`input-group-text  ${password_check_err ? 'text-danger' : 'text-primary'}`}
                      type="button"
                      onClick={e =>
                        this.setState({ show_password2: !show_password2 }, () => {
                          this.passwordInput2.current.focus();
                        })
                      }
                    >
                      {show_password2 ? <EyeClosedIcon /> : <EyeIcon />}
                    </button>
                  </div>
                </div>
              </div>
              {!account?.data?.privacy_accepted_at && (
                <div className="mb-3">
                  <div className="row">
                    <div className="col-8">
                      <span>
                        Ho preso visione ed accetto{' '}
                        <a href="/assets/PRY-Privacy.pdf" target="_blank" rel="noopener noreferrer">
                          l'informativa sulla privacy.
                        </a>
                      </span>
                    </div>
                    <div className="col-4">
                      <div className="input-group">
                        <input
                          type="checkbox"
                          id="inputPrivacyCheck"
                          tabIndex={4}
                          name="privacy"
                          className="form-control checkbox"
                          required
                          checked={privacy}
                          onChange={this.handleChangeCheck}
                          disabled={!password_ok}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <button
                tabIndex={5}
                disabled={
                  password === '' ||
                  password !== password_check ||
                  this.state.running ||
                  (!account?.data?.privacy_accepted_at && !privacy)
                }
                className="btn btn-lg btn-primary btn-block"
                type="submit"
              >
                {this.state.running ? (
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  `${check_old_password ? 'Cambia' : 'Imposta'} la tua password`
                )}
              </button>
              {this.state.loginFailReason ? <div className="well">{this.state.loginFailReason}</div> : undefined}
            </form>
          </div>
          <div className="col-12 col-sm-6">
            <strong>Regole per le password</strong>
            <ul className="list-unstyled mt-3">
              <li className={this.state.hasLength ? 'text-success' : ''}>
                {this.state.hasLength && <CheckIcon size="small" />} 8 o più caratteri
              </li>
              <li className={this.state.hasUpperCase ? 'text-success' : ''}>
                {this.state.hasUpperCase && <CheckIcon size="small" />} 1 o più lettere maiuscole
              </li>
              <li className={this.state.hasLowerCase ? 'text-success' : ''}>
                {this.state.hasLowerCase && <CheckIcon size="small" />} 1 o più lettere minuscole
              </li>
              <li className={this.state.hasNumbers ? 'text-success' : ''}>
                {this.state.hasNumbers && <CheckIcon size="small" />} 1 o più numeri
              </li>
              <li className={this.state.hasNonalphas ? 'text-success' : ''}>
                {this.state.hasNonalphas && <CheckIcon size="small" />} 1 o più caratteri speciali (:!?%)
              </li>
            </ul>
            <strong>Verifica delle password</strong>
            <ul className="list-unstyled mt-3">
              <li className={passwordsMatch ? 'text-success' : password_check_err ? 'text-danger' : ''}>
                {passwordsMatch && <CheckIcon size="small" />}
                {password_check_err && <XIcon size="small" />} Le password coincidono
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (this.props.account?.data?.id) {
      Errors.sendErrorNotification('Entered ChangePasswordForm component', this.props.account.data.id)
        .then(r => console.log('Done'))
        .catch(err => console.error(err.message));
    }
    if (this.props.check_old_password) {
      this.oldPasswordInput.current.focus();
    } else {
      this.passwordInput.current.focus();
    }
  }

  handleChangeCheck = event => {
    try {
      this.handleChange(event);
    } catch (e) {
      this.setState({ error: e.message }, () => {
        Errors.sendErrorNotification('Password change failed to execute - handleChange level', e.stack)
          .then(res => this.setState({ errorSent: res }))
          .catch(err => console.error(err.message));
      });
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let newState = {
      [name]: value
    };
    if (name === 'password') {
      const passwordState = this.checkComplexity(value);
      newState = Object.assign(newState, passwordState);
    } else if (name === 'password_check') {
      const { password, password_check_err } = this.state;
      if (password_check_err && password === value) {
        newState.password_check_err = false;
      }
    }
    this.setState(newState);
  };

  checkComplexity(password) {
    const passwordState = {
      hasLength: password.length >= 8,
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumbers: /\d/.test(password),
      hasNonalphas: /[^0-9a-zA-Z]/.test(password)
    };
    passwordState.password_ok =
      passwordState.hasLength &&
      passwordState.hasUpperCase &&
      passwordState.hasLowerCase &&
      passwordState.hasNumbers &&
      passwordState.hasNonalphas;
    if (!passwordState.password_ok) {
      passwordState.password_check = '';
    }
    return passwordState;
  }

  handleSubmit = e => {
    e.preventDefault();
    const { old_password, password, password_check, privacy } = this.state;
    if (password !== password_check) {
      this.setState({ loginFailReason: 'Password mismatch' });
      return;
    }
    this.setState({ running: true }, () => {
      if (this.props.check_old_password) {
        this.onChangePassword(old_password, password, privacy);
      } else {
        this.onConfirmAccount(password, privacy);
      }
    });
  };

  onConfirmAccount = (password, privacy) => {
    Account.postConfirmToken(this.props.token, password, privacy)
      .then(json => {
        this.props.onPasswordChanged();
      })
      .catch(err => {
        this.passwordInput.current.focus();
        this.setState(initialState);
      });
  };

  onChangePassword = (old_password, password, privacy) => {
    Account.doPasswordChange(old_password, password, privacy)
      .then(data => {
        this.props.onPasswordChanged(data.message);
      })
      .catch(err => {
        this.oldPasswordInput.current.focus();
        if (this.props.onPasswordChangeFailed) {
          this.props.onPasswordChangeFailed(err.message);
        }
        this.setState(initialState);
      });
  };
}

export default ChangePasswordForm;
