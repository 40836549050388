import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import EventTemplate from '../../lib/models/admin/EventTemplate';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import { withRouter } from 'react-router-dom';

class EventTemplateAdd extends PureComponent {
  static propTypes = {
    event: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    name: '',
    description: '',
    validated: false
  };

  render() {
    const { description, name, validated } = this.state;
    return (
      <div className="card border-primary mb-5">
        <div className="card-header bg-primary text-white">
          <strong>Crea modello da questo evento</strong>
        </div>
        <div className="card-body">
          <form onSubmit={this.onSubmit}>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Nome modello:</label>
              <div className="col-sm-10 col-md-4">
                <input className="form-control" name={'name'} value={name} onChange={this.onChange} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Descrizione:</label>
              <div className="col-sm-10">
                <input className="form-control" name={'description'} value={description} onChange={this.onChange} />
              </div>
            </div>
            <div className="form-group row">
              <div className="offset-sm-2 col-sm-10">
                <button className="btn btn-primary" type="submit" disabled={!validated}>
                  Crea
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { name, description } = this.state;
    let validated = false;
    if (name && description) {
      validated = true;
    }
    this.setState({ validated });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { name, description, validated } = this.state;
    if (!validated) {
      return;
    }
    const data = {
      event_id: this.props.event.id,
      name,
      description
    };
    EventTemplate.create(data)
      .then(result => {
        const { history } = this.props;
        history.push(`/admin/event-templates/${result.id}`);
      })
      .catch(e => {
        ltToast('Fallita creazione lista:' + e.message, false, true);
      });
  };
}

export default withRouter(EventTemplateAdd);
