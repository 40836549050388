import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import { setTitle } from '../../lib/utils/windowutils';
import { Link } from 'react-router-dom';
import LTFormInput from '../Common/LT-Form-Elements/LTFormInputs/LTFormInput';
import LTFormDropDown from '../Common/LT-Form-Elements/LTFormInputs/LTFormDropDown';
import { TrashIcon } from '@primer/octicons-react';
import Profile from '../../lib/models/admin/Profile';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import LTFormTextArea from '../Common/LT-Form-Elements/LTFormInputs/LTFormTextArea';
import LTFormSwitch from '../Common/LT-Form-Elements/LTFormInputs/LTFormSwitch';
import LTFormCheckbox from '../Common/LT-Form-Elements/LTFormInputs/LTFormCheckbox';
import { CONTACT_METHODS, getProvinces } from '../../lib/utils/commonutils';
import Qualification from '../../lib/models/admin/Qualification';
import { formatDate } from '../../lib/utils/dateutils';
import ASCUpdate from './ASCUpdate';
import ProfileAttachments from '../../lib/models/ProfileAttachments';

class ProfileView extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    onSwitchTab: PropTypes.func
  };

  state = {
    profile: false,
    error: false,
    qualifications: { rows: [] },
    qualificationsChecked: false
  };

  refInput = React.createRef();

  render() {
    const { profile, error, qualifications } = this.state;
    let photoUrl = profile.photo_id ? ProfileAttachments.getAttachmentUrl(profile.id, profile.photo_id, true) : false;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        {!error && !profile && <Loading />}
        {profile && (
          <div>
            <div className="row">
              <div className="mb-3 col-12 col-md-8 col-lg-5">
                <div className="card border-primary">
                  <div className="card-header bg-primary text-white">
                    <strong>Dettagli</strong>
                  </div>
                  <div className="card-body">
                    {profile.account_id && profile.whatsapp_enable === null && (
                      <div className="alert alert-danger">Flag whatsapp non impostato</div>
                    )}
                    {profile.account_id && profile.whatsapp_enable === 0 && (
                      <div className="alert alert-warning">Flag whatsapp negato</div>
                    )}
                    <dl className="row">
                      <dt className="col-12 col-md-4 col-lg-3">Nome</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'name'}
                        value={profile.name}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Cognome</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'surname'}
                        value={profile.surname}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      {profile.account_id && (
                        <>
                          <dt className="col-12 col-md-4 col-lg-3">Metodo Contatto</dt>
                          <LTFormDropDown
                            canEdit
                            name="contact_method"
                            className="col-12 col-md-8 col-lg-9"
                            displayValue={profile.contact_method ? CONTACT_METHODS[profile.contact_method] : ''}
                            value={profile.contact_method}
                            options={profile.whatsapp_enable ? CONTACT_METHODS : { '': '', email: 'Email' }}
                            onChange={this.handleChange}
                            onEscape={this.undoChanges}
                            onBlur={this.saveEdit}
                          />
                        </>
                      )}
                      <dt className="col-12 col-md-4 col-lg-3">Email</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'email'}
                        value={profile.email}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Telefono</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'phone'}
                        value={profile.phone}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Indirizzo</dt>
                      <LTFormTextArea
                        rows={4}
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'address'}
                        value={profile.address}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Città</dt>
                      <LTFormTextArea
                        rows={4}
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'city'}
                        value={profile.city}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Provincia</dt>
                      <LTFormDropDown
                        canEdit
                        name="province"
                        className="col-12 col-md-8 col-lg-9"
                        displayValue={profile.province}
                        value={profile.province}
                        options={getProvinces()}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Codice Fiscale</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'cf'}
                        value={profile.cf}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Qualifiche</dt>
                      <LTFormCheckbox
                        canEdit={true}
                        displayValue={profile.qualifications.length > 0 ? profile.qualifications.join(', ') : 'Nessuna'}
                        className="col-12 col-md-8 col-lg-9"
                        options={qualifications.rows}
                        optionsChecked={profile.qualifications}
                        onChange={this.saveQualifications}
                        onEscape={this.undoChanges}
                        onBlur={() => true}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Stato</dt>
                      <LTFormSwitch
                        canEdit
                        name={'status'}
                        className="col-12 col-md-8 col-lg-9"
                        displayValue={profile.status === 1 ? 'Attivo' : 'Disattivo'}
                        value={!!profile.status}
                        options={{ 0: 'Disattivo', 1: 'Attivo' }}
                        onChange={e => this.handleChange(e, true)}
                        onEscape={this.undoChanges}
                      />
                    </dl>
                    {profile.account_id && (
                      <Link className="btn btn-outline-info" to={`/admin/accounts/${profile.account_id}`}>
                        Account
                      </Link>
                    )}
                    {!profile.account_id && profile.email && (
                      <button className="btn btn-outline-info" onClick={this.createAccount}>
                        Crea account
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-3 col-12 col-md-8 col-lg-4">
                <ASCUpdate history={this.props.history} profile={this.props.profile} />
              </div>
              <div className="mb-3 col-12 col-md-6 col-lg-3">
                <div className="card border-primary">
                  <div className="card-header bg-primary text-white">
                    <strong>Foto Tessera</strong>
                  </div>
                  <div className="card-body">
                    {photoUrl && <img style={{ width: 200 }} src={photoUrl} className="img-fluid" alt="Fototessera" />}
                    {!photoUrl && (
                      <div className="alert alert-info">
                        Foto tessera non presente. Per aggiungerla crea un allegato <strong>riservato</strong> con nome{' '}
                        <strong>"fototessera"</strong>
                        <div className="mt-3">
                          <a
                            className="btn btn-primary"
                            href="#attachments"
                            onClick={() => this.props.onSwitchTab('attachments')}
                          >
                            CREA
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {this.props.account.data.master && (
                <div className="mb-3 col-12 col-md-6 col-lg-4">
                  <div className="card border-danger">
                    <div className="card-header bg-danger text-white">
                      <strong>ATTENZIONE</strong>
                    </div>
                    <div className="card-body">
                      <button className="btn btn-danger" onClick={this.handleDelete}>
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    const { profile } = this.props;
    this.setState({ profile, oldProfile: profile });
    this.loadQualifications();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.profile && this.state.profile.id && !prevState.profile.id) {
      setTitle(this.state.profile.surname);
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
  }

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Sei sicuro?');
    if (ok) {
      const { profile } = this.state;
      Profile.delete(profile.id)
        .then(() => {
          this.props.history.push('/admin/profiles');
        })
        .catch(e => {
          ltToast('Impossibile cancellare il profilo: ' + e.message, 5000, true);
        });
    }
  };

  onDateChange = (e, name) => {
    let newProfile = { ...this.state.profile };
    newProfile[name] = e;
    this.setState({ profile: newProfile });
  };

  handleChange = (e, saveAfter) => {
    let newProfile = { ...this.state.profile };
    const { name, type, checked, value } = e.target;
    if (name === 'contact_method') {
      if (value === 'email' && !newProfile.email) {
        ltToast("Impostare prima l'indirizzo email");
        return;
      }
      if (value === 'phone' && !newProfile.phone) {
        ltToast('Impostare prima il numero di telefono');
        return;
      }
    }
    newProfile[name] = type === 'checkbox' ? checked : value;
    this.setState({ profile: newProfile }, () => {
      if (saveAfter) {
        this.saveEdit(name, this.state.profile[name]);
      }
    });
  };

  saveEdit = (field, value) => {
    const { oldProfile, profile } = this.state;
    if (oldProfile[field] !== value) {
      if (field === 'asc_release_date' || field === 'asc_renew_date') {
        value = formatDate({ date: value, format: '%Y-%M-%D', doubleDigit: true });
      }
      Profile.update(profile.id, field, value)
        .then(profile => {
          this.setState({ profile, oldProfile: profile }, () => {
            ltToast('Salvataggio effettuato', 2000);
          });
        })
        .catch(e => {
          this.setState({ profile: oldProfile }, () => {
            ltToast('Impossibile salvare: ' + e.message, 5000, true);
          });
        });
    }
  };

  saveQualifications = (qualifications, cb) => {
    const { profile } = this.state;
    let same = true;
    if (profile.qualifications.length !== qualifications.length) {
      same = false;
    } else {
      for (let i = 0; i < profile.qualifications.length; i++) {
        if (same === false) {
          break;
        }
        if (!qualifications.includes(profile.qualifications[i])) {
          same = false;
        }
      }
    }
    if (!same) {
      Profile.updateProfileQualifications(profile.id, qualifications)
        .then(newProfile => {
          this.setState({ profile: newProfile, oldProfile: newProfile });
          cb && cb();
        })
        .catch(e => {
          console.log('Impossibile salvare: ' + e.message, 5000, true);
        });
    } else {
      cb && cb();
    }
  };

  undoChanges = (field, value) => {
    const { oldProfile } = this.state;
    if (oldProfile[field] !== value) {
      let resetedProfile = { ...this.state.oldProfile };
      this.setState({
        profile: resetedProfile
      });
    }
  };

  loadQualifications = () => {
    Qualification.find({ limit: 100 }).then(qualifications => {
      this.setState({ qualifications });
    });
  };

  createAccount = () => {
    Profile.createProfileAccount(this.state.profile.id)
      .then(data => {
        this.props.history.push(`/admin/accounts/${data.account_id}`);
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };
}

export default ProfileView;
