import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import Loading from '../Common/Loading';
import { connect } from 'react-redux';
import EventListTab from './EventListTab';
import AddSmth from '../Common/AddSmth';

class EventListMain extends PureComponent {
  tabs = ['internal', 'external'];

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired
  };

  breadcrumbs = [
    { link: '/', text: 'Home' },
    { active: true, text: 'Event' }
  ];

  render() {
    const { account, history, location, match } = this.props;
    const current_tab = location.hash.substring(1);
    if (!current_tab || !account) return <Loading />;

    return (
      <>
        <div className="p-3">
          <ul className="nav nav-tabs">
            {!!account.data.create_event && (
              <>
                <li className="nav-item">
                  <a
                    href={`#internal`}
                    className={`nav-link ${'internal' === current_tab ? 'active' : ''}`}
                    onClick={e => this.switchTab(e, 'internal')}
                  >
                    Eventi
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href={`#external`}
                    className={`nav-link ${'external' === current_tab ? 'active' : ''}`}
                    onClick={e => this.switchTab(e, 'external')}
                  >
                    Eventi Esterni
                  </a>
                </li>
              </>
            )}
            {!account.data.create_event && (
              <li className="nav-item">
                <a href={`#${current_tab}`} className="nav-link active">
                  {account.data.create_event ? 'Eventi Esterni' : 'Eventi'}
                </a>
              </li>
            )}
          </ul>
        </div>

        <EventListTab account={account.data} history={history} location={location} intext={current_tab} />
        {!!account.data.create_event && <AddSmth match={match} />}
      </>
    );
  }

  componentDidMount() {
    const { location, account } = this.props;
    const { data } = account;
    let current_tab = data.create_event ? 'internal' : 'external';
    if (!location.hash) {
      this.redirect(current_tab);
    } else {
      const tab = location.hash.substring(1);
      if (!this.tabs.includes(tab)) {
        this.redirect(current_tab);
      }
    }
    // this.setState({ current_tab });
    setTitle('Eventi');
  }

  switchTab = (e, intext) => {
    e.preventDefault();
    this.redirect(intext);
    // this.setState({ current_tab: intext });
  };

  redirect = intext => {
    const { pathname } = this.props.location;
    this.props.history.push(pathname + '#' + intext);
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(EventListMain);
