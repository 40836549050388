import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AlertIcon, CheckCircleFillIcon } from '@primer/octicons-react';
import { connect } from 'react-redux';
import { getEventStatusString } from '../../lib/models/Events';
import { setTitle } from '../../lib/utils/windowutils';
import Event from '../../lib/models/admin/Event';
import EventUser from '../../lib/models/Event';
import { getPaginationUrl, getQueryParameters } from '../../lib/utils/urlutils';
import AdminEventSearch from '../Common/AdminEventSearch';
import { formatDate } from '../../lib/utils/dateutils';

class EventListTab extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    intext: PropTypes.string.isRequired
  };

  state = {
    events: { rows: [] },
    error: ''
  };

  render() {
    const { location, intext } = this.props;
    const { events, error } = this.state;
    const { offset } = getQueryParameters(location);

    return (
      <div>
        <AdminEventSearch intext={intext} />
        <div className="my-lg-3 my-1">
          {error && <div className="alert alert-danger">{error}</div>}
          {events.rows.length > 0 && (
            <table className="table table-striped table-hover table-responsive-md">
              <thead>
                <tr>
                  <th>Evento</th>
                  {intext === 'internal' && <th>Commessa</th>}
                  {intext === 'internal' && <th>Cliente</th>}
                  <th>Data</th>
                  <th>Luogo</th>
                  <th>Tipologia</th>
                  <th>Stato</th>
                  <th>Lista</th>
                </tr>
              </thead>
              <tbody>
                {events.rows.map((event, i) => {
                  const event_date = new Date(event.due_date);
                  const { column, backgroundClass } =
                    intext === 'internal' ? this.listStatus(event.list_status) : this.listStatus(event.block_status);
                  return (
                    <tr key={i} className={`justify-content-between align-items-center ${backgroundClass}`}>
                      <td>
                        <Link className="text-secondary" to={`/events/${event.id}`}>
                          {event.title}
                        </Link>
                      </td>
                      {intext === 'internal' && <td>{event.order_code}</td>}
                      {intext === 'internal' && <td>{event.customer_description}</td>}
                      <td>
                        {formatDate({
                          date: event_date,
                          doubleDigit: true
                        })}
                      </td>
                      <td>{event.address}</td>
                      <td>{event.event_type_description}</td>
                      <td>{getEventStatusString(event.status)}</td>
                      <td>{column}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {events.rows.length === 0 && <div className="alert alert-info">Nessun evento trovato</div>}
        </div>
        <div className="d-flex bd-highlight mb-3">
          {(Number(offset) || events.hasMore) && (
            <div className="mt-3 ml-auto p-2 bd-highlight">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={'page-item' + (!Number(getQueryParameters(location).offset) ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'p')} className="page-link" onClick={e => e.target.blur()}>
                      Precedente
                    </Link>
                  </li>
                  <li className={'page-item' + (events.hasMore === false ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'n')} className="page-link" onClick={e => e.target.blur()}>
                      Successivo
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    );
  }

  listStatus = list_status => {
    let statusString;
    let StatusIcon = AlertIcon;
    let backgroundClass;
    if (list_status > 0) {
      statusString = 'Confermati';
      StatusIcon = CheckCircleFillIcon;
      backgroundClass = 'bg-primary';
    } else {
      if (list_status < 0) {
        if (list_status === -1) {
          statusString = 'Non tutti compilati';
          backgroundClass = 'bg-warning';
        } else if (list_status === -2) {
          statusString = 'Lavoro rifiutato / Operatore rimosso';
          backgroundClass = 'bg-danger';
        }
      } else {
        statusString = 'Non tutti confermati';
        backgroundClass = 'bg-warning';
      }
    }

    return {
      column: (
        <span>
          <span className="mr-2">
            <StatusIcon />
          </span>
          {statusString}
        </span>
      ),
      backgroundClass
    };
  };

  componentDidMount() {
    setTitle('Eventi');
    this.loadEvents();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search || prevProps.intext !== this.props.intext) {
      this.loadEvents();
    }
  }

  loadEvents = () => {
    const { account, location, intext } = this.props;
    const { limit, offset, order_by, sort, q } = getQueryParameters(location);

    if (account.data.role < 4) {
      if (intext === 'internal') {
        Event.find({ limit, offset, order_by, sort, search: q })
          .then(events => {
            this.setState({ events, error: false });
          })
          .catch(e => {
            this.setState({ error: e.message, events: { rows: [] } });
          });
      } else {
        Event.findExt({ limit, offset, order_by, sort, search: q })
          .then(events => {
            this.setState({ events, error: false });
          })
          .catch(e => {
            this.setState({ error: e.message, events: { rows: [] } });
          });
      }
    } else {
      EventUser.find({ limit, offset, order_by, sort, search: q })
        .then(events => {
          this.setState({ events, error: false });
        })
        .catch(e => {
          this.setState({ error: e.message, events: { rows: [] } });
        });
    }
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(EventListTab);
