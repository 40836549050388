import React, { PureComponent } from 'react';
import { TrashIcon } from '@primer/octicons-react';
import { connect } from 'react-redux';
import Loading from '../Common/Loading';
import LTFormInput from '../Common/LT-Form-Elements/LTFormInputs/LTFormInput';
import { setTitle } from '../../lib/utils/windowutils';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import EventTemplate from '../../lib/models/admin/EventTemplate';
import Breadcrumb from '../Breadcrumb';

const breadcrumbs = [
  { link: '/admin', text: 'Admin' },
  { link: '/admin/event-templates', text: 'Template Eventi' }
];

class EventTemplateView extends PureComponent {
  state = {
    eventTemplate: false,
    error: false,
    breadcrumbs,
    content: ''
  };

  render() {
    const formRightClasses = 'col-12 col-md-8 col-lg-9';
    const formLeftClasses = 'col-12 col-md-4 col-lg-3';
    const { eventTemplate, error, breadcrumbs, content } = this.state;

    return (
      <div>
        <Breadcrumb crumbs={breadcrumbs} />
        {error && <div className="alert alert-danger">{error}</div>}
        {!error && !eventTemplate && <Loading />}
        {eventTemplate && (
          <div>
            <h5 className="mb-3">Modifica Modello Evento</h5>
            <div className="row">
              <div className="mb-3 col-12">
                <div className="card border-primary">
                  <div className="card-header bg-primary text-white">
                    <strong>Dettagli</strong>
                  </div>
                  <div className="card-body">
                    <dl className="row">
                      <dt className={formLeftClasses}>Id Evento</dt>
                      <dd className={formRightClasses}>{eventTemplate.event_id}</dd>
                      <dt className={formLeftClasses}>Nome</dt>
                      <LTFormInput
                        className={formRightClasses}
                        canEdit={true}
                        name={'name'}
                        value={eventTemplate.name}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className={formLeftClasses}>Descrizione</dt>
                      <LTFormInput
                        className={formRightClasses}
                        canEdit={true}
                        name={'description'}
                        value={eventTemplate.description}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className={formLeftClasses}>Template</dt>
                      <dd className={formRightClasses}>
                        <pre>{content}</pre>
                      </dd>
                    </dl>
                    <div className="mt-3">
                      <button className="btn btn-primary" onClick={this.handleTemplateApply}>
                        Crea nuovo evento
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 col-12 col-md-6 col-lg-4">
                <div className="card border-danger">
                  <div className="card-header bg-danger text-white">
                    <strong>DANGER ZONE</strong>
                  </div>
                  <div className="card-body">
                    <button className="btn btn-danger" onClick={this.handleDelete}>
                      <TrashIcon /> CANCELLA MODELLO
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    setTitle('Modello Evento');
    this.loadEventTemplate();
  }

  loadEventTemplate = () => {
    const { match } = this.props;
    const { params } = match;
    EventTemplate.get(params.id)
      .then(eventTemplate => {
        const _breadcrumbs = [...breadcrumbs, { active: true, text: eventTemplate.description }];
        this.setState({
          eventTemplate,
          oldEventTemplate: eventTemplate,
          breadcrumbs: _breadcrumbs,
          content: JSON.stringify(JSON.parse(eventTemplate.template), null, 3)
        });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };

  handleTemplateApply = () => {
    EventTemplate.apply(this.state.eventTemplate.id)
      .then(result => {
        this.props.history.push(`/events/${result.event_id}`);
      })
      .catch(e => {
        ltToast('Impossibile applicare questo modello: ' + e.message, 5000, true);
      });
  };

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Are you sure?');
    if (ok) {
      const { eventTemplate } = this.state;
      EventTemplate.delete(eventTemplate.id)
        .then(() => {
          this.props.history.push('/admin/event-templates');
        })
        .catch(e => {
          ltToast('Impossibile eliminare questo modello: ' + e.message, 5000, true);
        });
    }
  };

  handleChange = (e, saveAfter) => {
    const newEventTemplate = { ...this.state.eventTemplate };
    const { name, value } = e.target;
    newEventTemplate[name] = value;
    this.setState({ eventTemplate: newEventTemplate });
  };

  saveEdit = (field, value) => {
    const { oldEventTemplate, eventTemplate } = this.state;
    if (oldEventTemplate[field] !== value) {
      EventTemplate.update(eventTemplate.id, field, value)
        .then(eventTemplate => {
          this.setState({ eventTemplate, oldEventTemplate: eventTemplate });
        })
        .catch(e => {
          console.log('Impossibile salvare: ' + e.message, 5000, true);
        });
    }
  };

  undoChanges = (field, value) => {
    const { oldEventTemplate } = this.state;
    if (oldEventTemplate[field] !== value) {
      const resetedEventTemplate = { ...this.state.oldEventTemplate };
      this.setState({
        eventType: resetedEventTemplate
      });
    }
  };

  loadEventType = () => {
    const { match } = this.props;
    const { params } = match;
    EventTemplate.get(params.id)
      .then(eventTemplate => {
        this.setState({
          eventTemplate,
          oldEventTemplate: eventTemplate
        });
      })
      .catch(e => console.error('err', e.message));
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(EventTemplateView);
