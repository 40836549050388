import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Event from '../../lib/models/Event';
import ProfileAttachments from '../../lib/models/ProfileAttachments';

class StaffProfileModal extends PureComponent {
  static propTypes = {
    profileId: PropTypes.number.isRequired,
    eventId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired
  };

  state = {
    profile: false,
    error: false
  };

  render() {
    const { onClose } = this.props;
    const { error, profile } = this.state;

    return (
      <div
        className="modal show"
        style={{
          backgroundColor: 'rgba(0,0,0,.5)',
          display: 'block'
        }}
        onClick={onClose}
      >
        <div className="modal-dialog modal-dialog-centered" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Operatore</h5>
              <button type="button" className="close" aria-label="Close Modal" onClick={onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {error && <div className="alert alert-danger">{error}</div>}
              {!error && !profile && <span>Caricamento...</span>}
              {profile && (
                <div className="row">
                  <dl className="col-6">
                    <dt>Nome Cognome</dt>
                    <dd>
                      {profile.name} {profile.surname}
                    </dd>

                    <dt>Codice Fiscale</dt>
                    <dd>{profile.cf}</dd>

                    {profile.phone && (
                      <>
                        <dt>Telefono</dt>
                        <dd>{profile.phone}</dd>
                      </>
                    )}

                    {profile.asc_code && (
                      <>
                        <dt>ASC</dt>
                        <dd>{profile.asc_code}</dd>
                      </>
                    )}
                  </dl>

                  {profile?.photo_id && (
                    <div className="col-6">
                      <img
                        style={{ width: 200 }}
                        src={ProfileAttachments.getAttachmentUrl(profile.id, profile.photo_id, true)}
                        className="img-fluid"
                        alt="Fototessera"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadProfile();
  }

  loadProfile = () => {
    const { eventId, profileId } = this.props;
    Event.getProfileDetail(eventId, profileId)
      .then(profile => {
        this.setState({ profile });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };
}

export default StaffProfileModal;
