import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LTFormInput from '../Common/LT-Form-Elements/LTFormInputs/LTFormInput';
import ChangePasswordForm from '../Common/ChangePasswordForm';
import { getRoleString } from '../../lib/models/Roles';

class UserAccount extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired
  };

  state = {
    password_info: '',
    password_error: '',
    changingPwd: false
  };

  // TODO nome, email, ruolo, stato, partner, create event, master, change password

  render() {
    const { account } = this.props;
    const { changingPwd, password_error, password_info } = this.state;
    const { profile } = account.data;

    return (
      <div className="col-md-6 mb-3">
        <div className="card border-primary">
          <div className="card-header bg-primary text-white">
            <strong>Account</strong>
          </div>
          <div className="card-body">
            <dl className="row">
              {!profile && <dt className="col-12 col-md-4 col-lg-3">Nome</dt>}
              {!profile && (
                <LTFormInput
                  className="col-12 col-md-8 col-lg-9"
                  canEdit={true}
                  name={'name'}
                  value={account.data.name}
                  onChange={this.handleChange}
                  onEscape={this.undoChanges}
                  onBlur={this.saveEdit}
                  onEnter={this.saveEdit}
                />
              )}
              {!profile && <dt className="col-12 col-md-4 col-lg-3">Email</dt>}
              {!profile && <dd className="col-12 col-md-8 col-lg-9">{account.data.email}</dd>}
              <dt className="col-12 col-md-4 col-lg-3">Partner</dt>
              <dd className="col-12 col-md-8 col-lg-9">{account.data.partner_name}</dd>
              <dt className="col-12 col-md-4 col-lg-3">Ruolo</dt>
              <dd className="col-12 col-md-8 col-lg-9">{getRoleString(account.data.role_code)}</dd>
            </dl>
            {!changingPwd && !password_info && (
              <button className="btn btn-outline-primary" onClick={this.openChangePwd}>
                Modifica Password
              </button>
            )}
            {password_info && (
              <div className="alert alert-dismissible alert-primary my-3">
                {password_info}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={e => {
                    this.setState({ password_info: '' });
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}
            {changingPwd && (
              <fieldset className="border-primary border p-3">
                <div className="row">
                  <div className="col text-right">
                    <button
                      type="button"
                      className="close"
                      aria-label="Close Password form"
                      onClick={e => {
                        e.target.blur();
                        this.setState({ changingPwd: false });
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                </div>
                {password_error && (
                  <div className="alert alert-dismissible alert-danger my-3">
                    {password_error}
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                      onClick={e => {
                        this.setState({ password_error: '' });
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
                <ChangePasswordForm
                  check_old_password={true}
                  account={account}
                  hideTitle={true}
                  onPasswordChanged={this.onPasswordChanged}
                  onPasswordChangeFailed={password_error => {
                    this.setState({ password_error });
                  }}
                />
              </fieldset>
            )}
          </div>
        </div>
      </div>
    );
  }

  openChangePwd = e => {
    e.preventDefault();
    this.setState({ changingPwd: true });
  };

  onPasswordChanged = password_info => {
    this.setState({ password_info, changingPwd: false });
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(UserAccount);
