import { deleteData, getData, postData, putData } from '../../utils/httputils';

class StaffBlock {
  static get(event_id) {
    const url = `/api/v1/events/${event_id}/staff/blocks`;
    return getData(url);
  }

  static create(event_id, data) {
    const url = `/api/v1/events/${event_id}/staff/blocks`;
    return postData(url, data);
  }

  static update(event_id, block_id, data) {
    const url = `/api/v1/events/${event_id}/staff/blocks/${block_id}`;
    return putData(url, data);
  }

  static delete(event_id, block_id) {
    const url = `/api/v1/events/${event_id}/staff/blocks/${block_id}`;
    return deleteData(url);
  }

  static sendNotificationToAll(event_id, block_id) {
    const url = `/api/v1/events/${event_id}/staff/blocks/${block_id}/invites`;
    return putData(url);
  }
}

export default StaffBlock;
