import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import checkaccount from './AccountComponent';
import { setTitle } from '../lib/utils/windowutils';
import { Link, Route, Switch } from 'react-router-dom';
import AdminComp from './Admin';
import EventsRoot from './Events';
import WhatsAppRequest from './WhatsAppRequest';
import UserMain from './User/UserMain';
import PrivacyRequest from './PrivacyRequest';
import PasswordUpdateRequest from './Common/PasswordUpdateRequest';
import { checkExpiringPassword } from '../lib/utils/dateutils';

class MainComp extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  state = {
    password_info: ''
  };

  constructor(props) {
    super(props);
    setTitle('Home');
  }

  render() {
    const { account, dispatch } = this.props;
    const { password_info } = this.state;
    const { data } = account;
    if (data.operator || data.responsible) {
      if (data.privacy_accepted_at === null) {
        return <PrivacyRequest />;
      }
      if (data.profile.whatsapp_enable === null) {
        return <WhatsAppRequest />;
      }
    }
    if (data.must_change_pwd) {
      return <PasswordUpdateRequest account={account} dispatch={dispatch} />;
    }
    return (
      <>
        {password_info && (
          <div className="px-3">
            <div className="alert alert-dismissible alert-danger my-3">
              {password_info}{' '}
              <Link
                to="/account"
                onClick={e => {
                  this.setState({ password_info: '' });
                }}
              >
                Vai al cambio password.
              </Link>
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={e => {
                  this.setState({ password_info: '' });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        )}
        <Switch>
          {(data.master || data.admin) && <Route path="/admin" component={AdminComp} />}
          <Route path="/account" component={UserMain} />
          <Route path="/">
            <div className="p-lg-3">
              <EventsRoot account={data} />
            </div>
          </Route>
        </Switch>
      </>
    );
  }

  componentDidMount() {
    this.getPwdInfo();
  }

  getPwdInfo = () => {
    const { data } = this.props.account;
    const daysUntilExpires = checkExpiringPassword(data.password_expire_at);
    if (daysUntilExpires < 31) {
      const single_plur = daysUntilExpires === 1 ? 'giorno' : 'giorni';
      const password_info = `Attenzione! La tua password deve essere cambiata entro ${daysUntilExpires} ${single_plur}!`;
      this.setState({ password_info });
    }
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

const Main = connect(mapStateToProps)(checkaccount(MainComp));
export default Main;
