import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AlertIcon, CheckCircleFillIcon, CircleIcon, PaperAirplaneIcon, XCircleFillIcon } from '@primer/octicons-react';
import { STAFF_STATUSES, staffListStatusString } from '../StaffList/StaffListRecord';

class StatusIcon extends PureComponent {
  static propTypes = {
    status: PropTypes.number.isRequired
  };

  render() {
    return this.renderIcon(this.props.status);
  }

  renderIcon = status => {
    if (status === STAFF_STATUSES.confirmed) {
      return (
        <span style={{ color: '#2ECC71' }} title={staffListStatusString(status)}>
          <CheckCircleFillIcon size="small" />
        </span>
      );
    } else if (status === STAFF_STATUSES.rejected) {
      return (
        <span style={{ color: '#E74C3C' }} title={staffListStatusString(status)}>
          <XCircleFillIcon size="small" />
        </span>
      );
    } else if (status === STAFF_STATUSES.sent) {
      return (
        <span style={{ color: '#F4D03F' }} title={staffListStatusString(status)}>
          <AlertIcon size="small" />
        </span>
      );
    } else if (status === STAFF_STATUSES.compiled) {
      return (
        <span style={{ color: '#3498DB' }} title={staffListStatusString(status)}>
          <PaperAirplaneIcon size="small" />
        </span>
      );
    } else if (status === STAFF_STATUSES.created) {
      return <CircleIcon size="small" />;
    }
  };
}

export default StatusIcon;
