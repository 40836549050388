import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Event from '../../lib/models/Event.js';
import { staffListRequest } from '../../lib/models/stafflist/StaffListReducer';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import { ArrowBothIcon } from '@primer/octicons-react';

class StaffBlocksModal extends PureComponent {
  static propTypes = {
    staff: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    fromListId: PropTypes.number.isRequired,
    fromBlockId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    eventId: PropTypes.number.isRequired
  };

  state = {
    availableBlocks: null
  };

  render() {
    const { onClose, staff } = this.props;
    const { availableBlocks } = this.state;

    return (
      <div
        className="modal show"
        style={{
          backgroundColor: 'rgba(0,0,0,.5)',
          display: 'block'
        }}
        onClick={onClose}
      >
        <div className="modal-dialog modal-dialog-centered" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Scegli il Blocco</h5>
              <button type="button" className="close" aria-label="Close Modal" onClick={onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-striped table-hover text-center">
                <tbody>
                  <tr>
                    <th />
                    <th>Blocco</th>
                    <th>Slot</th>
                    <th>Partner</th>
                    <th>Ora Inizio</th>
                    <th>Ora Fine</th>
                  </tr>

                  {availableBlocks &&
                    availableBlocks.map((block, i) => {
                      const listrows = staff.list.filter(row => row.block_id === block.id);
                      const filledProfilesCount = listrows.filter(row => row.profile_id).length;
                      const startTime = block.start_time.substring(0, block.start_time.lastIndexOf(':'));
                      const endTime = block.end_time.substring(0, block.end_time.lastIndexOf(':'));

                      return (
                        <tr key={i}>
                          <td>
                            <button className="btn btn-sm btn-outline-info" onClick={() => this.doMoveRecord(block.id)}>
                              <ArrowBothIcon />
                            </button>
                          </td>
                          <td>{block.id}</td>
                          <td>
                            {filledProfilesCount}/{block.block_size}
                          </td>
                          <td>{block.partner_name}</td>
                          <td>{startTime}</td>
                          <td>{endTime}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.filterBlocks();
  }

  checkBlock = block => {
    const { staff, fromBlockId, fromListId } = this.props;
    const listrows = staff.list.filter(row => row.block_id === block.id);
    const filledProfilesCount = listrows.filter(row => row.profile_id).length;
    const singleRow = staff.list.filter(row => row.id === fromListId);
    return (
      block.id !== fromBlockId &&
      filledProfilesCount !== block.block_size &&
      block.partner_id === singleRow[0].partner_id
    );
  };

  filterBlocks = () => {
    const { blocks } = this.props.staff;
    let availableBlocks = blocks.filter(this.checkBlock);
    this.setState({ availableBlocks });
  };

  doMoveRecord = blockId => {
    const { eventId, fromListId, staff, onClose, dispatch } = this.props;
    const rowsByBlock = staff.list.filter(row => row.block_id === blockId);
    const arrayIds = rowsByBlock.filter(row => !row.profile_id);
    const toListId = arrayIds[0].id;
    Event.doMoveRecord(eventId, fromListId, toListId)
      .then(() => {
        setTimeout(() => {
          dispatch(staffListRequest(eventId))
            .then(onClose)
            .catch(e => {
              ltToast('Fallito spostamento staff: ' + e.message, false, true);
            });
        }, 200);
      })
      .catch(e => {
        ltToast('Fallito spostamento staff: ' + e.message, false, true);
      });
  };
}

function mapStateToProps(state) {
  const { staff } = state;

  return {
    staff
  };
}

export default connect(mapStateToProps)(StaffBlocksModal);
