import React, { Component } from 'react';

class Loading extends Component {
  render() {
    return (
      <div className="container mt-5 pt-5">
        <div className="row">
          <div className="col-md-4 offset-md-4 col-xs-6 offset-xs-3">
            <LoadingContent />
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;

export const LoadingContent = () => {
  return (
    <div>
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped active progress-bar-animated"
          role="progressbar"
          aria-valuenow="100"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: '100%' }}
        />
      </div>
      <div className="alert alert-info mt-3">Caricamento in corso...</div>
    </div>
  );
};
