import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import InputSearch from './InputSearch';
import { getHandleSearch, getSearchObject } from '../../lib/utils/searchUtils';
import DropdownSearch from './DropdownSearch';
import EventUser from '../../lib/models/Event';
import { getDropdownVariables } from '../../lib/utils/searchUtils';

class PastEventSearch extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  state = {
    eventsDetails: { rows: [] }
  };

  render() {
    const { history, match } = this.props;
    const { eventsDetails } = this.state;
    const searchObject = getSearchObject(this.props.location);
    if (eventsDetails.rows.length === 0) {
      return <div>Caricamento...</div>;
    }
    return (
      <>
        <div className="row my-2">
          <div className="col-12">
            <InputSearch
              initialValue={searchObject.q.main || ''}
              clearSearch={e => history.push(match.url)}
              doSearch={this.handleSearch}
            />
          </div>
        </div>
        <div className="row my-2">
          {/*
            <div className="col-12 col-lg-3">
            <DropdownSearch
              label={'Stato Evento'}
              doSearch={this.handleSearch}
              dropdownName="status"
              initialValue={searchObject.q.status ? searchObject.q.status : ''}
              dropdownList={{ 400: EVENT_STRINGS['400'], 500: EVENT_STRINGS['500'] }}
            />
          </div>
            */}

          <div className="col-12 col-lg-4">
            <DropdownSearch
              label={'Società Responsabile'}
              doSearch={this.handleSearch}
              dropdownName="partner_id"
              initialValue={searchObject.q.partner_id ? searchObject.q.partner_id : ''}
              dropdownList={getDropdownVariables('partners', eventsDetails)}
            />
          </div>
          <div className="col-12 col-lg-4">
            <DropdownSearch
              label={'Cliente'}
              doSearch={this.handleSearch}
              dropdownName="customer_id"
              initialValue={searchObject.q.customer_id ? searchObject.q.customer_id : ''}
              dropdownList={getDropdownVariables('customers', eventsDetails)}
            />
          </div>
          <div className="col-12 col-lg-4">
            <DropdownSearch
              label={'Responsabile Evento'}
              doSearch={this.handleSearch}
              dropdownName="responsible_account_id"
              initialValue={searchObject.q.responsible_account_id ? searchObject.q.responsible_account_id : ''}
              dropdownList={getDropdownVariables('responsibles', eventsDetails)}
            />
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    this.loadEventsDetails();
  }

  handleChange = event => {
    const target = event.target;
    const value =
      target.type === 'checkbox' ? target.checked : target.name === 'code' ? target.value.toUpperCase() : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleSearch = (searchInput, inputName) => {
    const { pathname } = this.props.location;
    const searchObject = getHandleSearch(searchInput, inputName, this.props.location);
    this.props.history.push(pathname + qs.stringify(searchObject, { addQueryPrefix: true }));
  };

  loadEventsDetails = () => {
    EventUser.getPastEventsDetails().then(eventsDetails => {
      this.setState({ eventsDetails });
    });
  };
}

export default withRouter(PastEventSearch);
