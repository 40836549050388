import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import UserAccount from './UserAccount';
import UserProfile from './UserProfile';

class UserView extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired
  };

  // TODO tirar fuori il profile con email = account.email creare route su backend

  render() {
    const { account } = this.props;
    const { profile } = account;

    return (
      <div className="mt-3">
        <div className="container-fluid">
          <div className="row mb-3">
            {profile && <UserProfile profile={profile} />}
            <UserAccount account={account} />
          </div>
        </div>
      </div>
    );
  }
}

export default UserView;
