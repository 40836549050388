import { getData } from '../utils/httputils';

let event_types;

export const loadEventTypes = async () => {
  if (!event_types) {
    const url = '/api/v1/admin/event-types';
    const data = await getData(url);
    event_types = data.rows;
  }
  return event_types;
};
