import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import Profile from '../../lib/models/admin/Profile';
import { getEventStatusString } from '../../lib/models/Events';
import { Link } from 'react-router-dom';
import { formatDate } from '../../lib/utils/dateutils';
import { formatJobDuration, sumJobDuration } from '../../lib/utils/commonutils';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import { LinkExternalIcon, StackIcon } from '@primer/octicons-react';

function JobRow({ job }) {
  return (
    <tr key={`staff-list-row-${job.id}`}>
      <td>
        {job.event_title}{' '}
        <Link to={`/events/${job.event_id}`}>
          <LinkExternalIcon />
        </Link>
      </td>
      <td>{job.partner_name}</td>
      <td>{getEventStatusString(job.event_status)}</td>
      <td>{formatDate({ date: new Date(job.event_date), doubleDigit: true })}</td>
      <td>{job.block_start_time ? job.block_start_time : '-'}</td>
      <td>{job.block_end_time ? job.block_end_time : '-'}</td>
      <td>{job.job_duration ? formatJobDuration(job.job_duration) : '-'}</td>
    </tr>
  );
}

function MonthEvents({ month }) {
  return (
    <table className="table table-sm table-borderless">
      <thead>
        <tr>
          <th>Evento</th>
          <th style={{ width: '12%' }}>Partner</th>
          <th style={{ width: '12%' }}>Stato</th>
          <th style={{ width: '12%' }}>Data</th>
          <th style={{ width: '12%' }}>Ora Inizio</th>
          <th style={{ width: '12%' }}>Ora Fine</th>
          <th style={{ width: '12%' }}>Ore di lavoro</th>
        </tr>
      </thead>
      <tbody>{month.events.map(event => event.jobs.map(job => <JobRow key={job.id} job={job} />))}</tbody>
    </table>
  );
}

class ProfileJobList extends PureComponent {
  static propTypes = {
    profile: PropTypes.object.isRequired
  };

  state = {
    openedEvents: new Set(),
    months: {},
    events: [],
    jobs: []
  };

  componentDidMount() {
    setTitle('Lavori');
    this.loadJobs();
  }

  loadJobs = () => {
    const { profile } = this.props;
    Profile.getJobs(profile.id)
      .then(res => {
        const { events, jobs } = res;
        const months = {};
        events.forEach(event => {
          const eventDate = new Date(event.due_date);
          const month = `${eventDate.getMonth() + 1}-${eventDate.getFullYear()}`;
          if (!months[month]) {
            months[month] = {
              events: [],
              job_duration: []
            };
          }
          event.jobs = jobs.filter(job => job.event_id === event.id);
          event.jobs.forEach(j => months[month].job_duration.push(j.job_duration));
          months[month].events.push(event);
        });
        this.setState({ months });
      })
      .catch(e => {
        ltToast('Impossibile caricare i lavori: ' + e.message, 5000, true);
      });
  };

  render() {
    const { months } = this.state;

    return (
      <div className="card border-primary">
        <div className="card-header bg-primary text-white">
          <strong>Lavori</strong>
        </div>
        <div className="card-body">
          <div style={{ width: '100%', overflow: 'auto' }}>
            {Object.keys(months).map(month => {
              return (
                <div key={month}>
                  <p style={{ fontSize: '1.1em' }}>
                    <strong>{month}</strong> Totale ore di lavoro {sumJobDuration(months[month].job_duration)}{' '}
                    <a
                      title="Stampa mese"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/api/v1/admin/profiles/${this.props.profile.id}/jobs/${month}`}
                      className="btn btn-primary"
                    >
                      <StackIcon size="small" />
                    </a>
                  </p>
                  <MonthEvents month={months[month]} />
                  <div className="my-4 border-bottom" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileJobList;
