import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  InfoIcon,
  PaperAirplaneIcon,
  PencilIcon,
  SearchIcon,
  MoveToBottomIcon,
  MoveToTopIcon
} from '@primer/octicons-react';
import StaffList from '../../lib/models/stafflist/StaffList';
import { staffListRequest } from '../../lib/models/stafflist/StaffListReducer';
import { akAlert, akConfirm } from '../Common/AlertConfirm';
import StatusIcon from '../Common/StatusIcon';
import { getFormattedStringDate } from '../../lib/utils/dateutils';
import Event from '../../lib/models/Event.js';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import { EVENT_STATUSES } from '../../lib/models/Events';

export const STAFF_STATUSES = {
  created: 0, // 'Da compilare',
  compiled: 10, // 'Da confermare',
  sent: 15, // 'Richiesta inviata'
  confirmed: 20, // 'Confermato',
  rejected: 30 // 'Rifiutato'
};

export const staffListStatusString = status => {
  const statusStrings = {
    [STAFF_STATUSES.created]: 'Da compilare',
    [STAFF_STATUSES.compiled]: 'Da notificare',
    [STAFF_STATUSES.sent]: 'Da confermare',
    [STAFF_STATUSES.confirmed]: 'Confermato',
    [STAFF_STATUSES.rejected]: 'Rifiutato'
  };

  return statusStrings[status];
};

class StaffListRecord extends PureComponent {
  static propTypes = {
    row: PropTypes.object.isRequired,
    blockOwner: PropTypes.bool.isRequired,
    eventStatus: PropTypes.number.isRequired,
    eventOwner: PropTypes.bool.isRequired,
    onEditListProfile: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    showMoveOptions: PropTypes.func.isRequired,
    showProfile: PropTypes.func,
    isAdmin: PropTypes.bool.isRequired
  };

  state = {
    showCheckIn: false,
    showCheckOut: false,
    manual: false
  };

  render() {
    const { row, onEditListProfile, eventStatus, canEdit, showProfile, blockOwner, eventOwner, isAdmin } = this.props;
    const { showCheckIn, showCheckOut } = this.state;
    const nameString = row.profile_id ? `${row.surname} ${row.name}` : '';
    const confirmed = row.status === STAFF_STATUSES.confirmed;
    const checkOngoing = eventStatus === EVENT_STATUSES.ongoing;
    const checkStarted = eventStatus >= EVENT_STATUSES.ongoing;
    const allowNotify = eventStatus < EVENT_STATUSES.concluded;
    const canceled = eventStatus === EVENT_STATUSES.canceled;
    return (
      <tr className="justify-content-between align-items-center">
        <td className="hide-on-mobile">
          <StatusIcon status={row.status} />
        </td>
        <td>
          {nameString && (
            <a
              href="/profile"
              onClick={e => {
                e.preventDefault();
                showProfile(row.id);
              }}
            >
              {nameString}
            </a>
          )}{' '}
          {(!nameString || row.status < STAFF_STATUSES.sent) && canEdit && isAdmin && blockOwner && (
            <button onClick={() => onEditListProfile(row)} className="btn btn-sm float-right">
              {nameString ? <PencilIcon /> : <SearchIcon />}
            </button>
          )}
        </td>
        <td>
          {row.check_in ? (
            <>
              <span className="text-success">
                {showCheckIn && row.check_in_by}
                {!showCheckIn && getFormattedStringDate(row.check_in)}
              </span>
              <button
                className="btn btn-sm btn-outline-success ml-1"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ showCheckIn: !showCheckIn });
                }}
              >
                <InfoIcon size={16} />
              </button>
            </>
          ) : confirmed && checkOngoing && (blockOwner || eventOwner) ? (
            <button className="btn btn-success" onClick={e => this.doCheckInOut('check-in')}>
              <MoveToBottomIcon />
              <span className="hide-on-mobile ml-2">CHECK-IN</span>
            </button>
          ) : (
            <span className="text-center">-</span>
          )}
        </td>
        <td>
          {row.check_out ? (
            <>
              <span className="text-danger">
                {showCheckOut && row.check_out_by}
                {!showCheckOut && getFormattedStringDate(row.check_out)}{' '}
              </span>
              <button
                className="btn btn-sm btn-outline-danger ml-1"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ showCheckOut: !showCheckOut });
                }}
              >
                <InfoIcon size={16} />
              </button>
            </>
          ) : checkStarted && confirmed && (blockOwner || eventOwner) && row.check_in ? (
            <button className="btn btn-danger" onClick={e => this.doCheckInOut('check-out')}>
              <MoveToTopIcon />
              <span className="hide-on-mobile ml-2">CHECK-OUT</span>
            </button>
          ) : (
            <span className="text-center">-</span>
          )}
        </td>

        <td className="hide-on-mobile text-right">
          {row.profile_id && !canceled && (
            <span>
              {row.contact_method && row.status === STAFF_STATUSES.compiled && allowNotify && (
                <button
                  className="ml-3 btn btn-sm btn-primary my-1 my-md-0"
                  title="Invia notifica"
                  onClick={e => this.setState({ manual: false }, () => this.markAsNotified())}
                >
                  <PaperAirplaneIcon />
                </button>
              )}
              {row.status === STAFF_STATUSES.compiled && isAdmin && blockOwner && (
                <button
                  className="ml-3 btn btn-sm btn-secondary my-1 my-md-0"
                  title="Notificato"
                  onClick={this.manualCheck}
                >
                  Disponibilità richiesta
                </button>
              )}
              {row.status === STAFF_STATUSES.sent && blockOwner && (
                <span>
                  <button
                    className="ml-3 btn btn-sm btn-success my-1 my-md-0"
                    title="Confermato"
                    onClick={this.markAsConfirmed}
                  >
                    Confermato
                  </button>
                  <button
                    className="ml-3 btn btn-sm btn-warning my-1 my-md-0"
                    title="Rifiutato"
                    onClick={this.markAsRejected}
                  >
                    Rifiutato
                  </button>
                </span>
              )}
              {canEdit &&
                isAdmin &&
                (blockOwner || (eventOwner && eventStatus === EVENT_STATUSES.concluded)) &&
                row.status > STAFF_STATUSES.created &&
                row.status <= STAFF_STATUSES.rejected && (
                  <button
                    className="ml-3 btn btn-sm btn-info my-1 my-md-0"
                    title="Sposta"
                    onClick={() => this.props.showMoveOptions(row.id, row.block_id)}
                  >
                    Sposta
                  </button>
                )}
              {isAdmin &&
                blockOwner &&
                row.status > STAFF_STATUSES.created &&
                row.status <= STAFF_STATUSES.rejected &&
                canEdit && (
                  <button className="ml-3 btn btn-sm btn-danger my-1 my-md-0" title="Rimuovi" onClick={this.doRemove}>
                    Rimuovi
                  </button>
                )}
            </span>
          )}
        </td>
      </tr>
    );
  }

  doRemove = () => {
    const { row } = this.props;
    const remove = () => {
      StaffList.update(row.event_id, row.id, {
        field: 'profile_id',
        value: null
      })

        .then(() => {
          setTimeout(() => {
            this.props.dispatch(staffListRequest(row.event_id));
          }, 200);
        })
        .catch(e => {
          ltToast('Impossibile rimuovere: ' + e.message, 5000, true);
        });
    };
    if (row.status !== STAFF_STATUSES.rejected) {
      akConfirm(
        'Conferma',
        'Sei sicuro di volere rimuovere dalla lista?',
        () => {
          remove();
        },
        () => {}
      );
    } else {
      remove();
    }
  };

  manualCheck = () => {
    this.setState({ manual: true }, () => this.markAsNotified());
  };

  markAsNotified = () => {
    this.updateStatus(STAFF_STATUSES.sent);
  };

  markAsConfirmed = () => {
    this.updateStatus(STAFF_STATUSES.confirmed);
  };

  markAsRejected = () => {
    this.updateStatus(STAFF_STATUSES.rejected);
  };

  updateStatus = status => {
    const { row } = this.props;
    const { manual } = this.state;
    StaffList.update(row.event_id, row.id, { field: 'status', value: status }, manual)
      .then(() => {
        setTimeout(() => {
          this.props.dispatch(staffListRequest(row.event_id));
        }, 200);
      })
      .catch(err => {
        akAlert('ATTENZIONE', err.message);
      });
  };

  doCheckInOut = type => {
    const { row } = this.props;
    const { id, event_id } = row;
    const checkInOut = type === 'check-in' ? 'check_in' : 'check_out';
    Event.doCheckInOut(event_id, id, checkInOut)
      .then(() => {
        setTimeout(() => {
          this.props.dispatch(staffListRequest(row.event_id));
        }, 200);
      })
      .catch(e => ltToast('Impossibile effettuare il check-in o il check-out: ' + e.message, 5000, true));
  };
}

export default StaffListRecord;
