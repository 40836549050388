import AdminEntity from './AdminEntity';
import { getData, postData } from '../../utils/httputils';

class EventTemplate extends AdminEntity {
  static apiPath = '/api/v1/admin/event-templates';

  static find(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${this.apiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
        'asc'}${searchQuery}`
    );
  }

  static apply(id) {
    return postData(`${this.apiPath}/${id}/apply`);
  }
}

export default EventTemplate;
