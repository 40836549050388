import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Route, Switch } from 'react-router-dom';
import EventTemplateMain from './EventTemplateMain';
import EventTemplateView from './EventTemplateView';

class EventTemplatesRoot extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    return (
      <div className="entity-wrapper mt-3">
        <Switch>
          <Route exact path={match.path} component={EventTemplateMain} />
          <Route exact path={`${match.path}/:id`} component={EventTemplateView} />
        </Switch>
      </div>
    );
  }
}

export default EventTemplatesRoot;
