import StaffList from './StaffList';
import StaffBlock from './StaffBlock';

const initialState = {
  event: null,
  isLoading: false,
  isFailed: false,
  list: [],
  blocks: []
};

export const STAFF_CHANGED = 'STAFF_REQUEST';
export const STAFF_LOADED = 'STAFF_LOADED';
export const STAFF_ERROR = 'STAFF_ERROR';

export default function staffListReducer(state = initialState, action) {
  switch (action.type) {
    case STAFF_CHANGED:
      if (state.event === action.data) return state;
      return Object.assign({}, state, { isLoading: true, list: [], blocks: [], event: action.data });
    case STAFF_LOADED:
      return Object.assign({}, state, { isLoading: false, list: action.data.list, blocks: action.data.blocks });
    case STAFF_ERROR:
      return Object.assign({}, state, { isLoading: false, list: [], blocks: [], isFailed: true });
    default:
      return state;
  }
}

export function staffListRequest(event_id) {
  return dispatch => {
    dispatch(staffListEventSelected(event_id));
    return StaffList.get(event_id)
      .then(listJson =>
        StaffBlock.get(event_id)
          .then(blockJson => dispatch(staffLoaded(listJson, blockJson)))
          .catch(() => dispatch(failedStaffLoading()))
      )
      .catch(() => dispatch(failedStaffLoading()));
  };
}

function staffListEventSelected(event_id) {
  return {
    type: STAFF_CHANGED,
    data: event_id
  };
}

function staffLoaded(listJson, blockJson) {
  return {
    type: STAFF_LOADED,
    data: { list: listJson, blocks: blockJson }
  };
}

function failedStaffLoading() {
  return {
    type: STAFF_ERROR
  };
}
