import { getData, postData } from '../utils/httputils';

export const loadPartners = async () => {
  const url = '/api/v1/partners';
  const data = await getData(url);
  return data.rows;
};

export const loadPartnerAccounts = async id => {
  const url = `/api/v1/partners/${id}/accounts`;
  const data = await getData(url);
  return data.rows;
};

export const getPartnerLogo = (partner_id, logo_uuid, embed) => {
  return `/api/v1/partners/${partner_id}/logo?l=${logo_uuid || ''}&e=${embed ? '1' : '0'}`;
};

export const savePartnerLogo = (partner_id, data) => {
  const url = `/api/v1/admin/partners/${partner_id}/logo`;
  return postData(url, data);
};
