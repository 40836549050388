import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import InputSearch from './InputSearch';
import { getQueryParameters } from '../../lib/utils/urlutils';

class UrlManglingInputSearch extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  render() {
    const { history, match } = this.props;
    const parameters = getQueryParameters(this.props.location);
    return (
      <InputSearch
        initialValue={parameters.q || ''}
        clearSearch={e => history.push(match.url)}
        doSearch={this.handleSearch}
      />
    );
  }

  handleSearch = searchString => {
    const { pathname, search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    parameters.q = searchString;
    parameters.offset = 0;
    this.props.history.push(pathname + qs.stringify(parameters, { addQueryPrefix: true }));
  };
}

export default withRouter(UrlManglingInputSearch);
