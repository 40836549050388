import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import { Route, Switch } from 'react-router-dom';
import AdminMain from './AdminMain';
import AccountRoot from '../Accounts';
import checkUser from '../AccountComponent';
import PartnerRoot from '../Partners';
import CustomerRoot from '../Customer';
import QualificationRoot from '../Qualification';
import EventTypeRoot from '../EventTypes';
import ProfileRoot from '../PersonProfile';
import EventTemplatesRoot from '../EventTemplates';

class AdminComp extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    setTitle('Admin');
  }

  render() {
    const { match, account } = this.props;
    if (!account.data.master && !account.data.admin) {
      return <div>Unauthorized</div>;
    }
    return (
      <div className="container-fluid main-wrapper">
        <Switch>
          <Route exact path={match.path} component={AdminMain} />
          <Route path={`${match.path}/accounts`} component={AccountRoot} />
          <Route path={`${match.path}/customers`} component={CustomerRoot} />
          <Route path={`${match.path}/qualifications`} component={QualificationRoot} />
          <Route path={`${match.path}/partners`} component={PartnerRoot} />
          <Route path={`${match.path}/event-types`} component={EventTypeRoot} />
          <Route path={`${match.path}/profiles`} component={ProfileRoot} />
          <Route path={`${match.path}/event-templates`} component={EventTemplatesRoot} />
        </Switch>
      </div>
    );
  }
}

export default checkUser(AdminComp);
