import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb';
import { setTitle } from '../../lib/utils/windowutils';
import Partner from '../../lib/models/admin/Partner';
import UrlManglingInputSearch from '../Common/UrlManglingInputSearch';
import { getPaginationUrl, getQueryParameters } from '../../lib/utils/urlutils';
import AddSmth from '../Common/AddSmth';

class PartnerComp extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    partners: { rows: [] }
  };

  breadcrumbs = [
    { link: '/admin/', text: 'Admin' },
    { active: true, text: 'Partner' }
  ];

  render() {
    const { match, location } = this.props;
    const { partners } = this.state;
    const { offset } = getQueryParameters(location);

    return (
      <div>
        <Breadcrumb crumbs={this.breadcrumbs} />
        <div className="my-3 row">
          <div className="col-12 col-md-4 col-lg-3">
            <UrlManglingInputSearch />
          </div>
        </div>
        <div>
          {partners.rows.length > 0 && (
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Codice</th>
                  <th>Ragione Sociale</th>
                  <th>Nome Contatto</th>
                  <th>Email Contatto</th>
                  <th>Telefono Contatto</th>
                  <th>Indirizzo Sede Operativa</th>
                  <th>Indirizzo Sede Legale</th>
                  <th>Partita IVA</th>
                  <th>Stato</th>
                </tr>
              </thead>
              <tbody>
                {partners.rows.map((partner, i) => {
                  return (
                    <tr key={i} className="justify-content-between align-items-center">
                      <td>
                        <Link to={`${match.path}/${partner.id}`}>{partner.name}</Link>
                      </td>
                      <td>{partner.description}</td>
                      <td>{partner.contact_name}</td>
                      <td>{partner.contact_email}</td>
                      <td>{partner.contact_phone}</td>
                      <td>{partner.address}</td>
                      <td>{partner.legal_address ? partner.legal_address : '-'}</td>
                      <td>{partner.piva ? partner.piva : '-'}</td>
                      <td>{partner.status === 1 ? 'Attivo' : partner.status === 9 ? 'Disattivato' : 'Inattivo'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {partners.rows.length === 0 && <div className="alert alert-info">Nessun partner trovato</div>}
        </div>
        <div className="d-flex bd-highlight mb-3">
          {(Number(offset) || partners.hasMore) && (
            <div className="mt-3 ml-auto p-2 bd-highlight">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={'page-item' + (!Number(getQueryParameters(location).offset) ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'p')} className="page-link" onClick={e => e.target.blur()}>
                      Precedente
                    </Link>
                  </li>
                  <li className={'page-item' + (partners.hasMore === false ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'n')} className="page-link" onClick={e => e.target.blur()}>
                      Successivo
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
        <AddSmth match={match} />
      </div>
    );
  }

  componentDidMount() {
    setTitle('Partner');
    this.loadPartners();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadPartners();
    }
  }

  loadPartners = () => {
    const { location } = this.props;
    const { limit, offset, order_by, sort, q } = getQueryParameters(location);

    Partner.find({ limit, offset, order_by, sort, search: q }).then(partners => {
      this.setState({ partners });
    });
  };
}

export default PartnerComp;
