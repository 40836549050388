import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Loading from '../Common/Loading';
import { setTitle } from '../../lib/utils/windowutils';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import Event from '../../lib/models/admin/Event';
import { loadCustomers } from '../../lib/models/Customers';
import { loadEventTypes } from '../../lib/models/EventTypes';
import Breadcrumb from '../Breadcrumb';

class EventAdd extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  state = {
    title: '',
    order_code: '',
    customer_id: '',
    event_type_code: '',
    customers: false,
    eventTypes: false
  };

  breadcrumbs = [
    { link: '/', text: 'Eventi' },
    { active: true, text: 'Nuovo' }
  ];

  render() {
    const { title, order_code, customer_id, event_type_code, customers, eventTypes, error } = this.state;

    if (!customers) return <Loading />;
    if (customers.length === 0)
      return (
        <div className="alert alert-danger">
          Nessun cliente presente
          <br />
          <Link to={'/admin/customers/add'}>Crea nuovo cliente</Link>
        </div>
      );
    if (eventTypes.length === 0) return <div className="alert alert-danger">Nessuna tipologia di evento presente</div>;

    const validated = title && order_code && customer_id && event_type_code;

    return (
      <div>
        <Breadcrumb crumbs={this.breadcrumbs} />
        {error && <div className="alert alert-danger">{error}</div>}
        <div>
          <div className="row">
            <div className="mb-4 col-12 col-md-8 col-lg-6">
              <div className="card border-primary">
                <div className="card-header bg-primary text-white">
                  <strong>Crea nuovo evento</strong>
                </div>
                <div className="card-body">
                  <form onSubmit={this.onSubmit}>
                    <div className="form-group row">
                      <label htmlFor="inputTitle" className="col-sm-6 col-lg-4 col-form-title">
                        Titolo
                      </label>
                      <div className="col-sm-6 col-lg-8">
                        <input
                          type="text"
                          name="title"
                          value={title}
                          className="form-control"
                          id="inputTitle"
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputCode" className="col-sm-6 col-lg-4 col-form-code">
                        Codice commessa
                      </label>
                      <div className="col-sm-6 col-lg-8">
                        <input
                          type="text"
                          name="order_code"
                          value={order_code}
                          className="form-control"
                          id="inputCode"
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputCustomer" className="col-sm-6 col-lg-4 col-form-customer">
                        Cliente
                      </label>
                      <div className="col-sm-6 col-lg-8">
                        <select
                          name="customer_id"
                          className="form-control"
                          onChange={this.handleChange}
                          value={customer_id}
                        >
                          <option />
                          {customers.map((customer, i) => {
                            return (
                              <option key={i} value={customer.id}>
                                {customer.description} ({customer.code})
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputCustomer" className="col-sm-6 col-lg-4 col-form-customer">
                        Tipologia
                      </label>
                      <div className="col-sm-6 col-lg-8">
                        <select
                          name="event_type_code"
                          className="form-control"
                          onChange={this.handleChange}
                          value={event_type_code}
                        >
                          <option />
                          {eventTypes.map((eventType, i) => {
                            return (
                              <option key={i} value={eventType.code}>
                                {eventType.description}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-6 col-lg-8">
                        <button type="submit" className="btn btn-primary" disabled={!validated}>
                          SALVA
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTitle('Crea nuovo evento');
    Promise.all([loadCustomers(), loadEventTypes()]).then(([customers, eventTypes]) => {
      this.setState({ customers, eventTypes });
    });
  }

  onSubmit = event => {
    event.preventDefault();
    const { title, order_code, customer_id, event_type_code } = this.state;
    if (!title || !order_code || !customer_id || !event_type_code) return;
    Event.create({ title, order_code, customer_id, event_type_code })
      .then(data => {
        this.props.history.push(`./${data.id}`);
      })
      .catch(e => {
        ltToast('Creazione evento fallita:' + e.message, false, true);
      });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };
}

export default EventAdd;
