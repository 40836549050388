import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import { setTitle } from '../../lib/utils/windowutils';
import LTFormInput from '../Common/LT-Form-Elements/LTFormInputs/LTFormInput';
import { TrashIcon } from '@primer/octicons-react';
import EventType from '../../lib/models/admin/EventType';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import LTFormSwitch from '../Common/LT-Form-Elements/LTFormInputs/LTFormSwitch';
import Breadcrumb from '../Breadcrumb';

const breadcrumbs = [
  { link: '/admin', text: 'Admin' },
  { link: '/admin/event-types', text: 'Tipologie Evento' }
];

class EventTypeView extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    eventType: false,
    error: false,
    breadcrumbs
  };

  refInput = React.createRef();

  render() {
    const { eventType, error, breadcrumbs } = this.state;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        {!error && !eventType && <Loading />}
        <Breadcrumb crumbs={breadcrumbs} />
        {eventType && (
          <div>
            <h5 className="mb-3">Modifica Tipologia Evento</h5>
            <div className="row">
              <div className="mb-3 col-12 col-md-6 col-lg-4">
                <div className="card border-primary">
                  <div className="card-header bg-primary text-white">
                    <strong>Dettagli</strong>
                  </div>
                  <div className="card-body">
                    <dl className="row">
                      <dt className="col-12 col-md-4 col-lg-3">Codice</dt>
                      <dd className="col-12 col-md-8 col-lg-9">{eventType.code}</dd>
                      <dt className="col-12 col-md-4 col-lg-3">Descrizione</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'description'}
                        value={eventType.description}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Stato</dt>
                      <LTFormSwitch
                        canEdit
                        name="status"
                        className="col-12 col-md-8 col-lg-9"
                        displayValue={eventType.status === 1 ? 'Attivo' : 'Disattivato'}
                        value={!!eventType.status}
                        options={{ 0: 'Disattivo', 1: 'Attivo' }}
                        onChange={e => this.handleChange(e, true)}
                        onEscape={this.undoChanges}
                      />
                    </dl>
                  </div>
                </div>
              </div>
              <div className="mb-3 col-12 col-md-6 col-lg-4">
                <div className="card border-danger">
                  <div className="card-header bg-danger text-white">
                    <strong>ATTENZIONE</strong>
                  </div>
                  <div className="card-body">
                    <button className="btn btn-danger" onClick={this.handleDelete}>
                      <TrashIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    setTitle('Tipologia Evento');
    this.loadEventType();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.eventType && this.state.eventType.code && !prevState.eventType.code) {
      setTitle(this.state.eventType.code);
    }
    if (prevProps.match.params.code !== this.props.match.params.code) {
      this.loadEventType();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
  }

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Sei sicuro?');
    if (ok) {
      const { eventType } = this.state;
      EventType.delete(eventType.code)
        .then(() => {
          this.props.history.push('/admin/event-types');
        })
        .catch(e => {
          ltToast('Impossibile cancellare la tipologia di evento: ' + e.message, 5000, true);
        });
    }
  };

  handleChange = (e, saveAfter) => {
    let newEventType = { ...this.state.eventType };
    const { name, type, checked, value } = e.target;
    newEventType[name] = type === 'checkbox' ? checked : value;
    this.setState({ eventType: newEventType }, () => {
      if (saveAfter) {
        this.saveEdit(name, this.state.eventType[name]);
      }
    });
  };

  saveEdit = (field, value) => {
    const { oldEventType, eventType } = this.state;
    if (oldEventType[field] !== value) {
      EventType.update(eventType.code, field, value)
        .then(eventType => {
          this.setState({ eventType, oldEventType: eventType }, () => {
            ltToast('Salvataggio effettuato', 2000);
          });
        })
        .catch(e => {
          ltToast('Impossibile salvare: ' + e.message, 5000, true);
        });
    }
  };

  undoChanges = (field, value) => {
    const { oldEventType } = this.state;
    if (oldEventType[field] !== value) {
      let resetedEventType = { ...this.state.oldEventType };
      this.setState({
        eventType: resetedEventType
      });
    }
  };

  loadEventType = () => {
    const { match } = this.props;
    const { params } = match;
    EventType.get(params.code)
      .then(eventType => {
        const _breadcrumbs = [...breadcrumbs, { active: true, text: eventType.description }];
        console.log('_breadcrumbs', _breadcrumbs);
        this.setState({
          eventType,
          oldEventType: eventType,
          breadcrumbs: _breadcrumbs
        });
      })
      .catch(e => console.error('err', e.message));
  };
}

export default EventTypeView;
