import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import QualificationMain from './QualificationMain';
import QualificationView from './QualificationView';
import { loadRoles } from '../../lib/models/Roles';
import QualificationAdd from './QualificationAdd';

class QualificationRoot extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  state = {
    roles: false
  };

  render() {
    const { match } = this.props;
    const { roles } = this.state;
    if (!roles) {
      return <div>Loading...</div>;
    }
    return (
      <div className="entity-wrapper mt-3">
        <Switch>
          <Route exact path={match.path} component={QualificationMain} />
          <Route exact path={`${match.path}/add`} component={QualificationAdd} />
          <Route exact path={`${match.path}/:code`} component={QualificationView} />
        </Switch>
      </div>
    );
  }

  componentDidMount() {
    loadRoles()
      .then(roles => this.setState({ roles }))
      .catch(() => {});
  }
}

export default QualificationRoot;
