import { getData } from '../utils/httputils';

let customers;

export const loadCustomers = async (force = false) => {
  if (!customers || force) {
    const url = '/api/v1/customers';
    customers = await getData(url);
  }
  return customers;
};
