import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Use `LTDropDown` to list and edit options.
 */

class LTDropDown extends PureComponent {
  static propTypes = {
    isFocused: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    handleBlur: PropTypes.func,
    handleKeyDown: PropTypes.func,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]),
    multiple: PropTypes.bool
  };

  componentDidMount() {
    if (typeof this.props.options === 'function') {
      this.props
        .options()
        .then(options => {
          this.setState({ options });
        })
        .catch(e => {
          this.setState({ error: e.message });
        });
    } else {
      this.props.isFocused && this.refSelect && this.refSelect.current.focus();
    }
  }

  refSelect = React.createRef();

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.options && this.state.options) {
      this.props.isFocused && this.refSelect && this.refSelect.current.focus();
    }
  }

  state = {
    options: false
  };

  render() {
    const { name, value, onChange, id, handleKeyDown, handleBlur, options, multiple } = this.props;
    const { error } = this.state;
    const _options = typeof options === 'function' ? this.state.options : options;
    if (!_options) {
      if (error) {
        return <div className="alert alert-danger">{error}</div>;
      }
      return <div>Attendi...</div>;
    }
    const validValue = value && _options[value];
    return (
      <>
        <select
          multiple={multiple}
          className="form-control form-control-sm"
          ref={this.refSelect}
          name={name}
          id={id}
          onChange={onChange}
          value={value}
          onKeyDown={e => handleKeyDown(e)}
          onBlur={() => handleBlur()}
        >
          {!validValue && <option value="" />}
          {Object.keys(_options).map(key => {
            const value = _options[key];
            return (
              <option key={key} value={name === 'province' ? value : key}>
                {value}
              </option>
            );
          })}
        </select>
      </>
    );
  }
}

export default LTDropDown;
