import { getData, putData } from '../utils/httputils';

export default class Profiles {
  static findAvailable(staffListId, params) {
    const apiPath = `/api/v1/profiles/available/${staffListId}`;
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    const url = `${apiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
      'desc'}${searchQuery}`;
    return getData(url);
  }

  static saveWhatsappEnable(whatsapp_enable) {
    return putData('/api/v1/profiles/whatsapp', { whatsapp_enable });
  }
}
