import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import EventTemplateBlock from '../EventTemplates/EventTemplateAdd';

class EventUtilityView extends PureComponent {
  static propTypes = {
    event: PropTypes.object.isRequired
  };

  render() {
    return <EventTemplateBlock event={this.props.event} />;
  }
}

export default EventUtilityView;
