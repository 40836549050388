import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SearchIcon, TrashIcon } from '@primer/octicons-react';
import { handleOnKeyDown } from '../../lib/utils/commonutils';

class InputSearch extends PureComponent {
  static propTypes = {
    initialValue: PropTypes.string.isRequired,
    doSearch: PropTypes.func.isRequired,
    clearSearch: PropTypes.func.isRequired
  };

  state = {
    text: ''
  };

  render() {
    const { doSearch } = this.props;
    const { text } = this.state;
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text" onClick={e => doSearch(text)}>
            <SearchIcon size="small" />
          </span>
        </div>
        <input
          type="text"
          value={text}
          onChange={e => this.setState({ text: e.target.value })}
          onKeyDown={e => handleOnKeyDown({ event: e, onEnter: e => doSearch(text) })}
          className="form-control form-control-sm"
          placeholder="Search"
        />
        <div className="input-group-append">
          <button className="input-group-text text-danger" onClick={this.onClearButtonClick}>
            <TrashIcon size="small" />
          </button>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const { initialValue } = this.props;
    this.setState({ text: initialValue });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialValue } = this.props;
    if (prevProps.initialValue !== initialValue) {
      this.setState({ text: initialValue });
    }
  }

  onClearButtonClick = () => {
    this.setState({ text: '' });
    this.props.clearSearch();
  };
}

export default InputSearch;
