import React, { PureComponent } from 'react';
import { setTitle } from '../../lib/utils/windowutils';
import ConfirmedEvents from '../UserEvents/Confirmed';
import OngoingEvents from '../UserEvents/Ongoing';
import PendingEvents from '../UserEvents/Pending';
import { Link } from 'react-router-dom';

class UserEventList extends PureComponent {
  render() {
    return (
      <div className="my-4 container-fluid">
        <OngoingEvents />
        <PendingEvents />
        <ConfirmedEvents />
        <div className="row mt-lg-4">
          <div className="col text-left">
            <Link className="btn btn-primary btn-lg" to={'/past'}>
              Eventi conclusi
            </Link>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTitle('Lavori');
  }
}

export default UserEventList;
