import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import { setTitle } from '../../lib/utils/windowutils';
import LTFormInput from '../Common/LT-Form-Elements/LTFormInputs/LTFormInput';
import LTFormDropDown from '../Common/LT-Form-Elements/LTFormInputs/LTFormDropDown';
import { TrashIcon } from '@primer/octicons-react';
import Customer from '../../lib/models/admin/Customer';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import LTFormTextArea from '../Common/LT-Form-Elements/LTFormInputs/LTFormTextArea';
import { connect } from 'react-redux';
import { loadCustomers } from '../../lib/models/Customers';
import Breadcrumb from '../Breadcrumb';
import { Link } from 'react-router-dom';

const breadcrumbs = [
  { link: '/admin', text: 'Admin' },
  { link: '/admin/customers', text: 'Clienti' }
];

class CustomerView extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired
  };

  state = {
    customer: false,
    error: false,
    breadcrumbs
  };

  refInput = React.createRef();

  render() {
    const { customer, error, breadcrumbs } = this.state;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        {!error && !customer && <Loading />}
        <Breadcrumb crumbs={breadcrumbs} />
        {customer && (
          <div>
            <h5 className="mb-3">Modifica Cliente</h5>
            <div className="row">
              <div className="mb-3 col-12 col-md-8 col-lg-5">
                <div className="card border-primary">
                  <div className="card-header bg-primary text-white">
                    <strong>Dettagli</strong>
                  </div>
                  <div className="card-body">
                    <dl className="row">
                      <dt className="col-12 col-md-4 col-lg-3">Codice</dt>
                      <dd className="col-12 col-md-8 col-lg-9">{customer.code}</dd>
                      <dt className="col-12 col-md-4 col-lg-3">Ragione Sociale</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'description'}
                        value={customer.description}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Email</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'email'}
                        value={customer.email}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Contatto</dt>
                      <LTFormInput
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'contact'}
                        value={customer.contact}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                        onEnter={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Indirizzo</dt>
                      <LTFormTextArea
                        rows={4}
                        className="col-12 col-md-8 col-lg-9"
                        canEdit={true}
                        name={'address'}
                        value={customer.address}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                      />
                      <dt className="col-12 col-md-4 col-lg-3">Stato</dt>
                      <LTFormDropDown
                        canEdit
                        name="status"
                        className="col-12 col-md-8 col-lg-9"
                        displayValue={customer.status === 1 ? 'Attivo' : 'Disattivato'}
                        value={customer.status}
                        options={{ 0: 'Disattivo', 1: 'Attivo' }}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                      />
                    </dl>
                    <div className="mt-2">
                      <Link to={`/?q=customer_id:${customer.id}#internal`} className="btn btn-outline-secondary">
                        Lavori
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {this.props.account.data.master && (
                <div className="mb-3 col-12 col-md-6 col-lg-4">
                  <div className="card border-danger">
                    <div className="card-header bg-danger text-white">
                      <strong>DANGER ZONE</strong>
                    </div>
                    <div className="card-body">
                      <button className="btn btn-danger" onClick={this.handleDelete}>
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.loadCustomer();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.customer && this.state.customer.id && !prevState.customer.id) {
      setTitle(this.state.customer.code);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadCustomer();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
  }

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Sei sicuro?');
    if (ok) {
      const { customer } = this.state;
      Customer.delete(customer.id)
        .then(() => {
          return loadCustomers(true);
        })
        .then(() => {
          this.props.history.push('/admin/customers');
        })
        .catch(e => {
          ltToast('Impossibile cancellare il cliente: ' + e.message, 5000, true);
        });
    }
  };

  handleChange = e => {
    e.preventDefault();
    let newCustomer = { ...this.state.customer };
    newCustomer[e.target.name] = e.target.value;
    this.setState({ customer: newCustomer });
  };

  saveEdit = (field, value) => {
    const { oldCustomer, customer } = this.state;
    if (oldCustomer[field] !== value) {
      Customer.update(customer.id, field, value)
        .then(customer => {
          this.setState({ customer, oldCustomer: customer });
          return loadCustomers(true);
        })
        .catch(e => {
          console.log('Impossibile salvare: ' + e.message, 5000, true);
        });
    }
  };

  undoChanges = (field, value) => {
    const { oldCustomer } = this.state;
    if (oldCustomer[field] !== value) {
      let resetedCustomer = { ...this.state.oldCustomer };
      this.setState({
        customer: resetedCustomer
      });
    }
  };

  loadCustomer = () => {
    const { match } = this.props;
    const { params } = match;
    Customer.get(params.id)
      .then(customer => {
        const _breadcrumbs = [...breadcrumbs, { active: true, text: customer.description }];
        this.setState({
          customer,
          oldCustomer: customer,
          breadcrumbs: _breadcrumbs
        });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(CustomerView);
