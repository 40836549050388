import { getData, postData, deleteData, putData } from '../../utils/httputils';

class Account {
  static apiPath = '/api/v1/accounts';

  static list() {
    return getData(Account.apiPath);
  }

  static getMe() {
    return getData(Account.apiPath + '/me');
  }

  static doLogout() {
    return deleteData(Account.apiPath + '/login');
  }

  static doLogin(data) {
    return postData(Account.apiPath + '/login', data).then(json => {
      return json.sessionId;
    });
  }

  static doLostPassword(email) {
    return postData(Account.apiPath + '/lost-password', { email });
  }

  static getConfirmToken(token) {
    return postData(Account.apiPath + '/confirm', { token });
  }

  static checkConfirmToken(token) {
    return getData(Account.apiPath + '/confirm?token=' + token);
  }

  static doPasswordChange(old_password, password, privacy) {
    return postData(Account.apiPath + '/password', { old_password, password, privacy });
  }

  static postConfirmToken(token, password, privacy) {
    return postData(Account.apiPath + '/confirm/' + token, { password, privacy });
  }

  static getJob(job_id) {
    return getData(`${Account.apiPath}/job/${job_id}`);
  }

  static updateJob(event_id, job_id, status) {
    return putData(`${Account.apiPath}/job/${job_id}`, { event_id, status });
  }

  static doCheckInOut(job_id, check_in_out) {
    return postData(
      `${Account.apiPath}/job/${job_id}/${check_in_out}`,
      check_in_out === 'check_in' ? { check_in: true } : { check_in: false }
    );
  }

  static doPrivacyAccept() {
    return putData('/api/v1/accounts/privacy', { privacy_accepted: true });
  }
}

export default Account;
