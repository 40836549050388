import AdminEntity from './AdminEntity';
import { getData } from '../../utils/httputils';

class Event extends AdminEntity {
  static apiPath = '/api/v1/admin/events';

  static getAdminEventsDetails = intext => {
    return getData(`${this.apiPath}/search_filters/${intext}`);
  };
}

export default Event;
