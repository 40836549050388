import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TriangleDownIcon, TriangleUpIcon } from '@primer/octicons-react';

class OneOrMoreAlert extends PureComponent {
  static propTypes = {
    elements: PropTypes.array.isRequired,
    zeroMessage: PropTypes.string,
    ElementComponent: PropTypes.func.isRequired,
    manyMessage: PropTypes.string.isRequired
  };

  state = {
    showList: false
  };

  renderSingleElement = (item, i) => {
    const { ElementComponent } = this.props;
    return <ElementComponent key={i} item={item} />;
  };

  render() {
    const { elements, zeroMessage, manyMessage } = this.props;
    const { showList } = this.state;
    if (elements.length === 0) {
      return (
        <div className="alert alert-info" role="alert">
          {zeroMessage ? zeroMessage : 'Nessun elemento trovato'}
        </div>
      );
    }
    if (elements.length === 1) {
      return this.renderSingleElement(elements[0]);
    }
    return (
      <>
        <div className="alert alert-info" role="alert">
          <div className="alert-heading" onClick={e => this.setState({ showList: !showList })}>
            {showList ? <TriangleUpIcon /> : <TriangleDownIcon />}
            {manyMessage}
          </div>
        </div>
        {showList && (
          <>
            <hr />
            <div className="mb-0">{elements.map((item, i) => this.renderSingleElement(item, i))}</div>
          </>
        )}
      </>
    );
  }
}

export default OneOrMoreAlert;
