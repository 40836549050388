import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import Loading from '../Common/Loading';
import UserView from './UserView';
import { connect } from 'react-redux';
import ProfileAttachmentsList from '../ProfileAttachments/ProfileAttachmentsList';
import Breadcrumb from '../Breadcrumb';

class UserMain extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired
  };

  state = {
    current_tab: ''
  };

  breadcrumbs = [
    { link: '/', text: 'Home' },
    { active: true, text: `${this.props.account.data.name}` }
  ];

  tabs = {
    detail: {
      title: 'Dettaglio',
      comp: UserView
    },
    attachments: {
      title: 'Allegati',
      comp: ProfileAttachmentsList
    }
  };

  render() {
    const { current_tab } = this.state;
    const { account, history } = this.props;
    if (!current_tab || !account) return <Loading />;
    const { profile } = account.data;
    const TabComp = this.tabs[current_tab].comp;

    return (
      <>
        <div className="p-3">
          <Breadcrumb crumbs={this.breadcrumbs} />
          <ul className="nav nav-tabs">
            {profile &&
              Object.keys(this.tabs).map((tab, i) => {
                return (
                  <li key={i} className="nav-item">
                    <a
                      href={`#${tab}`}
                      className={`nav-link ${tab === current_tab ? 'active' : ''}`}
                      onClick={e => this.setState({ current_tab: tab })}
                    >
                      {this.tabs[tab].title}
                    </a>
                  </li>
                );
              })}
            {!profile && (
              <li className="nav-item">
                <a
                  href={`#detail`}
                  className="nav-link active"
                  onClick={e => this.setState({ current_tab: this.tabs.detail })}
                >
                  {this.tabs.detail.title}
                </a>
              </li>
            )}
          </ul>
        </div>

        <TabComp account={account.data} profile={profile} history={history} />
      </>
    );
  }

  componentDidMount() {
    const { location } = this.props;
    let current_tab = 'detail';
    if (location.hash) {
      const hash = location.hash.substring(1);
      if (this.tabs[hash]) {
        current_tab = hash;
      }
    }
    this.setState({ current_tab });
    setTitle(this.props.account.data.name);
  }
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(UserMain);
