import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';
import 'react-datepicker/dist/react-datepicker.css';
registerLocale('it', it);

/**
 * Use `LTInputTime` to display date picker
 */
class LTInputDate extends PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
    onChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    subText: PropTypes.string,
    isFocused: PropTypes.bool
  };

  render() {
    const { value, onChange, handleKeyDown, handleBlur, subText } = this.props;
    return (
      <>
        <DatePicker
          locale="it"
          dateFormat="dd/MM/yyyy"
          className="form-control form-control-sm"
          selected={value}
          onChange={onChange}
          startOpen={true}
          onKeyDown={handleKeyDown && handleKeyDown}
          onCalendarClose={handleBlur}
        />
        {subText && <span className="col-form-label-sm text-muted">{subText}</span>}
      </>
    );
  }
}

export default LTInputDate;
