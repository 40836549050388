import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Account, { doGetUserMe, receiveLogin, requestLogin } from '../../lib/models/account';
import { EyeClosedIcon, EyeIcon } from '@primer/octicons-react';
import { setTitle } from '../../lib/utils/windowutils';

const Header = () => {
  return (
    <div className="header text-center py-4">
      <img src={process.env.PUBLIC_URL + '/assets/logo-inlista-white.png'} alt="logo InLista" />
      <div className="mt-4 p-2 pt-3 bg-login">
        <h5>Accedi</h5>
      </div>
    </div>
  );
};

class LoginComp extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    email: '',
    password: '',
    keepMeLogged: false,
    show_password: false,
    message: '',
    running: false
  };

  passwordInput = React.createRef();
  emailInput = React.createRef();

  render() {
    const { account } = this.props;
    if (account && account.data && account.data.email) {
      return (
        <div>
          <p>Accesso effettuato</p>
          <Link className="btn btn-info" to="/">
            Continua
          </Link>
        </div>
      );
    }
    const { email, password, keepMeLogged, show_password, message, running } = this.state;

    return (
      <div className="login-wrapper">
        <div className="">
          <Header />
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                {message && <div className="alert alert-danger">{message}</div>}
                {account.error && (
                  <div className="alert alert-danger">
                    Problema con l'account richiesto, contattare l'amministratore.
                  </div>
                )}
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <label htmlFor="pryInputEmail1">Indirizzo email</label>
                    <input
                      ref={this.emailInput}
                      type="email"
                      className="form-control"
                      id="pryInputEmail1"
                      value={email}
                      required
                      autoComplete="username"
                      onChange={e => this.setState({ email: e.target.value })}
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="pryInputPassword1">Password</label>
                    <div className="input-group">
                      <input
                        ref={this.passwordInput}
                        type={show_password ? 'input' : 'password'}
                        className="form-control"
                        name="password"
                        required
                        autoComplete="current-password"
                        value={password}
                        id="pryInputPassword1"
                        onChange={e => this.setState({ password: e.target.value })}
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="input-group-text text-primary"
                          onClick={() => {
                            this.setState({ show_password: !show_password }, () => {
                              this.passwordInput.current.focus();
                            });
                          }}
                        >
                          {show_password ? <EyeClosedIcon /> : <EyeIcon />}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="pryCheck1"
                      checked={keepMeLogged}
                      onChange={e =>
                        this.setState({
                          keepMeLogged: e.target.checked
                        })
                      }
                    />
                    <label className="form-check-label" htmlFor="pryCheck1">
                      Mantieni aperta la sessione
                    </label>
                  </div>
                  <div className="text-center mt-4">
                    <button type="submit" disabled={!email || running} className="btn btn-primary w-100">
                      {running ? (
                        <div className="spinner-border spinner-border-sm" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        'Login'
                      )}
                    </button>
                  </div>
                </form>
                <p className="mt-4">
                  <Link to="/lost-password" className="text-white">
                    Hai dimenticato la password?
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (this.props.account.data) {
      this.props.history.replace('/');
      return;
    }
    if (this.emailInput.current) {
      this.emailInput.current.focus();
    }
    const htmlClass = document.querySelector('html').classList;
    if (htmlClass.contains('il-light')) {
      htmlClass.remove('il-light');
      htmlClass.add('il-dark');
      this.switchBodyClass = true;
    } else {
      this.switchBodyClass = false;
    }
    setTitle('Login');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.account.data) {
      const redirectURL = localStorage.getItem('redirectURL');
      localStorage.removeItem('redirectURL');
      this.props.history.push(redirectURL || '/');
    }
  }

  componentWillUnmount() {
    if (this.switchBodyClass) {
      const htmlClass = document.querySelector('html').classList;
      htmlClass.remove('il-dark');
      htmlClass.add('il-light');
    }
  }

  onSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    if (!email || !password) return;
    this.setState(
      {
        running: true
      },
      this.doLogin
    );
  };

  doLogin = () => {
    const { dispatch } = this.props;
    const { email, password, keepMeLogged } = this.state;
    dispatch(requestLogin());
    Account.doLogin({ email, password, keepMeLogged })
      .then(sessionId => {
        dispatch(receiveLogin());
        setTimeout(() => {
          dispatch(doGetUserMe());
        }, 200);
        this.setState({ running: false, message: '' });
      })
      .catch(error => {
        this.setState({ running: false, message: error.message });
      });
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

const Login = withRouter(connect(mapStateToProps)(LoginComp));
export default Login;
