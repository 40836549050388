import { combineReducers } from 'redux';
import { accountReducer } from './lib/models/account/';

import { connectRouter } from 'connected-react-router';
import staffListReducer from './lib/models/stafflist/StaffListReducer';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    account: accountReducer,
    staff: staffListReducer
  });

export default rootReducer;
