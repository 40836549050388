import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import InputSearch from './InputSearch';
import Event from '../../lib/models/admin/Event';
import DropdownSearch from './DropdownSearch';
import { getDropdownVariables, getHandleSearch, getSearchObject } from '../../lib/utils/searchUtils';
import qs from 'qs';
import { EVENT_STRINGS } from '../../lib/models/Events';
import DatePicker from 'react-datepicker';
import { formatDate } from '../../lib/utils/dateutils';

class AdminEventSearch extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    intext: PropTypes.string.isRequired
  };

  state = {
    eventsDetails: { rows: [] },
    advancedSearch: true
  };

  render() {
    const { history, match, intext } = this.props;
    const { eventsDetails, advancedSearch } = this.state;
    const searchObject = getSearchObject(this.props.location);
    if (eventsDetails.rows.length === 0) {
      return <div>Ricerca non disponibile.</div>;
    }
    /*

        <div className="row">
          <div className="col-12">
            <button
              className="mb-lg-3 mb-1 btn btn-sm btn-outline-primary"
              onClick={e => this.setState({ advancedSearch: !advancedSearch })}
            >
              Ricerca Avanzata {advancedSearch ? '-' : '+'}
            </button>
          </div>
        </div>
     */
    return (
      <div className="container-fluid">
        <div className="row my-2">
          <div className="col-12">
            <InputSearch
              initialValue={searchObject.q.main || ''}
              clearSearch={e => history.push(match.url)}
              doSearch={this.handleSearch}
            />
          </div>
        </div>

        {advancedSearch && (
          <div className="row">
            {(intext === 'external' || intext === 'master') && (
              <div className="col-12 col-lg-4 my-1">
                <DropdownSearch
                  label={'Società Responsabile'}
                  doSearch={this.handleSearch}
                  dropdownName="partner_id"
                  initialValue={searchObject.q.partner_id ? searchObject.q.partner_id : ''}
                  dropdownList={getDropdownVariables('partners', eventsDetails)}
                />
              </div>
            )}
            {(intext === 'internal' || intext === 'master') && (
              <div className="col-12 col-lg-4 my-1">
                <DropdownSearch
                  label={'Cliente'}
                  doSearch={this.handleSearch}
                  dropdownName="customer_id"
                  initialValue={searchObject.q.customer_id ? searchObject.q.customer_id : ''}
                  dropdownList={getDropdownVariables('customers', eventsDetails)}
                />
              </div>
            )}
            {intext !== 'master' && (
              <div className="col-12 col-lg-4 my-1">
                <DropdownSearch
                  label={'Stato'}
                  doSearch={this.handleSearch}
                  dropdownName="status"
                  initialValue={searchObject.q.status ? searchObject.q.status : ''}
                  dropdownList={EVENT_STRINGS}
                />
              </div>
            )}
            <div className="col-12 col-lg-4 my-1">
              <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                  <label className="input-group-text">Data </label>
                </div>
                <DatePicker
                  locale="it"
                  dateFormat="dd/MM/yyyy"
                  className="form-control form-control-sm"
                  selected={searchObject.q.due_date ? new Date(searchObject.q.due_date) : ''}
                  onChange={date => this.handleSearch(date, 'due_date')}
                  startOpen={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.loadAdminEventsDetails();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.intext !== this.props.intext) {
      this.loadAdminEventsDetails();
    }
  }

  loadAdminEventsDetails = () => {
    Event.getAdminEventsDetails(this.props.intext).then(eventsDetails => {
      this.setState({ eventsDetails });
    });
  };

  handleSearch = (searchInput, inputName) => {
    let { pathname } = this.props.location;
    if (inputName === 'due_date') {
      searchInput = formatDate({ date: searchInput, format: '%Y-%M-%D', doubleDigit: true });
    }
    const searchObject = getHandleSearch(searchInput, inputName, this.props.location);
    this.props.history.push(pathname + qs.stringify(searchObject, { addQueryPrefix: true }) + '#' + this.props.intext);
  };
}

export default withRouter(AdminEventSearch);
