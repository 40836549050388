import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Use `LTCheckbox` to list and edit checkboxes.
 */

// TODO need both options and optionsChecked in props

class LTCheckbox extends PureComponent {
  static propTypes = {
    isFocused: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func,
    handleKeyDown: PropTypes.func,
    options: PropTypes.array.isRequired,
    optionsChecked: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { optionsChecked: [...props.optionsChecked] };
  }

  render() {
    const { onChange, handleBlur, options } = this.props;
    const { optionsChecked } = this.state;
    return (
      <div>
        {options.map(this.renderCheckbox)}
        <button
          className="btn btn-sm btn-outline-primary mr-2"
          onClick={e => {
            e.stopPropagation();
            onChange(optionsChecked, handleBlur);
          }}
        >
          SAVE
        </button>
        <button
          className="btn btn-sm btn-outline-warning"
          onClick={e => {
            e.stopPropagation();
            handleBlur();
          }}
        >
          CLOSE
        </button>
      </div>
    );
  }

  renderCheckbox = (option, i) => {
    const { optionsChecked } = this.state;
    return (
      <div className="form-check" key={i}>
        <input
          checked={optionsChecked.includes(option.code)}
          className="form-check-input"
          type="checkbox"
          id={option.id || option.code}
          name={option.code || option.id}
          onChange={e => this.handleChangeOptions(e, option.code)}
        />
        <label className="form-check-label" htmlFor={option.id || option.code}>
          {option.description}
        </label>
      </div>
    );
  };

  handleChangeOptions = (event, code) => {
    const target = event.target;
    const value = target.checked;

    const { optionsChecked } = this.state;
    let newOptionsChecked = [];
    const included = optionsChecked.includes(code);
    if (included) {
      newOptionsChecked = optionsChecked.filter(o => o !== code);
    } else if (value && !included) {
      newOptionsChecked = optionsChecked.map(o => o);

      newOptionsChecked.push(code);
    }
    this.setState({
      optionsChecked: newOptionsChecked
    });
  };
}

export default LTCheckbox;
