import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb';
import { setTitle } from '../../lib/utils/windowutils';
import Profile from '../../lib/models/admin/Profile';
import UrlManglingInputSearch from '../Common/UrlManglingInputSearch';
import { getPaginationUrl, getQueryParameters } from '../../lib/utils/urlutils';
import AddSmth from '../Common/AddSmth';

class ProfileList extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    profiles: { rows: [] }
  };

  breadcrumbs = [
    { link: '/admin/', text: 'Admin' },
    { active: true, text: 'Profili' }
  ];

  getCity(profile) {
    if (profile.city && profile.province) {
      return `${profile.city} (${profile.province})`;
    }
    if (profile.city) {
      return profile.city;
    }
    if (profile.province) {
      return profile.province;
    }
    return '';
  }
  getContact(profile) {
    if (profile.contact_method) {
      return `${profile.contact_method}: ${profile.contact_method === 'phone' ? profile.phone : profile.email}`;
    }
    return '';
  }

  render() {
    const { match, location } = this.props;
    const { profiles } = this.state;
    const { offset } = getQueryParameters(location);

    return (
      <div>
        <Breadcrumb crumbs={this.breadcrumbs} />
        <div className="my-3 row">
          <div className="col-12 col-md-4 col-lg-3">
            <UrlManglingInputSearch />
          </div>
        </div>
        <div>
          {profiles.rows.length > 0 && (
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Cognome</th>
                  <th>Nome</th>
                  <th>Qualifica</th>
                  <th>Città</th>
                  <th>Contatto</th>
                  <th>Stato</th>
                </tr>
              </thead>
              <tbody>
                {profiles.rows.map((profile, i) => {
                  return (
                    <tr key={i} className="justify-content-between align-items-center">
                      <td>
                        <Link to={`${match.path}/${profile.id}`}>{profile.surname}</Link>
                      </td>
                      <td>{profile.name}</td>
                      <td>{profile.qualifications.join(',')}</td>
                      <td>{this.getCity(profile)}</td>
                      <td>{this.getContact(profile)}</td>
                      <td>{profile.status === 1 ? 'Attivo' : 'Disattivato'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {profiles.rows.length === 0 && <div className="alert alert-info">No profile found</div>}
        </div>
        <div className="d-flex bd-highlight mb-3">
          {(Number(offset) || profiles.hasMore) && (
            <div className="mt-3 ml-auto p-2 bd-highlight">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={'page-item' + (!Number(getQueryParameters(location).offset) ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'p')} className="page-link" onClick={e => e.target.blur()}>
                      Precedente
                    </Link>
                  </li>
                  <li className={'page-item' + (profiles.hasMore === false ? ' disabled' : '')}>
                    <Link to={getPaginationUrl(location, 'n')} className="page-link" onClick={e => e.target.blur()}>
                      Successivo
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
        <AddSmth match={match} />
      </div>
    );
  }

  componentDidMount() {
    setTitle('Profili');
    this.loadProfiles();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadProfiles();
    }
  }

  loadProfiles = () => {
    const { location } = this.props;
    const { limit, offset, order_by, sort, q } = getQueryParameters(location);

    const params = {
      limit,
      offset,
      order_by: order_by || 'surname',
      sort: sort || 'asc',
      search: q || 'status:1'
    };
    Profile.find(params).then(profiles => {
      this.setState({ profiles });
    });
  };
}

export default ProfileList;
